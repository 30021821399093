import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Typography,
  Button,
  Dialog,
  Snackbar,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from "@material-ui/core";
import { Close as CloseIcon, CheckCircleOutlined as CheckCircleOutlinedIcon } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";

import { Travellers, Vehicles } from "../../../pages/authenticated";
import { useUserTravellers, useVehicle, useUpdateVerifySync } from "rest";
import { useProfile } from "context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      position: "relative",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    full: {
      width: "100%",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getStepContent = (stepIndex: number, props: any) => {
  switch (stepIndex) {
    case 0:
      let vehicle = {
        lengthInM: 0,
        numberplate: "",
        vehicleOwner: "",
        pendant: {
          lengthInM: "",
          numberplate: "",
          isPendantActive: "",
        },
      };
      return <Vehicles {...props} vehicle={vehicle} />;
    case 1:
      return <Travellers {...props} gap={true} />;
    default:
      return "Unknown stepIndex";
  }
};

export const VehicleAndTravellersDialog = ({ profilePageProps }) => {
  const classes = useStyles();

  const { verifyData, verifyCode, setFullScreen, fullScreen, activeStep, setActiveStep } = useProfile();

  const steps = ["Fahrzeug", "Reisenden"];

  const [vehicleVerify, setVehicleVerify] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [travelerVerify, setTravelerVerify] = useState(false);

  const { mutate: postVerifySync } = useUpdateVerifySync({
    onSuccess: (res) => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      checkMessageAfter(res);
    },
    onError: (error) => console.log(error),
  });

  const { refetch: getUserTravellers } = useUserTravellers({
    onSuccess: (results) => {
      const userTravelers = results?.filter((t: any) => {
        if (t.enabled) {
          return {
            birthDate: t.birthDate,
            enabled: t.enabled,
          };
        } else {
          return false;
        }
      });
      const today: any = new Date();
      let childCount = 0;
      if (userTravelers.length > 0) {
        childCount = userTravelers.reduce((acc: any, e: any) => {
          let birthday: any = new Date(e.birthDate);
          const diffTime = Math.abs(birthday - today);
          const diffYears = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365));
          if (diffYears > 12) {
            return acc + 0;
          } else {
            return acc + 1;
          }
        }, 0);
      }

      let adultCount = userTravelers.length - childCount;

      if (
        adultCount === parseInt(verifyData.adultTravelerCount) &&
        childCount === parseInt(verifyData.childTravelerCount)
      ) {
        postVerifySync({ code: verifyCode, bookingId: verifyData.bookingId });
      } else {
        setTravelerVerify(true);
        setTimeout(() => {
          setTravelerVerify(false);
        }, 5000);
      }
    },
  });

  const { refetch: getVehicle } = useVehicle({
    onSuccess: (results) => {
      results?.numberplate === verifyData.vehicleNumber
        ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
        : setVehicleVerify(true);
      setTimeout(() => {
        setVehicleVerify(false);
      }, 5000);
    },
    onError: (error) => console.error(error),
  });

  const checkMessageAfter = (data) => {
    switch (data) {
      case "Success!":
        setSuccessText("Reservierung aktualisiert");
        break;
      case "Error! Vehicle platenumber does not match":
        setSuccessText("Fehler! Fahrzeugplattennummer stimmt nicht überein");
        break;
      case "Error! No vehicle found":
        setSuccessText("Fehler! Kein Fahrzeug gefunden");
        break;
      case "Error! Phone Number belongs to another account.":
        setSuccessText("Fehler! Telefonnummer gehört zu einem anderen Konto.");
        break;
      case "Error! Invalid Code":
        setSuccessText("Fehler! Ungültiger Code");
        break;
      case "Error! There is a problem with travelers":
        setSuccessText("Fehler! Es gibt ein Problem mit Reisenden");
        break;
      case "Error! This account has been updated before.":
        setSuccessText("Fehler! Dieses Konto wurde bereits aktualisiert.");
        break;
      default:
        setSuccessText("Fehler! Synchronisierung fehlgeschlagen");
        break;
    }
  };

  const handleCloseFullScreen = () => {
    setFullScreen(false);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      getVehicle();
    } else if (activeStep === 1) {
      getUserTravellers()
        .then((results) => {
          // eslint-disable-next-line array-callback-return
          const userTravelers = results.data.filter((t: any) => {
            if (t.enabled) {
              return {
                birthDate: t.birthDate,
                enabled: t.enabled,
              };
            }
          });
          const today: any = new Date();
          let childCount = 0;
          if (userTravelers.length > 0) {
            childCount = userTravelers.reduce((acc: any, e: any) => {
              let birthday: any = new Date(e.birthDate);
              const diffTime = Math.abs(birthday - today);
              const diffYears = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365));
              if (diffYears > 12) {
                return acc + 0;
              } else {
                return acc + 1;
              }
            }, 0);
          }

          let adultCount = userTravelers.length - childCount;

          if (
            adultCount === parseInt(verifyData.adultTravelerCount) &&
            childCount === parseInt(verifyData.childTravelerCount)
          ) {
            postVerifySync({ code: verifyCode, bookingId: verifyData.bookingId });
          } else {
            setTravelerVerify(true);
            setTimeout(() => {
              setTravelerVerify(false);
            }, 5000);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepper = () => {
    return (
      <div className={classes.full}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={6}>
            {activeStep === steps.length ? (
              <div className={classes.root}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={4}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <CheckCircleOutlinedIcon style={{ fontSize: 60, color: green[500] }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {successText}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={handleCloseFullScreen}>
                      Ok
                    </Button>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep, profilePageProps)}
                </Typography>

                <div style={activeStep !== 1 ? { paddingTop: "50px" } : { paddingTop: "30px" }}>
                  {verifyData.vehicleMatch && activeStep === 1 ? (
                    ""
                  ) : (
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                      Zurück
                    </Button>
                  )}
                  <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Speichern" : "Weiter"}
                  </Button>
                  {activeStep === 1 && (
                    <Typography style={{ paddingTop: "25px" }}>
                      {/* You should have xx active adult and xx active child traveler. */}
                      Sie sollten {verifyData.adultTravelerCount} aktive Erwachsene und{" "}
                      {verifyData.childTravelerCount} aktive Kinder als Reisende haben.
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </div>
    );
  };

  return (
    <Dialog fullScreen open={fullScreen} onClose={handleCloseFullScreen} TransitionComponent={Transition}>
      <Snackbar open={vehicleVerify} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="error">Kennzeichen stimmt nicht überein</Alert>
      </Snackbar>
      <Snackbar open={travelerVerify} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="error">Anzahl der Reisenden stimmt nicht überein</Alert>
      </Snackbar>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="end" color="inherit" onClick={handleCloseFullScreen} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {stepper()}
    </Dialog>
  );
};
