import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { AuthContext } from "../context/Auth";

export const AuthGuard = () => {
  const { currentUser } = useContext(AuthContext) as any;

  if (currentUser) {
    return <Outlet />;
  }
  return <Navigate to={"/login"} replace />;
};
