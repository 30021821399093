import React, { useContext, useState, useEffect } from "react";
import { Box, Breadcrumbs, Button, Typography, Theme, TextField } from "@mui/material";
import deLocale from "date-fns/locale/de";
import { useTranslation, withTranslation } from "react-i18next";
import {
  CssBaseline,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fade,
  makeStyles,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { RowItem } from "./RowItem";
import { useDeleteOrder } from "rest/orders-service";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  booking: {
    backgroundColor: "#DCE6A0",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
  no: {
    backgroundColor: "#f9c5af",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
  orangeButton: {
    // color: "white",
    backgroundColor: "#FFA500", // Orange color
    "&:hover": {
      backgroundColor: "#FF8C00", // Darker orange on hover
    },
    color: "white", // Automatically set text color for contrast
  },
});

const rows = [
  {
    date: new Date(),
    price: 12.5,
    status: "preparing",
    action: () => console.log("action"),
    id: 1,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "canceled",
    action: () => console.log("action"),
    id: 2,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "ready",
    action: () => console.log("action"),
    id: 3,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "canceled",
    action: () => console.log("action"),
    id: 4,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "ready",
    action: () => console.log("action"),
    id: 5,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "canceled",
    action: () => console.log("action"),
    id: 6,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "canceled",
    action: () => console.log("action"),
    id: 7,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "canceled",
    action: () => console.log("action"),
    id: 8,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "preparing",
    action: () => console.log("action"),
    id: 9,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "preparing",
    action: () => console.log("action"),
    id: 10,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
  {
    date: new Date(),
    price: 12.5,
    status: "ready",
    action: () => console.log("action"),
    id: 11,
    items: [
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
      { name: "Brot", perPrice: 3.5, count: 2, price: 7.0 },
    ],
  },
];

export interface Item {
  date: Date;
  price: number;
  status: string;
  action: () => void;
  items: ChildItem[];
  id: string;
}

interface ChildItem {
  name: string;
  perPrice: number;
  count: number;
  price: number;
}

export const OrdersTable = (params) => {
  const classes = useStyles();
  // const [date, updateDate] = useState(new Date());
  const [openDelete, setOpenDelete] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [orderId, setOrderId] = useState("");
  const { t } = useTranslation();

  const deleteDialog = (e) => {
    e.stopPropagation();
    setOpenDelete(true);
  };

  const closeSuccess = () => {
    setOpenSuccess(false);
  };

  const closeDelete = () => {
    setOpenDelete(false);
  };

  const { mutate: deleteOrder } = useDeleteOrder({
    onSuccess: (data) => {
      closeDelete();
      setTimeout(() => {
        setOpenSuccess(true);
      }, 500);
      // setOpenSuccess(true);
      setTimeout(() => {
        setOpenSuccess(false);
      }, 4000);
      // console.log(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });
  const transformedOrders = params.orders.map((order) => {
    return {
      date: new Date(order.date),
      price: order.sum,
      status: order.status,
      action: () => console.log("action"),
      id: order._id,
      items: order.products.map((product) => {
        return {
          name: product.name,
          perPrice: product.pricePerItem,
          count: product.count,
          price: product.sum,
        };
      }),
    };
  });

  const deleteOrderOnItem = (orderId, e) => {
    setOrderId(orderId);
    deleteDialog(e);
    // deleteOrder({ orderId });
  };

  const handleDelete = () => {
    deleteOrder({ orderId: orderId });
  };

  return (
    <>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle id="form-dialog-title">Löschen</DialogTitle>
        <DialogContent style={{ minWidth: "40vw" }}>
          <Typography variant="subtitle1">Ihre Bestellung wird dauerhaft gelöscht.</Typography>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setOpenDelete(false)}
            color="primary"
            variant="contained"
            disableElevation
            className={classes.orangeButton}
          >
            {t("label_cancel")}
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={handleDelete}
            disableElevation
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSuccess} onClose={() => closeSuccess()}>
        <DialogTitle id="form-dialog-title">Erfolg</DialogTitle>
        <DialogContent style={{ minWidth: "40vw" }}>
          <Typography variant="subtitle1">Ihre Bestellung wurde erfolgreich gelöscht.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeSuccess()}
            color="primary"
            className={classes.orangeButton}
            variant="contained"
            disableElevation
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      <Fade style={{ marginTop: "16px" }} in={true}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>{t("date")}</TableCell> */}
                <TableCell />
                <TableCell>{t("date")}</TableCell>
                <TableCell>Gesamtbetrag</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Aktion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transformedOrders.map((item) => (
                <RowItem key={item.id} item={item} deleteOrder={deleteOrderOnItem} />
              ))}

              {/* {getBookings.length < 1 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    {bookingPast ? t("history_table_no_record") : t("booking_table_no_record")}
                  </TableCell>
                </TableRow>
              )}
              {getBookings?.map((item: any, index: number) =>
                bookingPast
                  ? new Date(item.endDate).getTime() - new Date().getTime() < 0 && (
                      <RowItem item={item} key={item.id + "row"} />
                    )
                  : new Date(item.endDate).getTime() - new Date().getTime() > 0 && (
                      <RowItem item={item} key={item.id + "row"} />
                    )
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Fade>
    </>
  );
};
