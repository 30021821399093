import { useCallback, useContext } from "react";
import { useMutation, useQueryClient, UseMutationOptions } from "react-query";
import { EntityType } from "../query-keys";

import type { ICreateOrderParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";

export const useCreateOrder = (options?: UseMutationOptions<any, any, ICreateOrderParams>) => {
  const { ordersApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (params: ICreateOrderParams) =>
      ordersApiService.createCancellableRequest((cancelToken) =>
        ordersApiService.createOrder(params, cancelToken)
      ),
    [ordersApiService]
  );

  const mutation = useMutation<any, any, ICreateOrderParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.ORDERS);
      options?.onSuccess?.(...args);
    },
  });
  return mutation;
};
