import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          label_email: "Email",
          label_login: "Login",
          label_registration: "Register",
          label_forgot_password: "Forgot password?",
          label_password: "Password",
          label_repassword: "Password again",
          password_error: "password must match.",
          label_search_term: "Search term",
          label_search_by: "Search by",
          label_street_and_house_number: "Street and house number",
          label_country_code: "Country code",
          label_amount_of_spots: "Amount of spots",
          label_maximum_stay: "Maximum stay",
          label_season: "Season",
          label_name: "Name",
          label_search: "Search",
          label_area: "Area",
          label_area_code: "Area code",
          label_address: "Address",
          label_resend_email_verification: "Resend verification email",
          label_resend_text:
            "We have sent a confirmation link to your email address. To complete the process, just click the link in that email.",
          label_admin_menu_parking_areal_list: "Parking areal",
          label_admin_menu_home: "Home",
          label_admin_menu_parking_areal_upload: "Upload",
          label_admin_menu_comments_list: "Comments",
          label_admin_menu_images_list: "Images",
          label_admin_menu_users_list: "Users",
          label_admin_menu_bookings_list: "Bookings",
          label_properties: "Properties",
          label_chemistry_wc: "Chemical toilet",
          label_disposal: "Greywater disposal",
          label_electricity: "Electricity",
          label_pets: "Pets",
          label_shower: "Shower",
          label_water: "Water",
          label_wifi: "Wifi",
          label_price_information: "Price information",
          label_electricity_costs: "Electricity cost",
          label_pet_cost: "Pet cost",
          label_price_child: "Price child",
          label_price_extra_person: "Price extra person",
          label_price_extra_child: "Price extra child",
          label_price_two_adults: "Price two adults",
          label_shower_costs: "Shower costs",
          label_visitor_tax: "Visitor tax",
          label_water_cost: "Water cost",
          label_wifi_cost: "Wifi cost",
          label_general_information: "General information",
          label_detail_information: "Details",
          label_lat: "Latitude",
          label_lon: "Longitude",
          label_phone: "Phone",
          label_homepage: "HOMEPAGE",
          hover_logout: "Logout",
          add_new: "Add New",
          label_cancel: "Cancel",
          label_show_filters: "Show Filters",
          label_show_map: "Show Map",
          label_price_ascending: "Price: Ascending",
          label_price_descending: "Price: Descending",
          label_sort_distance: "Distance",
          label_sort_by: "Sort By",
          label_grad: "Grad",
          label_minute: "Minute",
          label_sekunden: "Sekunden",
          label_toilet_cost: "WC:",
          label_visitor_tax_kind: "Child Tax:",
          label_visitor_tax_hund: "Dog Tax:",
          label_chemistryWC: "Chemical Toilet:",
          label_automat: "Auto Chemical Toilet:",
          label_disposal_cost: "Disposal:",
          label_rubbish: "Garbage:",
          label_areaCode: "Area Code",
          label_countryCode: "Country",
          label_search_firstname: "First Namme",
          label_search_lastname: "Last Name",
          label_search_email: "Email",
          label_search_phone: "Phone",
          label_search_pl: "Search",
          label_search_by_value: "Value",
          label_booking_saved: "Booking Has Been Saved Successfully",
          label_booking_customer: "Select Customer",
          label_booking_select_dates: "Select Dates and Size of Vehicle",
          label_booking_select_spot: "Select Parking Spot",
          label_save_filters: "Save Filters",
          label_reset_filters: "Reset Filters",
          label_booking_info: "Booking Info",
          label_next: "Next",
          label_back: "Back",
          label_save: "Reserve",
          label_save_pre: "Reserve",
          label_travellers_submitted: "Travellers has been submitted successfully",
          label_travellers_not_uploaded: "Travellers has not been uploaded successfully",
          label_check_required: "Please check required fields.",
          label_review_submitted: "Your review has been submitted successfully",
          label_my_account: "My Account",
          label_log_out: "Log Out",
          label_admin_menu_settings: "Settings",
          label_admin_menu_crm: "CRM",
          label_firstname: "First Name",
          label_lastname: "Last Name",
          label_parking_areal_name: "Parking Areal Name",
          login_success: "You have registered succesfully. Please Check your email and validate.",
          register_success:
            "You have registered succesfully. Please Check your email and validate. You can login now.",
          auth_user_not_found: "No user corresponding to this email",
          auth_email_already_in_use: "The email address is already in use",
          auth_email_already_exists:
            "The provided email is already in use by an existing user. Each user must have a unique email.",
          auth_invalid_email:
            "The provided value for the email user property is invalid. It must be a string email address.",
          auth_weak_password: "Password should be at least 6 characters",
          auth_wrong_password: "Wrong password",
          login_forget_password_success: "Password reset link has been sent to your email.",
          label_wrong_image_format_size_title: "Wrong Image Format or Size",
          label_wrong_image_format_or_size:
            "Please check your image format and size. Only PNG and JPG files which are below {{sizeLimit}} MB are supported.",
          label_file_upload_success: "The file has been uploaded successfully",
          label_file_upload_fail: "The file has not been uploaded successfully",
          label_wrong_code: "Wrong verification code",
          verification_code: "Verification Code",
          please_register: "Please create account via web or mobile application for getting guest cards",
          label_startDate: "Start Date",
          label_endDate: "End Date",
          label_canceled: "Canceled",
          label_numberPlate: "Number Plate",
          label_filter_by: "Filter By",
          insufficient_point:
            "Dear customer, unfortunately there is currently a malfunction in the payment system. We are working with high pressure on the elimination of the problem. Until then, you must please buy points to operator of the pitch. Thank you for your understanding and have a nice Aufenhlt.",
          label_reviews: "Reviews",
          label_add_review: "Add Review",
          label_submit_review: "Submit review",
          label_location: "Location",
          label_calm: "Calm",
          label_equipment: "Equipment",
          label_cleanliness: "Cleanliness",
          label_value_for_money: "Value for money",
          label_activity_opportunities: "Activity opportunities",
          label_comment: "Comment",
          date: "Date",
          parking_space: "Parking space",
          arrival: "Arrival",
          departure: "Departure",
          place_number: "Place number",
          pin: "PIN",
          add_new_folder: "Add New folder",
          create: "Create",
          select_folder: "Select folder",
          find_pitches: "Find Pitches",
          table_no_record: "No Records Found",
          booking_table_no_record: "No Bookings Found",
          history_table_no_record: "No Past Bookings Found",
          comment_table_no_record: "No Comments Found",
          change_folder: "Change Folder",
          delete: "Delete",
          profile_submit_success: "Your profile has been submitted successfully",
          your_account_linked: "Your account has been linked",
          your_account_already_linked: "Your account has already been linked",
          label_loading_profile: "Loading profile...",
          profile_info_text_1:
            "Wondering why this information is necessary? An overnight stay on a RV park or a campsite is treated the same as an overnight stay in a hotel. According to the Federal Registration Act, the information in the mandatory fields is required for this.",
          bills: "Bills",
          your_rewiev_succesfully_deleted: "Your review has been successfully deleted",
          your_rewiev_succesfully_saved: "Your review has been successfully saved",
          your_ratings: "Your Ratings",
          cancel_booking: "Cancel booking",
          cancel_booking_confirm: "Are you sure you want to cancel this booking?",
          yes: "Yes",
          no: "No",
          cannot_revert_cancel_booking: "If you have canceled your booking, you cannot undo it.",
          must_make_new_reservation: "You must make a new reservation.",
          succesfully_cancelled_booking: "You have successfully canceled the booking",
          problem_message_try_again: "There is a problem, please try again later",
          promotion_message_camp: "Can't get to your pitch? No problem! Thanks to CAMP/24 Check-In you can",
          cancel_free_of_charge: "cancel free of charge",
          add_new_traveller: " Add new traveller +",
          no_dogs: "No dogs",
          vehicles_submitted_successfully: "Your vehicles have been submitted successfully",
          check_mandatory_fields: "Please check the mandatory fields:",
          add_new_vehicle: "Add new vehicle +",
          add_trailer: "Add trailer +",
          can_choose_pick_based_information: "You can choose a pitch based on your information:",
          confirmation_link_sended_mail: "The confirmation link has been sent to your email",
          contact_info: "Contact information",
          general_info: "General information",
          number_of_parking_lots: "Number of parking lots",
          maximum_length: "Maximum length",
          maximum_stay: "Maximum stay",
          checkin_ab: "Check/in ab",
          checkout_bis: "Check/out bis",
          distances: "Distances",
          destination: "Destination",
          location: "Location",
          gps: "GPS",
          update_data: "Update data",
          start_navigation: "Start Navigation",
          bookable_price_for_two_person: "€ for 2 Person",
          included: "included",
          extra_person: "Extra Person",
          per_person: "Per person",
          dog: "Dog",
          price_per_hundred_liter: "€ 100 liters",
          peak_season: "Peak season",
          side_season: "Side Season",
          discount_cards: "Discount cards",
          turist_info: "Tourist Info",
          shopping: "Shopping",
          other: "Other",
          current: "Current",
          restaurant: "Restaurant",
          health: "Health",
          no_info_this_category: "No Info Found in This Category",
          close: "Close",
          upload_image_description:
            "Have you been here before? Great, if you also have a picture you can upload it here.",
          upload_here: "upload here",
          crop_your_image: "crop your image",
          caption: "Caption",
          maximum_limit_for_upload_input: "A maximum of 240 characters can be used for a caption.",
          maximum_240_characters: "Maximum 240 characters",
          agree: "Agree",
          warning: "Warning",
          parking_lot_closed_warn: "The parking lot is closed for 5 minutes. You should make your booking in",
          complete_seconds: "Complete seconds.",
          already_have_rez_warn: "You have a reservation for the selected date.",
          check_dates_before_rez: "Please check the dates before making the reservation",
          label_ok: "Ok",
          parking_spot_already_rez: "The parking space you are trying to reserve is already reserved.",
          mistake: "Mistake",
          go_back_and_choose_another_park: "Please go back and choose another parking space.",
          child: "Child",
          tourist_tax: "Tourist tax",
          total: "Total",
          total_amount: "Total amount",
          vat: "VAT",
          select_travellers: "Select Travellers",
          agree_usage_cookie: "I expressly agree to the use of cookies",
          delete_and_reject_cookies: "Delete and reject cookies",
          cookie_consent:
            "In order to optimally design our website for you and to be able to continuously improve it, we use \n cookies. In doing so, data that you leave behind when you visit our website is stored and \n circumstances to the third parties mentioned in our privacy policy. Make up your mind \n Against the use of cookies, the functionality of the website may be restricted.",
          further_info: "Further information",
          button_save: "Save",
          persons: "Persons",
          per_additional_person: "Per additional person",
          casette_disposal: "Cassette disposal",
          washing_machine: "Washing machine",
          accessibility: "Accessibility",
          playground: "Playground",
          dragzone_text_image_upload: "Drag some photos here or click to select photos.",
          federal_state: "Federal State",
          picture_writing: "Picture writing",
          series: "Series",
          activities: "Activities",
          rental_accomodation: "Rental accommodation",
          service: "Service",
          scout_info: "CAMP/24 Check-Scout",
          sightseeing_features: "Sightseeing features",
          transport_connection: "Transport connection",
          hint_upload_pitch_picture:
            "Please upload pictures of your pitch here and even better of them sights in your area up.",
          hint_share_your_future_guests: "Share with your future guests the places that are worth visiting.",
          hint_image_limits:
            "A maximum of 10 images with a maximum of 5 MB each are possible, preferably as .png, .jpg or .jpeg",
          hint_click_icons_to_apply_pitch: "Please click on the icons that apply to your pitch.",
          hint_selected_icons_will_appear: "The icons you selected (green) will appear after your approval",
          hint_camp_display: "www.camp/24check-in.de is displayed.",
          hint_thanks_for_register_camp_area:
            "Thank you for helping us find the information about yours. Update pitch/campsite for your guests.",
          hint_fill_fields_below: "Please fill out the fields below completely.",
          hint_receive_link_for_approval:
            "After your revision, you will receive a link from us for approval\n so that we can be sure that everything is up to date in the interests of satisfied guests.",
          hint_for_questions_please_contact:
            "If you have any questions, please do not hesitate to contact us.",
          hint_regards_campname: "Your CAMP/24 Check-In team",
          city: "City",
          price: "price",
          on_the_map: "On The Map",
          add_to_shopping_cart: "Add to shopping cart",
          for_free: "For free",
          search_zip_or_city: "Search zip code or city",
          make_sure_vehicle_adress_profiles: "Make sure your vehicle, address and profiles are filled out.",
          please_login_to_choose_date: "Please log in to choose a date",
          pitches: "Pitches",
          selected_parking_space: "Selected parking space",
          posting_date: "Posting date",
          delete_folder: "Delete Folder",
          your_profile_submit_fail: "Your profile was not submitted successfully",
          thanks_for_using_pls_register:
            "Thank you for using CAMP/24 Check-In. Please register here once (fields with * are mandatory fields).",
          your_profile: "Your Profile",
          mandatory_field: "Mandatory_field",
          family_name: "Family name",
          birthdate: "Birth date",
          birth_place: "Place of birth",
          nationality: "Nationality",
          valid_id_or_passport_number: "valid ID number or passport number",
          postal_code: "Postal code",
          residance: "Residence",
          street_housenumber: "Street, House number",
          country_of_residence: "Country of residence",
          country_code_phone: "Country code, Phone",
          save_changes: "Save changes",
          click_here_to_upload_photo: "Click here to upload a photo",
          success: "Success",
          error_vehicle_plate_number_not_match: "Error! Vehicle plate number does not match",
          error_no_vehicle_found: "Error! No vehicle found",
          error_phone_belong_another_acc: "Error! Phone number belongs to another account.",
          error_invalid_code: "Error! Invalid Code",
          error_traveler_problem: "Error! There is a problem with travelers",
          error_sync_failed: "Error! Sync failed",
          check_mobile_number: "Check mobile number",
          for_sync_enter_sms_code:
            "for syncing the booking you have right now in the checkpoint please enter the code that you received via SMS on your mobile phone",
          code: "Code",
          verify: "Verify",
          from: "from",
          to: "to",
          same_adress_as_user: "Users living adress",
          country: "Country",
          edit: "Edit",
          length_in_meters: "Length in meters",
          save_trailer: "Save Trailer",
          vehicle_owner: "Vehicle owner",
          save_vehicle: "Save vehicle",
          this_is_required: "This is required",
          your_password_send_successfully: "Your password has been sent successfully",
          your_password_failed_send: "Ihr Passwort wurde nicht erfolgreich übermittelt",
          change_password: "Change password",
          current_password: "Current Password",
          new_password: "New Password",
          permanently_delete_account: "Permanently delete account",
          press_delete_to_confirm:
            "Please enter the text 'DELETE' in capital letters in the confirmation field to confirm the deletion of your account",
          confirm: "Confirm",
          welcome: "WELCOME",
          delete_account: "Deleting an account",
          profile: "Profile",
          traveler: "Traveler",
          vehicles: "Vehicles",
          favourites: "favourites",
          bookings: "Bookings",
          history: "History",
          sidebar_cancel: "Cancel",
          imprint: "Imprint",
          privacy: "Privacy",
          add_space: "Add Space",
          gallery: "Gallery",
          show_map: "Show map",
          hide_map: "Hide map",
          link_copied_clipboard: "Link copied to clipboard.",
          standard: "Standard",
          create_new_folder: "CREATE NEW FOLDER",
          add: "Add",
          book: "Book",
          reserve_now: "Reserve now",
          before_reservation_add_vehicle:
            "to make a reservation you should add your vehicle on the account page",
          camping: "Camping",
          contact: "Contact",
          b2b_from_campers_to_camper: "From campers for campers",
          b2b_over_40_year_exp:
            "Over 40 years of camping experience have been instrumental in the development of our system.",
          b2b_benefits_our_system: "Benefit from the many advantages of our system:",
          b2b_vat_savings: "VAT savings",
          b2b_flexiable_payment_based_season: "Flexible pricing based on season and need",
          b2b_register_of_overnight: "Registration of all overnight guests",
          fair_secure_ticket: "Secure your free ticket for the Caravan Salon 2020",
          fair_thanks_for_interest: "Thank you for your interest in the parking space of the future.",
          fair_free_ticket_mail:
            "We look forward to welcoming you to our stand A61 in hall 12. You will receive an online voucher for a free ticket within the next few hours. You must redeem this voucher for a ticket by August 28, 2020 using the following link",
          fair_caravan_salon_contact:
            "You are welcome to make an appointment with us in advance by e-mail or telephone at our stand at the Caravan Salon",
          fair_free_transport_message:
            "Note: With the ticket you can use the public transport of the VRR for free.",
          sign_in_with_google: "Sign In with Google",
          sign_in_with_apple: "Sign In with Apple",
          your_payment_arrived_thanks: "Your payment has arrived, thank you!",
          campsite: "Campsite",
          start_plan_your_trip: "Start planning your trip here",
          search_for_pitch: "Search for pitch",
          latest_update: "Last Update:",
          no_result_pitch_search: "We couldn't find any pitch for that search term.",
          booking_reservation_needed: "Please login or register to make a reservation.",
          vehicle_needed_for_booking: "Please add a vehicle to your account to make a reservation.",
          login_required: "Login required",
          vehicle_length: "Vehicle Size",
          please_fill_all_fields: "Please fill in all fields",
          pre_booking_form: "Pre-booking form",
          label_mobile_enter: "Please enter your mobile number",
          sms_check: "send SMS",
          description: "Description",
          label_code_input: "Please enter the code you received via SMS",
          too_many_tries: "Too many tries",
          resend_code: "Resend code",
          confirmed_before: "You have already confirmed this booking",
          booking_confirmed: "Booking confirmed",
          code_is_wrong: "Code is wrong",
          code_is_expired: "Code is expired",
          code_missing: "Code Missing",
          prebooking_error: "Pre-reservation error",
          prebooking_save_onprocess: "pre-reservation save on process",
          label_yes: "Yes",
          label_no: "No",
          rotate_device: "Please rotate your device to landscape mode for an optimal viewing experience",
          spot_error:
            "An error occurred. Please close the window and try with selecting a different parking spot.",
          sms_verified_shortly:
            "Your pre-reservation will be verified shortly and you will be notified by SMS",
          label_prebooking_exists:
            "You have a pre-reservation at this campsite that is waiting for confirmation. Do you want to create a new pre-reservation?",
          add_traveller: "Add Traveller",
          new_traveller: "New Traveller",
          arrival_date: "Arrival Date",
          departure_date: "Departure Date",
          vehicle_size: "Vehicle Size",
          adult_count: "Adult Count",
          child_count: "Child Count",
          pet_count: "Pet Count",
          valid_until: "Valid Until",
          s_label: "S",
          m_label: "M",
          l_label: "L",
          xl_label: "XL",
          xxl_label: "XXL",
          label_spotnumber: "Spot number",
          label_notice: "Please add the following information, this will save you a lot of time at check-in",
          label_why: "Why is this information necessary?",
          child_1: "1 Child",
          child_2: "2 Child",
          child_3: "3 Child",
          child_4: "4 Child",
          adult_1: "1 Adult",
          adult_2: "2 Adult",
          adult_3: "3 Adult",
          adult_4: "4 Adult",
          pet_1: "1 Pet",
          pet_2: "2 Pet",
          pet_3: "3 Pet",
          pet_4: "4 Pet",
          cabin_size: "Cabin Size",
          tent_size: "Tent Size",
          tent: "Tent",
          cabin: "Cabin",
          tent_size_select: "Tent Size",
          small_tent: "Small Tent",
          large_tent: "Large Tent",
          see_pricing: "See Pricing",
          label_price_list_short: "Prices",
          label_price_list: "Prices",
          prebooking_payment_failed: "Payment failed",
          prebooking_try_payment_again: "Please try payment again",
          try_again: "Try again",
          pay_now: "Pay now",
          label_payment: "Payment",
          label_confirm: "Confirm",
          tent_size_zeltplatz: "Tent size",
          vehicle_length_stellplatz: "Vehicle length",
          booking_saved: "Booking has been saved successfully",
          booking_payment_failed: "Payment failed",
          booking_payment_success: "Payment success",
        },
      },
      de: {
        translations: {
          spot_error:
            "Ein Fehler ist aufgetreten. Bitte schließen Sie das Fenster und versuchen Sie es mit der Auswahl eines anderen Parkplatzes.",
          label_yes: "Ja",
          label_no: "Nein",
          label_email: "E-Mail",
          label_login: "Anmelden",
          label_registration: "Registrieren",
          label_forgot_password: "Passwort vergessen?",
          label_password: "Passwort",
          label_repassword: "Passwort bestätigen",
          password_error: "Passwort muss übereinstimmen",
          label_search_term: "Suchbegriff",
          label_search_by: "Suche nach",
          label_address: "Adresse",
          label_name: "Name",
          label_amount_of_spots: "Anzal der Stellplätze",
          label_maximum_stay: "Maximaler Aufenthalt",
          label_season: "Saison",
          label_street_and_house_number: "Strasse und Hausnummer",
          label_country_code: "Land",
          label_search: "Suche",
          label_area: "Ort",
          label_area_code: "PLZ",
          label_properties: "Eigenschaften",
          label_chemistry_wc: "Chemie Toiletten Entsorgung",
          label_disposal: "Grauwasser_Entsorgung",
          label_electricity: "Strom",
          label_pets: "Haustiere",
          label_shower: "Duschen",
          label_water: "Wasser",
          label_wifi: "Wifi",
          label_price_information: "Preisinformationen",
          label_electricity_costs: "Strom:",
          label_pet_cost: "pro Hund",
          label_price_child: "pro Kind",
          label_price_extra_person: "weitere Personen",
          label_price_extra_child: "weitere Kinder:",
          label_price_two_adults: "für 2 Personen",
          label_shower_costs: "Dusche:",
          label_visitor_tax: "Kurtaxe pro Personen:",
          label_water_cost: "Wasser:",
          label_wifi_cost: "Wlan:",
          label_detail_information: "Details",
          label_lat: "Breitengrad",
          label_lon: "Längengrad",
          label_phone: "Telefon",
          label_homepage: "STARTSEITE",
          hover_logout: "Ausloggen",
          label_general_information: "Generelle Informationen",
          label_resend_email_verification: "Bestätigungsemail erneut versenden",
          label_resend_text:
            "Wir haben einen Bestätigungslink an Ihre E-Mail-Adresse gesendet. Um den Vorgang abzuschließen, klicken Sie einfach auf den Link in dieser E-Mail.",
          label_admin_menu_parking_areal_list: "Parkgebiete",
          label_admin_menu_parking_areal_upload: "Hochladen",
          label_cancel: "Abbrechen",
          label_show_filters: "Filter anzeigen",
          label_show_map: "Karte anzeigen",
          label_price_ascending: "Preis aufsteigend",
          label_price_descending: "Preis absteigend",
          label_sort_distance: "Entfernung",
          label_sort_by: "Sortieren",
          label_grad: "Grad",
          label_minute: "Minute",
          label_sekunden: "Sekunden",
          label_toilet_cost: "WC:",
          label_visitor_tax_kind: "Kurtaxe pro Kind:",
          label_visitor_tax_hund: "Kurtaxe pro Hund:",
          label_chemistryWC: "Entsorgung:",
          label_automat: "Entsorgungsautomat:",
          label_disposal_cost: "Bodenentsorgung:",
          label_rubbish: "Müllentsorgung:",
          add_new: "Neu hinzufügen",
          label_areaCode: "PLZ",
          label_countryCode: "Land",
          label_search_firstname: "Vorname",
          label_search_lastname: "Nachname",
          label_search_email: "E-Mail",
          label_search_phone: "Mobilnummer",
          label_search_pl: "Suchen",
          label_search_by_value: "Suchbegriff eingeben",
          label_booking_saved: "Buchung wurde erfolgreich gespeichert!",
          label_booking_customer: "Benutzer/in wählen",
          label_booking_select_dates: "Datum und Fahrzeuglänge wählen",
          label_booking_select_spot: "Platz Nr. wählen",
          label_save_filters: "Filter Speichern",
          label_reset_filters: "Filter löschen",
          label_booking_info: "Buchungsinfo",
          label_next: "Weiter",
          label_back: "Zurück",
          label_save: "Reservieren",
          label_travellers_submitted: "Reisende wurden erfolgreich eingereicht",
          label_travellers_not_uploaded: "Travellers has not been uploaded successfully",
          label_check_required: "Bitte überprüfen Sie die Pflichtfelder.",
          label_review_submitted: "Ihre Bewertung wurde erfolgreich eingereicht",
          label_my_account: "Mein Konto",
          label_log_out: "Abmelden",
          label_admin_menu_settings: "Einstellungen",
          label_admin_menu_crm: "CRM",
          label_firstname: "Vorname",
          label_lastname: "Nachname",
          label_parking_areal_name: "Stellplatzname",
          login_success:
            "Sie haben sich erfolgreich registriert. Bitte prüfen Sie Ihre E-Mail und bestätigen Sie.",
          register_success:
            "Sie haben sich erfolgreich registriert. Bitte bestätigen Sie Ihre Registrierung mit Klick auf den Link in der E-Mail, die wir Ihnen soeben gesendet haben.",
          auth_user_not_found: "Kein Benutzer, der dieser E-Mail entspricht",
          auth_email_already_in_use: "Die E-Mail-Adresse ist bereits in Gebrauch",
          auth_email_already_exists:
            "Die angegebene E-Mail wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine eindeutige E-Mail haben",
          auth_invalid_email:
            "Der angegebene Wert für die Benutzereigenschaft email ist ungültig. Es muss eine String-E-Mail-Adresse sein.",
          auth_weak_password: "Das Passwort sollte mindestens 6 Zeichen lang sein",
          auth_wrong_password: "Falsches Passwort",
          login_forget_password_success:
            "Der Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet.",
          label_file_upload_success: "Das Bild wurde erfolgreich hochgeladen",
          label_file_upload_fail: "Das Bild wurde nicht erfolgreich hochgeladen",
          label_wrong_image_format_size_title: "Falsches Bildformat oder falsche Größe",
          label_wrong_image_format_or_size:
            " Bitte überprüfen Sie Ihr Bildformat und -größe. Es werden nur PNG- und JPG-Dateien unterstützt, die weniger als {{sizeLimit}} MB groß sind.",
          label_wrong_code: "falscher Verifizierungscode",
          verification_code: "Verifizierungscode",
          please_register:
            "Bitte erstellen Sie ein Konto über das Internet oder die mobile Anwendung, um Gästekarten zu erhalten.",
          label_startDate: "Anreise",
          label_endDate: "Abreise",
          label_canceled: "Storniert",
          label_numberPlate: "Kennzeichen",
          label_filter_by: "filtern nach",
          insufficient_point:
            "Lieber Kunde leider gibt es aktuell eine Störung am Zahlsystem. Wir arbeiten mit hochdruck an der Behebung des Problems. Bis dahin müssen Sie bitte Punkte kaufen bis Betreiber des Stellplatzes. Vielen Dank für Ihr Verständnis und schönen Aufenhlt.",
          label_reviews: "Bewertungen",
          label_add_review: "Bewertung hinzufügen",
          label_submit_review: "Bewertung abschicken",
          label_location: "Lage",
          label_calm: "Ruhe",
          label_equipment: "Ausstattung",
          label_cleanliness: "Sauberkeit",
          label_value_for_money: "Preis-Leistungs-Verhältnis",
          label_activity_opportunities: "Aktivitätsmöglichkeiten",
          label_comment: "Kommentar",
          scout_info: "CAMP/24 Check-Scout",
          date: "Datum",
          parking_space: "Stellplatz",
          arrival: "Anreise",
          departure: "Abreise",
          place_number: "Platz Nr",
          pin: "PIN",
          add_new_folder: "Neuen Ordner hinzufügen",
          create: "Erstellen",
          select_folder: "Ordner auswählen",
          find_pitches: "Stellplätze finden",
          table_no_record: "Keine Aufzeichnungen gefunden",
          booking_table_no_record: "Keine Buchungen vorhanden",
          history_table_no_record: "Keine Historie vorhanden",
          comment_table_no_record: "Keine Bewertungen vorhanden",
          change_folder: "Ändere den Ordner",
          delete: "Löschen",
          profile_submit_success: "Ihr Profil wurde erfolgreich übermittelt",
          your_account_linked: "Ihr Konto wurde verlinkt",
          your_account_already_linked: "Ihr Konto wurde bereits verlinkt",
          label_loading_profile: "Profil wird geladen...",
          profile_info_text_1:
            "Sie fragen sich, warum diese Angaben notwendig sind? Eine Übernachtung auf einem Wohnmobilstellplatz oder einem Campingplatz wird genauso behandelt wie eine Hotelübernachtung. Dafür sind gemäß Bundesmeldegesetz die Angaben in den Pflichtfeldern notwendig.",
          bills: "Rechnungen",
          your_rewiev_succesfully_deleted: "Ihre Bewertung wurde erfolgreich gelöscht",
          your_rewiev_succesfully_saved: "Ihre Bewertung wurde erfolgreich gespeichert",
          your_ratings: "Ihre Bewertungen",
          cancel_booking: "Buchung stornieren",
          cancel_booking_confirm: "Sind Sie sicher, dass Sie diese Buchung stornieren möchten?",
          yes: "Ja",
          no: "Nein",
          cannot_revert_cancel_booking:
            "Wenn Sie Ihre Buchung storniert haben, können Sie dies nicht rückgängig machen.",
          must_make_new_reservation: "Sie müssen eine neue Reservierung tätigen.",
          succesfully_cancelled_booking: "Sie haben die Buchung erfolgreich storniert",
          problem_message_try_again: "Es gibt ein Problem, bitte versuchen Sie es später erneut",
          promotion_message_camp:
            "Sie können Ihren Stellplatz nicht anfahren? Kein Problem! Dank CAMP/24 Check-In können Sie",
          cancel_free_of_charge: "kostenlos stornieren",
          add_new_traveller: " Neuen Reisenden hinzufügen +",
          no_dogs: "Keine Hunde",
          vehicles_submitted_successfully: "Ihre Fahrzeuge wurden erfolgreich eingereicht",
          check_mandatory_fields: "Bitte überprüfen Sie die Pflichtfelder:",
          add_new_vehicle: "Neues Fahrzeug hinzufügen +",
          add_trailer: " Anhänger hinzufügen +",
          can_choose_pick_based_information: " Aufgrund ihrer Angaben können Sie einen Stellplatz wählen:",
          confirmation_link_sended_mail: "Der Bestätigungslink wurde an Ihre E-Mail gesendet",
          contact_info: "Kontaktinformationen",
          general_info: "Allgemeine Informationen",
          number_of_parking_lots: "Anzahl Stellplätze",
          maximum_length: "Maximale Länge",
          maximum_stay: "Maximaler Aufenthalt",
          checkin_ab: "Check/in ab",
          checkout_bis: "Check/out bis",
          distances: "Entfernungen",
          destination: "Zielort",
          location: "Standort",
          gps: "GPS",
          update_data: "Daten aktualisieren",
          start_navigation: "Navigation Starten",
          bookable_price_for_two_person: "€ für 2 Personen",
          included: "inklusive",
          extra_person: "weitere Person",
          per_person: "pro Person",
          dog: "Hund",
          price_per_hundred_liter: "€ 100 liters",
          peak_season: "Hauptsaison",
          side_season: "Nebensaison",
          discount_cards: "Rabattkarten",
          turist_info: "Touristeninfo",
          shopping: "Einkaufen",
          other: "Sonstiges",
          current: "Aktuelles",
          restaurant: "Restaurant",
          health: "Gesundheit",
          no_info_this_category: "Keine Informationen in dieser Kategorie gefunden",
          close: "schließen",
          upload_image_description:
            "Sie waren hier schon? Super, wenn Sie auch ein Bild haben können Sie es hier hochladen.",
          upload_here: "hier hochladen",
          crop_your_image: "Ihr Bild zuschneiden",
          caption: "Bildtitel",
          maximum_limit_for_upload_input:
            "Für eine Bildunterschrift können maximal 240 Zeichen verwendet werden.",
          maximum_240_characters: "Maximal 240 Zeichen",
          agree: "Zustimmen",
          warning: "Warnung",
          parking_lot_closed_warn:
            "Der Parkplatz ist für Sie, 5 Minuten gesperrt. Sie sollten Ihre Buchung in",
          complete_seconds: "Sekunden abschließen.",
          already_have_rez_warn: "Sie haben eine Reservierung zum gewählten Datum.",
          check_dates_before_rez: "Bitte überprüfen Sie die Daten vor der Reservierung",
          label_ok: "Ok",
          parking_spot_already_rez:
            "Der Parkplatz, den Sie zu reservieren versuchen, ist bereits reserviert.",
          mistake: "Fehler",
          go_back_and_choose_another_park: "Bitte kehren Sie zurück und wählen Sie einen anderen Parkplatz.",
          child: "Kind",
          tourist_tax: "Kurtaxe",
          total: "Summe",
          total_amount: "Gesamtbetrag",
          vat: "Mwst",
          select_travellers: "Wählen Sie Reisende aus",
          agree_usage_cookie: "Ich stimme der Nutzung von Cookies ausdrücklich zu",
          delete_and_reject_cookies: "Cookies löschen und Ablehnen",
          cookie_consent:
            "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir \n Cookies. Dabei werden Daten, die Sie beim Besuch unserer Website hinterlassen gespeichert und unter \n Umstän0den an die in unserer Datenschutzerklärung erwähnten Dritten weitergegeben. Entscheiden Sie sich \n gegen die Verwendung von Cookies, kann dadurch die Funktionalität der Website eingeschränkt sein.",
          further_info: "Weitere Informationen",
          button_save: "sichern",
          persons: "Personen",
          per_additional_person: "pro weitere Person",
          casette_disposal: "Kassettenentsorgung",
          washing_machine: "Waschmaschine",
          accessibility: "Barrierefreiheit",
          playground: "Spielplatz",
          dragzone_text_image_upload:
            "Ziehen Sie einige Fotos hierher, oder klicken Sie, um Fotos auszuwählen.",
          federal_state: "Bundesland",
          picture_writing: "Bildeschreibung",
          latest_update: "Letzte Aktualisierung:",
          series: "Reihenfolge",
          activities: "Aktivitäten",
          rental_accomodation: "Mietunterkünfte",
          service: "Service",
          sightseeing_features: "Sehenswürdigkeiten",
          transport_connection: "Verkehrsanbindung",
          hint_upload_pitch_picture:
            "Bitte laden Sie hier Bilder Ihres Stellplatzes und noch lieber von den Sehenswürdigkeiten in Ihrer Umgebung hoch.",
          hint_share_your_future_guests:
            "Teilen Sie mit Ihren künftigen Gästen die Plätze, die einen Besuch wert sind.",
          hint_image_limits: "Max. 10 Bilder mit max. je 5 MB sind möglich, gerne als .png, .jpg oder .jpeg",
          hint_click_icons_to_apply_pitch:
            "Bitte klicken Sie die Icons an, die für Ihren Stellplatz zutreffen.",
          hint_selected_icons_will_appear:
            "Die von Ihnen ausgewählten Icons (grün) werden nach Ihrer Freigabe auf",
          hint_camp_display: "www.camp/24check-in.de angezeigt.",
          hint_thanks_for_register_camp_area:
            "Danke, dass Sie uns helfen, die Informationen zu Ihrem. Stellplatz/Campingplatz für Ihre Gäste zu aktualisieren.",
          hint_fill_fields_below: "Bitte füllen Sie die nachstehenden Felder vollständig aus.",
          hint_receive_link_for_approval:
            "Nach Ihrer Überarbeitung bekommen Sie von uns einen Link zur Freigabe,\n damit wir sicher sind, dass alles im Sinne zufriedener Gäste auf dem neuesten Stand ist.",
          hint_for_questions_please_contact: "Falls Sie Fragen haben, melden Sie sich gerne bei uns.",
          hint_regards_campname: "Ihr Team von CAMP/24 Check-In",
          city: "Stadt",
          price: "preis",
          on_the_map: "Auf Der Karte",
          add_to_shopping_cart: "in den Warenkorb legen",
          for_free: "Kostenlos",
          search_zip_or_city: "PLZ oder Stadt suchen",
          make_sure_vehicle_adress_profiles:
            "Stellen Sie sicher, dass Ihr Fahrzeug, Ihre Adresse und Ihre Profile ausgefüllt sind.",
          please_login_to_choose_date: "Bitte melden Sie sich an, um ein Datum wählen zu können",
          pitches: "Stellplätze",
          selected_parking_space: "Ausgewählter Parkplatz",
          posting_date: "Buchungsdatum",
          delete_folder: "Lösche Ordner",
          your_profile_submit_fail: "Ihr Profil wurde nicht erfolgreich übermittelt",
          thanks_for_using_pls_register:
            "Danke, dass Du Sie CAMP/24 Check-In nutzen. Bitte registrieren Sie sich hier einmalig (Felder mit * sind Pflichtfelder).",
          your_profile: "Dein Profil",
          mandatory_field: "Pflichtfeld",
          family_name: "Familienname",
          birthdate: "Geburtsdatum",
          birth_place: "Geburtsort",
          nationality: "Staatsangehörigkeit",
          valid_id_or_passport_number: "gültige Ausweisnummer oder Passnummer",
          postal_code: "Postleitzahl",
          residance: "Wohnort",
          street_housenumber: "Straße, Hausnummer",
          country_of_residence: "Wohnland",
          country_code_phone: "Ländervorwahl, Telefon",
          save_changes: "Änderungen speichern",
          click_here_to_upload_photo: "Klicken Sie hier, um ein Foto hochzuladen",
          success: "Erfolg",
          error_vehicle_plate_number_not_match: "Fehler! Fahrzeugplattennummer stimmt nicht überein",
          error_no_vehicle_found: "Fehler! Kein Fahrzeug gefunden",
          error_phone_belong_another_acc: "Fehler! Telefonnummer gehört zu einem anderen Konto.",
          error_invalid_code: "Fehler! Ungültiger Code",
          error_traveler_problem: "Fehler! Es gibt ein Problem mit Reisenden",
          error_sync_failed: "Fehler! Synchronisierung fehlgeschlagen",
          check_mobile_number: "Mobile Nummer überprüfen",
          for_sync_enter_sms_code:
            "für die Synchronisierung der Buchung, die Sie gerade im Checkpoint haben, geben Sie bitte den Code ein, den Sie per SMS auf Ihr Mobiltelefon erhalten haben",
          code: "Code",
          verify: "Überprüfen",
          from: "von",
          to: "bis",
          same_adress_as_user: "Gleiche Adresse wie Nutzer",
          country: "Staat",
          edit: "Bearbeiten",
          length_in_meters: "Länge in Meter",
          save_trailer: "Anhänger Speichern",
          vehicle_owner: "Fahrzeughalter",
          save_vehicle: "Fahrzeug Speichern",
          this_is_required: "Dies ist erforderlich",
          your_password_send_successfully: "Ihr Passwort wurde erfolgreich übermittelt",
          your_password_failed_send: "Ihr Passwort wurde nicht erfolgreich übermittelt",
          change_password: "Passwort ändern",
          current_password: "Aktuelles Passwort",
          new_password: "Neues Passwort",
          permanently_delete_account: "Konto endgültig löschen",
          press_delete_to_confirm:
            "Bitte geben Sie im Bestätigungsfeld den Text 'LÖSCHEN' in Großbuchstaben ein, um die Löschung Ihres Kontos zu bestätigen",
          confirm: "Bestätigen",
          welcome: "WILLKOMMEN",
          delete_account: "Konto löschen",
          profile: "Profil",
          traveler: "Reisende",
          vehicles: "Fahrzeuge",
          favourites: "Favoriten",
          bookings: "Buchungen",
          history: "Historie",
          sidebar_cancel: "Stornieren",
          imprint: "Impressum",
          privacy: "Datenschutz",
          add_space: "Platz Hinzufügen",
          gallery: "Galerie",
          show_map: "Karte einblenden",
          hide_map: "Karte ausblenden",
          link_copied_clipboard: "Link in die Zwischenablage kopiert.",
          standard: "Standard",
          create_new_folder: "NEUEN ORDNER ERSTELLEN",
          add: "Hinzufügen",
          book: "Buchen",
          reserve_now: "Jetzt Reservieren",
          prebooking_save_onprocess: "Ihre Vorreservierung wird bearbeitet!",
          before_reservation_add_vehicle:
            "um eine Reservierung vorzunehmen, sollten Sie Ihr Fahrzeug auf der Kontoseite hinzufügen",
          camping: "Camping",
          contact: "Kontakt",
          b2b_from_campers_to_camper: "Von Camper für Camper",
          b2b_over_40_year_exp:
            "Über 40 Jahre Camping Erfahrung sind maßgeblich bei der Entwicklung unseres Systems mit eingeflossen.",
          b2b_benefits_our_system: "Profitieren Sie von den vielen Vorteilen unseres Systems:",
          b2b_vat_savings: "Mehrwertsteuerersparnisse",
          b2b_flexiable_payment_based_season: "Flexible Preisgestaltung nach Saison und Bedarf",
          b2b_register_of_overnight: "Registrierung aller Übernachtungsgäste",
          fair_secure_ticket: "Sichern Sie sich Ihr Gratis Ticket für den Caravan Salon 2020",
          fair_thanks_for_interest: "Vielen Dank für Ihr Interesse am Stellplatz der Zukunft.",
          fair_free_ticket_mail:
            "Wir freuen uns darauf sie an unserem Stand A61 in Halle 12 zu begrüßen. Sie erhalten innerhalb der nächsten Stunden einen Online Gutschein für ein Gratis Ticket. Diesen Gutschein müssen Sie bis zum 28.08.20 unter folgendem Link in ein Ticket einlösen",
          fair_caravan_salon_contact:
            "Gerne können Sie vorab mit uns per Mail oder Telefon einen Termin an unserem Stand auf de Caravan Salon vereinbaren",
          fair_free_transport_message:
            "Hinweis: Mit dem Ticket können Sie gratis die öffentlichen Verkehrsmittel des VRR benutzen.",
          sign_in_with_google: "Anmeldung mit Google",
          sign_in_with_apple: "Melden Sie sich mit Apple an",
          your_payment_arrived_thanks: "Ihre Zahlung ist eingetroffen, danke!",
          campsite: "Campingplatz",
          start_plan_your_trip: "Starten Sie hier Ihre Reiseplanung",
          search_for_pitch: "Bitte wählen Sie einen Parkplatz",
          no_result_pitch_search: "Wir konnten keine Stellplätze für diesen Suchbegriff finden.",
          booking_reservation_needed: "Für Reservierung bitte anmelden oder registrieren.",
          vehicle_needed_for_booking: "Für Reservierung bitte Fahrzeug hinzufügen.",
          login_required: "Anmeldung erforderlich",
          vehicle_length: "Fahrzeuglänge:",
          vehicle_length_stellplatz: "Stellplatz: Fahrzeuglänge",
          please_fill_all_fields: "Bitte füllen Sie alle Felder aus",
          pre_booking_form: "Digitale Meldeschein",
          label_mobile_enter: "Bitte wählen Sie Ihr Ländervorwahl und geben Sie Mobilnummer ein",
          sms_check: "SMS Code Senden",
          description: "Notizen",
          label_code_input: "Bitte geben Sie den Code ein, den Sie per SMS erhalten haben",
          label_save_pre: "Vorreservieren",
          too_many_tries: "Zu viele Versuche",
          resend_code: "Code erneut senden",
          confirmed_before: "Bereits bestätigt",
          booking_confirmed: "Vorreservierung bestätigt",
          code_is_wrong: "Code ist falsch",
          code_is_expired: "Code ist abgelaufen",
          prebooking_error: "Vorreservierungsfehler",
          sms_verified_shortly: "Ihre Vorreservierung wird in Kürze per SMS bestätigt",
          label_prebooking_exists:
            "Sie haben eine Vorreservierung in diesem Campingplatz, die auf die Bestätigung wartet. Möchten Sie eine neue Vorreservierung anlegen?",
          rotate_device: "Für eine optimale Ansicht drehen Sie ihre Gerät bitte in Querformat.",
          add_traveller: "Reisenden hinzufügen",
          new_traveller: "Mitreisenden",
          arrival_date: "Anreisedatum",
          departure_date: "Abreisedatum",
          vehicle_size: "Fahrzeuggröße",
          adult_count: "Erwachsenen über 12 Jahre",
          adult_count_14: "Erwachsenen über 14 Jahre",
          child_count: "Kinder 3-12 Jahre",
          child_count_14: "Kinder 3-14 Jahre",
          pet_count: "Haustiere",
          valid_until: "Gültig bis",
          s_label: "S <6 meter",
          m_label: "M <8 meter",
          l_label: "L <9 meter",
          xl_label: "XL <10 meter",
          xxl_label: "XXL <12 meter",
          label_spotnumber: "Platznummer",
          label_why: "Warum sind diese Angaben notwendig?",
          label_notice:
            "Bitte ergänzen Sie noch folgende Angaben, das wird Ihnen beim Check-in viel Zeit ersparen",
          child_1: "Gesamt 1 Kind",
          child_2: "Gesamt 2 Kinder",
          child_3: "Gesamt 3 Kinder",
          child_4: "Gesamt 4 Kinder",
          adult_1: "Gesamt 1 Erwachsener",
          adult_2: "Gesamt 2 Erwachsene",
          adult_3: "Gesamt 3 Erwachsene",
          adult_4: "Gesamt 4 Erwachsene",
          pet_1: "Gesamt 1 Haustier",
          pet_2: "Gesamt 2 Haustiere",
          pet_3: "Gesamt 3 Haustiere",
          pet_4: "Gesamt 4 Haustiere",
          none: "Keine",
          prebooking: "Vorreservierung",
          cabin_size: "Hütten",
          tent_size: "Zeltgröße",
          tent_size_zeltplatz: "Zeltplatz: Zeltgröße",
          tent: "Zelt",
          cabin: "Hütte",
          tent_size_select: "Zelt Größe",
          small_tent: "Kleines Zelt",
          large_tent: "Großes Zelt",
          see_pricing: "Voraussichtliche Abrechnung",
          label_price_list: "Voraussichtliche Abrechnung",
          label_price_list_short: "Vorauss. Abrechnung",
          try_again: "Erneut versuchen",
          prebooking_payment_failed: "Zahlung fehlgeschlagen",
          prebooking_try_payment_again: "Bitte versuchen Sie die Zahlung erneut",
          pay_now: "Jetzt zahlen",
          label_payment: "Bezahlung",
          label_confirm: "Bestätigen",
          code_missing: "Code fehlt",
          booking_saved: "Buchung wurde erfolgreich gespeichert",
          booking_payment_failed: "Zahlung fehlgeschlagen",
          booking_payment_success: "Zahlung erfolgreich",
        },
      },
    },
    lng: "de",
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
