import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions } from "react-query";

import { ICreatePayBackParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";

export const useCreatePayBack = (options?: UseMutationOptions<any, any, ICreatePayBackParams>) => {
  const { publicApiService } = useContext(apiServiceContext);

  const mutationFn = useCallback(
    (payload: ICreatePayBackParams) =>
      publicApiService.createCancellableRequest((cancelToken) =>
        publicApiService.payBack(payload, cancelToken)
      ),
    [publicApiService]
  );

  const mutation = useMutation<any, any, ICreatePayBackParams>(mutationFn, options);

  return mutation;
};
