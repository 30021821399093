import { useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  TextField,
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  Backdrop,
  DialogTitle,
  CircularProgress,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { useUploadParkingArealImage } from "rest";
import { useAreaDetailState } from "context/AreaDetail";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

function UploadImage({ id, setDialogOpenPhoto, setOpenImageBackdrop }) {
  const { t } = useTranslation();
  const { setUploadSuccess, setUploadError } = useAreaDetailState();
  const classes = useStyles();
  const [imageUploadDialog, setImageUploadDialog] = useState(false);
  const [uploadImage, setUploadImage] = useState<boolean>(false);
  const [imageToCrop, setImageToCrop] = useState<any>();
  // const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState<any>();
  const [limitExceed, setLimitExceed] = useState<boolean>(false);
  const [caption, setCaption] = useState<string>("");

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setUploadImage(true);
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        // const formData = new FormData();

        // Pass the image file name as the third parameter if necessary.
        // formData.append('croppedImage', blob/*, 'example.png' */);
        const image = new File([blob], "filename");
        uploadParkingArealImage({
          parkingArealId: id,
          file: image,
          desc: caption,
        });
      });
      // upload
    }
  };

  const { mutate: uploadParkingArealImage } = useUploadParkingArealImage({
    onSuccess: () => {
      setUploadSuccess(true);
      setImageUploadDialog(false);
      setUploadImage(false);
      setTimeout(() => {
        setUploadSuccess(false);
      }, 5000);
    },
    onError: (error) => {
      console.error(error);
      setUploadError(true);
      setTimeout(() => {
        setUploadError(false);
      }, 5000);
      setOpenImageBackdrop(false);
      console.error(error);
    },
  });

  const handleChange = (files: FileList | null) => {
    if (files && files.item(0)) {
      //@ts-ignore
      const type = files.item(0).type;
      const arr = ["image/png", "image/jpg", "image/jpeg"];
      //@ts-ignore
      if (!arr.includes(type)) {
        setDialogOpenPhoto(true);
        //@ts-ignore
      } else if (files.item(0).size > 10222181) {
        setDialogOpenPhoto(true);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setImageToCrop(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
        setImageUploadDialog(true);
      }
    }
  };

  const imageZoomIn = () => {
    cropper.zoom(0.2);
  };
  const imageZoomOut = () => {
    cropper.zoom(-0.2);
  };
  const addCaption = (e: any) => {
    if (e.target.value.length < 241) {
      setLimitExceed(false);
      setCaption(e.target.value);
    } else {
      setLimitExceed(true);
    }
  };
  const handleCloseImageUploadDialog = () => {
    setImageUploadDialog(false);
    setCaption("");
    setLimitExceed(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event: any) => handleChange(event.target.files)}
          />
          <Typography gutterBottom variant="body1" color="primary" align="left">
            {t("upload_image_description")}
          </Typography>

          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              disableElevation
              style={{ width: "100%" }}
            >
              {t("upload_here")}
            </Button>
          </label>
        </CardContent>
      </Card>

      <Dialog
        open={imageUploadDialog}
        onClose={handleCloseImageUploadDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop className={classes.backdrop} open={uploadImage}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="alert-dialog-title">{t("crop_your_image")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Cropper
              style={{ height: 540, width: 960 }}
              zoomTo={0.5}
              // initialAspectRatio={1}
              // preview=".img-preview"
              background={true}
              src={imageToCrop}
              // viewMode={1}
              // movable={true}
              aspectRatio={16 / 9}
              minCropBoxHeight={90}
              minCropBoxWidth={160}
              zoomOnWheel={false}
              dragMode="move"
              // background={false}
              responsive={true}
              // autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: "10px" }}>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label={t("caption")}
                placeholder={t("maximum_limit_for_upload_input")}
                multiline
                fullWidth={true}
                value={caption}
                onChange={(event) => addCaption(event)}
                rows={2}
                variant="outlined"
                helperText={limitExceed ? t("maximum_240_characters") : ""}
                error={limitExceed}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <IconButton onClick={imageZoomOut} color="primary">
                <ZoomOutIcon />
              </IconButton>
              <IconButton onClick={imageZoomIn} color="primary">
                <ZoomInIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Button onClick={handleCloseImageUploadDialog} color="primary">
                {t("label_cancel")}
              </Button>
              <Button onClick={getCropData} color="primary" autoFocus>
                {t("agree")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadImage;
