import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import {
  CssBaseline,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Paper,
  RadioGroup,
  Snackbar,
  Fade,
  Radio,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { RadioProps } from "@material-ui/core/Radio";
import { useTravellers } from "context";
import { useQuery, useQueryClient } from "react-query";

import { useUserTravellers, usePetStatus } from "rest";

import { TravellersIcon } from "components/icons";
import { TravellerCard } from "components/user/travellers";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 16,
      fontSize: 14,
    },
    cardActions: {
      padding: 16,
      display: "flex",
      justifyContent: "space-between",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const UserTravellers = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { travellers, setTravellers, setExpanded, savedTraveller, setSavedTraveller, setNewTraveller } =
    useTravellers();
  const location = useLocation();

  const [checked, setChecked] = useState(false);
  const [checkedE, setCheckedE] = useState(false);

  const [checkedC, setCheckedC] = useState(false);
  const [petCount, setPetCount] = useState("female");
  const [fade, setFade] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gap, setGap] = useState(false);

  const { mutate: postPetStatus } = usePetStatus({
    onSuccess: (results, variables) => {
      queryClient.invalidateQueries("user_profile");
      // props.refreshUser().then(() => {
      setPetCount(variables.pet);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
      // });
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const handlePetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = (event.target as HTMLInputElement).value;
    postPetStatus({ userId: location?.state?.user?.id, pet: val });
  };

  useUserTravellers({
    onSuccess: (results) => {
      let arrResult: any[] = [];
      results?.forEach((x: any) => {
        arrResult.push({
          address: {
            area: x.area,
            areaCode: x.areaCode,
            countryCode: x.countryCode,
            streetAndHouseNumber: x.streetAndHouseNumber,
          },
          person: {
            birthDate: x.birthDate,
            birthPlace: x.birthPlace,
            firstName: x.firstName,
            identityCardNo: x.identityCardNo,
            lastName: x.lastName,
            nationality: x.nationality,
            avatar: x.avatar,
          },
          sameAddressAsUser: x.sameAddressAsUser,
          enabled: x.enabled,
          id: x.id,
          date: x.createdAt,
        });
      });
      arrResult.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      setTravellers(arrResult);
      setFade(true);
      setIsLoading(false);
    },
    onError: (error) => console.error(error),
  });

  React.useEffect(() => {
    setIsLoading(true);
    if (props.gap !== undefined) {
      setGap(props.gap);
    }
    setPetCount(location?.state?.user?.pet);
  }, []);

  const addTraveller = () => {
    setNewTraveller(true);
    setTravellers([
      ...travellers,
      {
        address: {
          area: "",
          areaCode: "",
          countryCode: "",
          streetAndHouseNumber: "",
        },
        person: {
          birthDate: "",
          birthPlace: "",
          firstName: "",
          identityCardNo: "",
          lastName: "",
          nationality: "",
        },
        sameAddressAsUser: true,
        enabled: true,
        id: "",
      },
    ]);
    setSavedTraveller(false);

    setExpanded(`panel${travellers.length}`);
  };

  if (travellers != null) {
    return (
      <>
        <CssBaseline />
        <Snackbar open={checked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="success"> {t("label_travellers_submitted")}</Alert>
        </Snackbar>
        <Snackbar open={checkedE} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="error"> {t("label_travellers_not_uploaded")}</Alert>
        </Snackbar>
        <Snackbar open={checkedC} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="error"> {t("label_check_required")}</Alert>
        </Snackbar>

        <Fade in={fade} timeout={3000}>
          <>
            <Card>
              <CardContent>
                <Typography className={classes.title} color="primary" gutterBottom>
                  Reisende
                </Typography>
                <div>
                  {travellers.map((item: any, index: number) => (
                    <TravellerCard
                      key={index + 9}
                      item={item}
                      index={index}
                      refreshUser={props.refreshUser}
                      setChecked={setChecked}
                      setCheckedE={setCheckedE}
                      setCheckedC={setCheckedC}
                    />
                  ))}
                </div>
              </CardContent>
              <CardActions className={classes.cardActions}>
                {savedTraveller ? (
                  <Button onClick={addTraveller} variant="contained" color="primary" disableElevation>
                    {t("add_new_traveller")}
                  </Button>
                ) : null}
              </CardActions>
            </Card>

            {gap === false && (
              <>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ paddingTop: "100px" }}
                >
                  <Grid item>
                    <Paper elevation={0} style={{ backgroundColor: "transparent" }}>
                      <TravellersIcon height={180} />
                    </Paper>
                  </Grid>

                  <Grid item>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={petCount}
                        onChange={handlePetChange}
                      >
                        <FormControlLabel value="none" control={<GreenRadio />} label={t("no_dogs") + ""} />
                        <FormControlLabel value="1" control={<GreenRadio />} label="1" />
                        <FormControlLabel value="2" control={<GreenRadio />} label="2" />
                        <FormControlLabel value="3" control={<GreenRadio />} label="3" />
                        <FormControlLabel value="4" control={<GreenRadio />} label="4" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </Fade>
      </>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export const Travellers = withTranslation()(UserTravellers);
