import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions } from "react-query";

import { IPetStatusParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";

export const usePetStatus = (options?: UseMutationOptions<any, any, IPetStatusParams>) => {
  const { travellersApiService } = useContext(apiServiceContext);

  const mutationFn = useCallback(
    (payload: IPetStatusParams) =>
      travellersApiService.createCancellableRequest((cancelToken) =>
        travellersApiService.petStatus(payload, cancelToken)
      ),
    [travellersApiService]
  );

  const mutation = useMutation<any, any, IPetStatusParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
