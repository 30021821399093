import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";
import { IFetchParkingArealByIdParams } from "../domain";

export const useParkingAreaById = <TData = any, TError = any>(
  params: IFetchParkingArealByIdParams,
  queryOptions: UseQueryProps<TData, TError> = {}
) => {
  const { publicApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      publicApiService.createCancellableRequest((cancelToken) =>
        publicApiService.getParkingArealById(params, cancelToken)
      ),
    [publicApiService, params]
  );

  return useQuery(QueryKeys.parkingArealById(params), queryFn, queryOptions);
};
