import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { KeyboardArrowDown, KeyboardArrowUp, Delete } from "@material-ui/icons";
import { Table, TableBody, TableCell, TableHead, IconButton, Collapse, TableRow } from "@mui/material";
import { Item } from ".";
import { makeStyles } from "@material-ui/styles";
import { useDeleteOrder } from "rest/orders-service";
import { close } from "inspector";

const statusTranslations = {
  PENDING: "Ausstehend",
  CONFIRMED: "Bestätigt",
  CANCELLED: "Storniert",
  COMPLETED: "Abgeschlossen",
};

const colors = {
  PENDING: "lightblue",
  CONFIRMED: "orange",
  CANCELLED: "red",
  COMPLETED: "green",
};

export const RowItem = (props: { item: Item; deleteOrder }) => {
  const { item, deleteOrder } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <TableRow
        sx={{
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {new Date(item.date).toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </TableCell>
        <TableCell>{item.price} €</TableCell>
        <TableCell>
          <Tooltip title={statusTranslations[item?.status] || "Unbekannter Status"}>
            <Box
              sx={{ bgcolor: colors?.[item?.status] || "red", width: 30, height: 30, borderRadius: "50%" }}
            />
          </Tooltip>
          {/* {item.status} */}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="delete dialog"
            size="small"
            onClick={(e) => deleteOrder(item.id, e)}
            style={{ color: "#00713C" }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& > *": { borderBottom: "unset" }, bgcolor: "#F5F5F5" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="purchases">
              <TableHead>
                <TableRow
                // sx={{ "& > *": { borderBottom: "unset !important" } }}
                >
                  <TableCell>Produktname</TableCell>
                  <TableCell>Preis pro Stück</TableCell>
                  <TableCell>Anzahl</TableCell>
                  <TableCell>Preis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item?.items?.map((i) => {
                  return (
                    <TableRow>
                      <TableCell>{i?.name}</TableCell>
                      <TableCell>{i?.perPrice}</TableCell>
                      <TableCell>{i?.count}</TableCell>
                      <TableCell>{i?.price}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
