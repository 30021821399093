import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { ICreateCommentUserParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useCreateCommentUser = (options?: UseMutationOptions<any, any, ICreateCommentUserParams>) => {
  const { commentsApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: ICreateCommentUserParams) =>
      commentsApiService.createCancellableRequest((cancelToken) =>
        commentsApiService.createCommentUser(payload, cancelToken)
      ),
    [commentsApiService]
  );

  const mutation = useMutation<any, any, ICreateCommentUserParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.COMMENT_OF_USER);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
