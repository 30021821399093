import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";

export const useVehicle = <TData = any, TError = any>(queryOptions: UseQueryProps<TData, TError> = {}) => {
  const { vehicleApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      vehicleApiService.createCancellableRequest((cancelToken) => vehicleApiService.getVehicle(cancelToken)),
    [vehicleApiService]
  );

  return useQuery(QueryKeys.userVehicle(), queryFn, queryOptions);
};
