import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { UserApiService } from "./user-service";
import { BookingApiService } from "./booking-service";
import { BookmarkApiService } from "./bookmark-service";
import { CommentApiService } from "./comment-service";
import { PublicApiService } from "./public-service";
import { TravellerApiService } from "./traveller-service";
import { VehicleApiService } from "./vehicle-service";
import { UploadApiService } from "./upload-service";
import { FilterApiService } from "./filter-service";
import { SearchApiService } from "./search-service";
import { OrdersApiService } from "./orders-service";

export interface IApiServiceContextProps {
  userApiService: UserApiService;
  bookingApiService: BookingApiService;
  bookmarksApiService: BookmarkApiService;
  commentsApiService: CommentApiService;
  publicApiService: PublicApiService;
  travellersApiService: TravellerApiService;
  vehicleApiService: VehicleApiService;
  uploadApiService: UploadApiService;
  filterApiService: FilterApiService;
  searchApiService: SearchApiService;
  ordersApiService: OrdersApiService;
}

const services: IApiServiceContextProps = {
  userApiService: new UserApiService(),
  bookingApiService: new BookingApiService(),
  bookmarksApiService: new BookmarkApiService(),
  commentsApiService: new CommentApiService(),
  publicApiService: new PublicApiService(),
  travellersApiService: new TravellerApiService(),
  vehicleApiService: new VehicleApiService(),
  uploadApiService: new UploadApiService(),
  filterApiService: new FilterApiService(),
  searchApiService: new SearchApiService(),
  ordersApiService: new OrdersApiService(),
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const apiServiceContext = React.createContext<IApiServiceContextProps>(services);

export const ApiServiceProvider: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <apiServiceContext.Provider value={services}>{children}</apiServiceContext.Provider>
    </QueryClientProvider>
  );
};
