import React, { useContext, useEffect } from "react";
import {
  Card,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { AuthContext } from "context/Auth";
import { useTranslation } from "react-i18next";

export function PriceInfoBookableCard({ item, vehicleType }) {
  const { currentUser }: any = useContext(AuthContext);
  const { t } = useTranslation();

  if (!item?.price) {
    item = { ...item, ...{ price: item?.priceInformation } };
  }

  return (
    <Card style={{ marginBottom: 18 }}>
      {item.price !== undefined && (
        <List
          dense={true}
          subheader={<ListSubheader color="primary">{t("label_price_information")}</ListSubheader>}
        >
          {item?.season?.length > 0 ? (
            <ListItem>
              <ListItemAvatar key="transition-group-content">
                <Avatar variant="square" src={`/assets/images/PriceInformation/Green/01.png`} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: "revert",
                      }}
                      component="div"
                    >
                      {vehicleType && item.season[0].hasOwnProperty("defB") ? (
                        currentUser ? (
                          <span>ab </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {vehicleType && item.season[0].hasOwnProperty("defB")
                        ? currentUser
                          ? item.season
                              .filter((e: any) => e.default === true)[0]
                              [vehicleType][vehicleType + "0"].replace(".", ",")
                          : item.seasonp
                              .filter((e: any) => e.default === true)[0]
                              ["B"]["B0"].replace(".", ",")
                        : ""}
                      <Typography
                        display={"inline"}
                        style={{
                          fontWeight: 400,
                          fontSize: "revert",
                        }}
                      >
                        {" "}
                        {t("bookable_price_for_two_person")}
                      </Typography>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ) : (
            ""
          )}
          {(item?.price?.priceExtraAdult && item?.price?.priceExtraAdult !== "0") ||
          (item?.price?.priceChild && item?.price?.priceChild !== "0") ? (
            <ListItem>
              <ListItemAvatar key="priceExtraAdult">
                <Avatar variant="square" src={`/assets/images/PriceInformation/Green/02.png`} />
              </ListItemAvatar>
              {item?.price?.priceExtraAdult && !item?.price?.priceChild ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item?.price?.priceExtraAdult.split("/")[0] === "0"
                    ? t("included")
                    : item?.price?.priceExtraAdult.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("extra_person")}
                  </Typography>
                </Typography>
              ) : !item?.price?.priceExtraAdult && item.price?.priceChild ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item?.price?.priceChild.split("/")[0] === "0"
                    ? t("included")
                    : item.price.priceChild.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("label_price_child")}
                  </Typography>
                </Typography>
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price?.priceExtraAdult.split("/")[0] === "0"
                    ? t("included")
                    : item.price?.priceExtraAdult.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("extra_person")} /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.price.priceChild.split("/")[0] === "0"
                      ? t("included")
                      : item.price.priceChild.split("/")[0] + " €"}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("label_price_child")}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {(item.price?.visitorTax && item.price?.visitorTax !== "0") ||
          (item.price?.visitorTaxKind && item.price?.visitorTaxKind !== "0") ||
          (item.price?.visitorTaxHund && item.price?.visitorTaxHund !== "0") ? (
            <ListItem>
              <ListItemAvatar key="priceExtraAdult">
                <Avatar variant="square" src={`/assets/images/PriceInformation/Green/03.png`} />
              </ListItemAvatar>
              {item.price.visitorTax && !item.price.visitorTaxKind && !item.price.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.visitorTax.split("/")[0] === "0"
                    ? t("included")
                    : item.price.visitorTax.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("per_person")}
                  </Typography>
                </Typography>
              ) : !item.price.visitorTax && item.price.visitorTaxKind && !item.price.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.visitorTaxKind.split("/")[0] === "0"
                    ? t("included")
                    : item.price.visitorTaxKind.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("label_price_child")}
                  </Typography>
                </Typography>
              ) : !item.price.visitorTax && !item.price.visitorTaxKind && item.price.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.visitorTaxHund.split("/")[0] === "0"
                    ? t("included")
                    : item.price.visitorTaxHund.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("dog")}
                  </Typography>
                </Typography>
              ) : item.price.visitorTax && item.price.visitorTaxKind && !item.price.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.visitorTax.split("/")[0] === "0"
                    ? t("included")
                    : item.price.visitorTax.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("per_person")} /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.price.visitorTaxKind.split("/")[0] === "0"
                      ? t("included")
                      : item.price.visitorTaxKind.split("/")[0] + " €"}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("label_price_child")}
                  </Typography>
                </Typography>
              ) : item.price.visitorTax && !item.price.visitorTaxKind && item.price.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.visitorTax.split("/")[0] === "0"
                    ? t("included")
                    : item.price.visitorTax.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("per_person")} /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.price.visitorTaxHund.split("/")[0] === "0"
                      ? t("included")
                      : item.price.visitorTaxHund.split("/")[0] + " €"}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("dog")}
                  </Typography>
                </Typography>
              ) : !item.price.visitorTax && item.price.visitorTaxKind && item.price.visitorTaxHund ? (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.visitorTaxKind.split("/")[0] === "0"
                    ? t("included")
                    : item.price.visitorTaxKind.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("label_price_child") + "/ "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.price.visitorTaxHund.split("/")[0] === "0"
                      ? t("included")
                      : item.price.visitorTaxHund.split("/")[0] + " €"}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("dog")}
                  </Typography>
                </Typography>
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.visitorTax.split("/")[0] === "0"
                    ? t("included")
                    : item.price.visitorTax.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("per_person")} /{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.price.visitorTaxKind.split("/")[0] === "0"
                      ? t("included")
                      : item.price.visitorTaxKind.split("/")[0] + " €"}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("label_price_child") + "/ "}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {item.price.visitorTaxHund.split("/")[0] === "0"
                      ? t("included")
                      : item.price.visitorTaxHund.split("/")[0] + " €"}
                  </Typography>
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {t("dog")}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.price?.electricityCosts ? (
            <ListItem>
              <ListItemAvatar key="electricity48">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/0.png`} />
              </ListItemAvatar>

              {item.price.electricityCosts === "0" ? (
                <ListItemText primary="inklusive" style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.electricityCosts.split("/")[0] + " € kW"}
                  <Typography
                    display={"inline"}
                    style={{
                      fontWeight: 400,
                      fontSize: "revert",
                    }}
                  >
                    {" "}
                    {item.price.electricityCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
          {item.price?.waterCosts ? (
            <ListItem>
              <ListItemAvatar key="water48">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/1.png`} />
              </ListItemAvatar>
              {item.price.waterCosts === "0" ? (
                <ListItemText primary="inklusive" style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.waterCosts.split("/")[0] + " " + t("price_per_hundred_liter")}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                    {item.price.waterCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price?.showerCosts && item.price?.showerCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="shower48">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/3.png`} />
              </ListItemAvatar>
              {item.price.showerCosts === "inklusive" ? (
                <ListItemText primary={item.price.showerCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.showerCosts.split("/")[0] + " €"}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.price.showerCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.price.showerCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price?.toiletCosts && item.price?.toiletCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="wifi48">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/2.png`} />
              </ListItemAvatar>
              {item.price.toiletCosts === "inklusive" ? (
                <ListItemText primary={item.price.toiletCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.toiletCosts.split("/")[0] + " €"}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.price.toiletCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.price.toiletCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price?.wifiCosts && item.price?.wifiCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="wifi48">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/5.png`} />
              </ListItemAvatar>
              {item.price.wifiCosts === "inklusive" ? (
                <ListItemText primary={item.price.wifiCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.wifiCosts.split("/")[0] + " €"}
                  <Typography display={"inline"} style={{ fontWeight: 400, fontSize: "revert" }}>
                    {" "}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price?.petCosts && item.price?.petCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="hund48">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/7.png`} />
              </ListItemAvatar>
              {item.price.petCosts === "0" ? (
                <ListItemText primary={t("included")} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.petCosts.split("/")[0] + " €"}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.price.petCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.price.petCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price?.chemistryWCCosts && item.price?.chemistryWCCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="chemistryWCCosts">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/10.png`} />
              </ListItemAvatar>
              {item.price.chemistryWCCosts === "inklusive" ? (
                <ListItemText primary={item.price.chemistryWCCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.chemistryWCCosts.split("/")[0] + " €"}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.price.chemistryWCCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.price.chemistryWCCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price.automatCosts && item.price.automatCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="automatCosts">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/12.png`} />
              </ListItemAvatar>
              {item.price.automatCosts === "inklusive" ? (
                <ListItemText primary={item.price.automatCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.automatCosts.split("/")[0] + " €"}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.price.automatCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.price.automatCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price.disposalCosts && item.price.disposalCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="disposalCosts">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/11.png`} />
              </ListItemAvatar>
              {item.price.disposalCosts === "inklusive" ? (
                <ListItemText primary={item.price.disposalCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.disposalCosts.split("/")[0] + " €"}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.price.disposalCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.price.disposalCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}

          {item.bookable && item.price.rubbishCosts && item.price.rubbishCosts !== "0" ? (
            <ListItem>
              <ListItemAvatar key="rubbishCosts">
                <Avatar variant="square" src={`/assets/images/Fittings/Green/9.png`} />
              </ListItemAvatar>
              {item.price.rubbishCosts === "inklusive" ? (
                <ListItemText primary={item.price.rubbishCosts} style={{ marginRight: "50px" }} />
              ) : (
                <Typography style={{ fontWeight: 400, fontSize: "revert" }} component="div">
                  {item.price.rubbishCosts.split("/")[0] + " €"}
                  <Typography
                    display={"inline"}
                    style={{
                      paddingLeft: `${(10 - item.price.rubbishCosts.split("/")[0].length) * 8.5}px`,
                      fontSize: "revert",
                    }}
                  >
                    {item.price.rubbishCosts.split("/")[1]}
                  </Typography>
                </Typography>
              )}
            </ListItem>
          ) : (
            ""
          )}
        </List>
      )}
    </Card>
  );
}
