import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  List,
  ListItem,
  Tooltip,
  Switch,
} from "@material-ui/core";

import { useVehicle } from "context";
import { useUpdateVehicle } from "rest";
import { InformationIcon } from "components";
// const mv = "/assets/images/mv.jpg";
const mv = "/assets/images/auto.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 12,
      fontSize: 20,
    },
    description: {
      marginBottom: 12,
      fontSize: 14,
    },
    media: {
      width: 200,
      height: 140,
    },
    details: {
      display: "flex",
    },
    content: {
      padding: "16px !important",
      flex: "1 0 auto",
    },
    disabledDiv: {
      pointerEvents: "none",
      opacity: "0.7",
      transition: "0.5s",
      maxWidth: 345,
    },
    root: {
      maxWidth: 345,
    },
  })
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const RedSwitch = withStyles({
  switchBase: {
    color: "#FF0000",
  },
  checked: {},
  track: {},
})(Switch);

// const RedSwitch = withStyles({
//   switchBase: {
//     color: "#FF0000 !important",
//   },
//   checked: { color: "#54AE36 !important" },
//   track: {},
// })(Switch);

export const CarVehicleCard = ({ openCarVehicleForm, saveVehicles }) => {
  const classes = useStyles();

  const { card1, card2, prev, vehicles, card3, setChecked, setSavedVehicle, setCheckedE } = useVehicle();

  const { mutate: updateVehicle } = useUpdateVehicle({
    onSuccess: () => {
      setSavedVehicle(false);
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const handleCarActive = (e: any) => {
    let updateVehicleObject = {
      lengthInM: vehicles.lengthInM.replace(",", "."),
      numberplate: vehicles.numberplate.toString(),
      pendantLengthInM: vehicles.pendant.lengthInM.replace(",", "."),
      pendantNumberplate: vehicles.pendant.numberplate.toString(),
      isPendantActive: vehicles.pendant.isPendantActive,
      carLengthInM: vehicles.car.lengthInM.replace(",", "."),
      carNumberplate: vehicles.car.numberplate.toString(),
      isCarActive: e.target.checked,
      isVehicleActive: vehicles.isVehicleActive,
      hasMoto: vehicles.hasMoto,
      hasBike: vehicles.hasBike,
      hasTent: vehicles.hasTent,
    };

    updateVehicle(updateVehicleObject);
  };

  const removeCar = () => {
    let updateVehicleObject = {
      lengthInM: vehicles.lengthInM.replace(",", "."),
      numberplate: vehicles.numberplate.toString(),
      pendantLengthInM: vehicles.pendant.lengthInM.replace(",", "."),
      pendantNumberplate: vehicles.pendant.numberplate.toString(),
      isPendantActive: vehicles.pendant.isPendantActive,
      carLengthInM: "",
      carNumberplate: "",
      isCarActive: false,
      isVehicleActive: vehicles.isVehicleActive,
      hasMoto: vehicles.hasMoto,
      hasBike: vehicles.hasBike,
      hasTent: vehicles.hasTent,
    };

    updateVehicle(updateVehicleObject);
  };

  return (
    <Card elevation={card3} className={card1 === 5 || card2 === 5 ? classes.disabledDiv : classes.root}>
      <CardContent className={card3 === 5 ? classes.disabledDiv : ""}>
        <Typography style={{ fontWeight: "bold" }} variant="h5" component="h2" color="primary">
          Fahrzeug
        </Typography>
      </CardContent>

      <CardMedia
        // className={classes.mediaX}
        component="img"
        alt="Vehicle"
        height="200"
        image={mv}
        title="Vehicle"
      />
      <CardContent className={card3 === 5 ? classes.disabledDiv : ""}>
        <Grid
          container
          spacing={2}
          style={prev?.hasCar ? { paddingBottom: "12px" } : { paddingBottom: "0px" }}
        >
          <Grid item md={6}>
            <Button
              onClick={openCarVehicleForm}
              variant="contained"
              disableElevation
              color="primary"
              style={{ width: "100%" }}
            >
              Bearbeiten
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button
              disabled={!prev?.hasCar}
              onClick={removeCar}
              variant="contained"
              disableElevation
              style={{ width: "100%" }}
            >
              Löschen
            </Button>
          </Grid>
        </Grid>
        {prev?.hasCar && (
          <Grid container spacing={4} className={card3 === 5 ? classes.disabledDiv : ""}>
            <List aria-label="Information">
              <ListItem style={{ paddingTop: "8px", paddingBottom: "0px" }}>
                <Typography variant="h6" component="h2" color="primary">
                  Kennzeichen:
                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <Typography variant="h6" component="h2" color="textPrimary">
                  {prev?.car.numberplate}
                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <Typography variant="h6" component="h2" color="primary">
                  Länge in Meter:
                </Typography>
              </ListItem>
              <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <Typography variant="h6" component="h2" color="textPrimary" style={{ paddingRight: "90px" }}>
                  {`${prev?.car.lengthInM} Meter`}
                </Typography>
                <RedSwitch
                  checked={prev?.car.isCarActive}
                  onChange={(e: any) => handleCarActive(e)}
                  id={"isCarActive"}
                  name="checkedB"
                  // style={{
                  //   marginLeft: "95px",
                  // }}
                  color="primary"
                />
                <HtmlTooltip
                  title={
                    <>
                      <span>
                        <p>Bitte wählen Sie ihre Fahrzeuge für die Reservierung</p>
                      </span>
                    </>
                  }
                >
                  <InformationIcon style={{ fill: "green", marginLeft: "12px" }} />
                </HtmlTooltip>
              </ListItem>
            </List>
          </Grid>
        )}
      </CardContent>
    </Card>
  );

  // return (
  //   <Card className={card1 === 5 ? classes.disabledDiv : ""} elevation={card2}>
  //     <div className={classes.details}>
  //       <CardMedia className={classes.media} image={wv} title="Pendant" />
  //       <CardContent className={classes.content}>
  //         <Typography className={classes.title} color="primary" gutterBottom>
  //           {prev?.pendant.numberplate}
  //         </Typography>
  //         <Typography className={classes.description} color="primary" gutterBottom>
  //           {prev?.pendant.lengthInM}
  //         </Typography>
  //         <Grid container spacing={2} className={card2 === 5 ? classes.disabledDiv : ""}>
  //           <Grid item md={6}>
  //             <Button
  //               onClick={openPendantVehicleForm}
  //               size="small"
  //               variant="contained"
  //               disableElevation
  //               color="primary"
  //               style={{ width: "100%" }}
  //             >
  //               Bearbeiten
  //             </Button>
  //           </Grid>
  //           <Grid item md={6}>
  //             <Button
  //               onClick={removePendantVehicle}
  //               size="small"
  //               variant="contained"
  //               disableElevation
  //               style={{ width: "100%" }}
  //             >
  //               Löschen
  //             </Button>
  //           </Grid>
  //         </Grid>
  //       </CardContent>
  //     </div>
  //   </Card>
  // );
};
