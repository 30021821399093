import React from "react";
import { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Cookies } from "react-cookie-consent";
import { Cookies as Cookie } from "components";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    back: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: -1,
      backgroundImage: `url("/assets/images/back.webp")`,
    },
    dialog: {
      backgroundImage: `url("/assets/images/back.webp")`,
      backgroundPosition: "center" /* Center the image */,
      backgroundRepeat: "noRepeat" /* Do not repeat the image */,
      backgroundSize: "cover" /* Resize the background image to cover the entire container */,
    },
  })
);

// @ts-ignore
export const CookieContext = React.createContext();

// @ts-ignore
export const CookieProvider = ({ children }) => {
  const [isCookieEnabled, setCookieEnabled] = useState(false);
  const [pending, setPending] = useState(true);
  const classes = useStyles();

  const { t } = useTranslation();

  useEffect(() => {
    // FirebaseAuth.onAuthStateChanged((user) => {
    //   // @ts-ignore
    //   setCurrentUser(user);
    //   setPending(false);
    // });
    const cookie = Cookies.get("camp24checkin");
    if (cookie) {
      setCookieEnabled(true); // cookie is enabled
      setPending(false);
    } else {
      setCookieEnabled(false); // cookie is disabled
      setPending(true);
    }
    // setPending(false);
  }, []);

  const accept = () => {
    Cookies.set("camp24checkin", true);
    window.location.reload();
  };

  if (pending) {
    return (
      <div>
        <Dialog fullScreen open={true} classes={{ paper: classes.dialog }}>
          <Dialog
            // fullScreen={fullScreen}
            open={true}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            style={{ marginTop: "-20%" }}
          >
            <DialogTitle id="responsive-dialog-title">{"Hinweis"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("cookie_consent")} <Link to={"/help"}>{t("further_info")}</Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => window.location.replace("http://www.google.com")}
                color="secondary"
              >
                {t("delete_and_reject_cookies")}
              </Button>
              <Button variant="contained" onClick={accept} color="primary">
                {t("agree_usage_cookie")}
              </Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      </div>
    );
  } else {
    // if (pending) {
    //   return (
    //     <div className={classes.root}>
    //       <CircularProgress />
    //     </div>
    //   );
    // }

    return (
      <CookieContext.Provider
        value={{
          isCookieEnabled,
        }}
      >
        {children}
      </CookieContext.Provider>
    );
  }
};
