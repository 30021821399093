export const EntityType = {
  SUPPLIERS: ["suppliers"],
  SUPPLIER: ["supplier"],
  PRODUCTS: ["products"],
  PRODUCT: ["product"],
  ORDERS: ["orders"],
  ORDER: ["order"],
};

export const QueryKeys = {
  suppliers: (params) => [EntityType.SUPPLIERS[0], "LIST", JSON.stringify(params)],
  supplier: (params) => [EntityType.SUPPLIER[0], "ITEM", JSON.stringify(params)],
  products: (params) => [EntityType.PRODUCTS[0], "LIST", JSON.stringify(params)],
  product: (params) => [EntityType.PRODUCT[0], "ITEM", JSON.stringify(params)],
  orders: (params) => [EntityType.ORDERS[0], "LIST", JSON.stringify(params)],
  order: (params) => [EntityType.ORDER[0], "ITEM", JSON.stringify(params)],
};
