import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";
import { IFetchParkingSpotBookingPricesByDateParams } from "../domain";

export const useParkingSpotBookingPricesByDate = <TData = any, TError = any>(
  params: IFetchParkingSpotBookingPricesByDateParams,
  queryOptions: UseQueryProps<TData, TError> = {}
) => {
  const { bookingApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      bookingApiService.createCancellableRequest((cancelToken) =>
        bookingApiService.getParkingSpotBookingPricesByDate(params, cancelToken)
      ),
    [bookingApiService, params]
  );

  return useQuery(QueryKeys.parkingSpotBookingPricesByDate(params), queryFn, queryOptions);
};
