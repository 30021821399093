import React, { useState, useContext, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  TextField,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Collapse,
} from "@material-ui/core";
import { Alert, Rating } from "@material-ui/lab";
import { StarBorder as StarBorderIcon } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    booking: {
      backgroundColor: "#DCE6A0",
      borderBottom: "5px solid white",
    },
    red: {
      backgroundColor: "#fad0c5",
      borderBottom: "5px solid white",
    },
    titlex: {
      backgroundColor: "#FFFFFF",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

export const BookingCommentRow = ({ item, getStars, setStars, setComment, getComment, sendComment }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <TableRow className={classes.titlex}>
        <TableCell colSpan={3} style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <Typography style={{ color: "#009646" }} variant="h6">
            {item.parkingArealName}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.booking}>
        <TableCell component="th" scope="row" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <p style={{ marginBottom: "0px", marginTop: "0px" }}>
            {t("from")} :
            {" " +
              new Date(item.startDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
          </p>
          <p style={{ marginBottom: "0px", marginTop: "0px" }}>
            {t("to")} :
            {"  " +
              new Date(item.endDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
          </p>
          <p style={{ marginBottom: "0px", marginTop: "10px" }}>
            <Rating
              name={item.id}
              value={getStars[item.id]}
              defaultValue={3}
              precision={1}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              onChange={(e, value) => {
                setStars({ ...getStars, [item.id]: value });
              }}
            />
          </p>
        </TableCell>
        <TableCell style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={3}
            value={getComment[item.id]}
            variant="outlined"
            onChange={(event) => setComment({ ...getComment, [item.id]: event.target.value })}
            style={{ width: "-webkit-fill-available", backgroundColor: "#FFFFFF" }}
          />
        </TableCell>
        <TableCell align="center" size="small" style={{ paddingTop: "3px", paddingBottom: "3px" }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => sendComment(item.id, item.parkingArealId)}
          >
            {t("button_save")}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};
