import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { ICreateVehicleParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useCreateVehicle = (options?: UseMutationOptions<any, any, ICreateVehicleParams>) => {
  const { vehicleApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: ICreateVehicleParams) =>
      vehicleApiService.createCancellableRequest((cancelToken) =>
        vehicleApiService.createVehicle(payload, cancelToken)
      ),
    [vehicleApiService]
  );

  const mutation = useMutation<any, any, ICreateVehicleParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.USER_VEHICLE);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
