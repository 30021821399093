import { Typography, Grid, Tooltip, Checkbox } from "@material-ui/core";

import Zoom from "@material-ui/core/Zoom";
import {
  ActivitiesList,
  ActivitiesListEn,
  FittingsList,
  FittingsListEn,
  RentList,
  RentListEn,
  ServiceList,
  ServiceListEn,
  SightseeingList,
  SightseeingListEn,
  TransportList,
  TransportListEn,
} from "constants/campCardItems";
import {
  FittingsImages,
  ActivitiesImages,
  SightseeingImages,
  ServiceImages,
  TransportImages,
  RentImages,
} from "helpers";

const lists = {
  FittingsList: FittingsList,
  FittingsListEn: FittingsListEn,
  ActivitiesList: ActivitiesList,
  ActivitiesListEn: ActivitiesListEn,
  SightseeingList: SightseeingList,
  SightseeingListEn: SightseeingListEn,
  TransportList: TransportList,
  TransportListEn: TransportListEn,
  RentList: RentList,
  RentListEn: RentListEn,
  ServiceList: ServiceList,
  ServiceListEn: ServiceListEn,
};

const images = {
  FittingsImages: FittingsImages,
  ActivitiesImages: ActivitiesImages,
  SightseeingImages: SightseeingImages,
  ServiceImages: ServiceImages,
  TransportImages: TransportImages,
  RentImages: RentImages,
};

function FacilityFactory({ item, state }) {
  return (
    <Grid container spacing={0} justifyContent="flex-start" style={{ paddingTop: "9px" }}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <Typography
          key={state.name + "_s"}
          align="left"
          style={{
            paddingTop: "10px",
            paddingRight: "16px",
            paddingLeft: "15px",
            maxWidth: "%15",
          }}
          color="primary"
        >
          {state.name + ":"}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={9} style={{ display: "flex" }}>
        <div>
          {Object.entries(item[state.elist]).map((option: any, v: any) =>
            item[state.elist][lists[state.en][v]] === true ? (
              <Tooltip
                title={lists[state.list][v]}
                arrow
                TransitionComponent={Zoom}
                key={option}
                style={{
                  paddingRight: "0px",
                  paddingLeft: "4px",
                  paddingTop: "0px",
                  paddingBottom: "4px",
                }}
              >
                <Checkbox
                  // checked={item.properties[lists[state.en][v]]}
                  checked={true}
                  id={option + v}
                  color="primary"
                  disableRipple={true}
                  size="small"
                  checkedIcon={
                    <img
                      src={images[state.images][`${item.bookable ? "Green/" : "Blue/"}` + v + ".png"]}
                      height={36}
                      width={36}
                      alt={[state.list][v]}
                    />
                  }
                />
              </Tooltip>
            ) : (
              ""
            )
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default FacilityFactory;
