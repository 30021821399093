import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { IDeleteBookmarkParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useDeleteBookmark = (options?: UseMutationOptions<any, any, IDeleteBookmarkParams>) => {
  const { bookmarksApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: IDeleteBookmarkParams) =>
      bookmarksApiService.createCancellableRequest((cancelToken) =>
        bookmarksApiService.deleteBookmark(payload, cancelToken)
      ),
    [bookmarksApiService]
  );

  const mutation = useMutation<any, any, IDeleteBookmarkParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.BOOKMARK_ALL);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
