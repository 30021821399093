import { Card, List, ListSubheader, ListItem, Grid, Typography, Checkbox, Tooltip } from "@material-ui/core";
import { RabattList, RabattNames } from "constants/campCardItems";
import { useTranslation } from "react-i18next";

function PromotionInfoCard({ item }) {
  const { t } = useTranslation();
  return (
    <Card style={{ marginBottom: 18 }}>
      <List dense={true} subheader={<ListSubheader color="primary">{t("discount_cards")}</ListSubheader>}>
        <ListItem key={"promotion"}>
          <Grid container>
            {Object.keys(item.promotion).map(
              (it: any, index: number) =>
                item.promotion[it] !== false && (
                  <Grid item key={it}>
                    <Tooltip
                      title={RabattNames[it]}
                      arrow
                      key={it + "_t"}
                      style={{
                        paddingRight: "3px",
                        paddingLeft: "2px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                    >
                      <Checkbox
                        checked={true}
                        // onClick={(e) => console.log(FittingsImages)}
                        id={"test"}
                        disableRipple={true}
                        size="small"
                        checkedIcon={
                          <img
                            src={`/assets/images/Rabatt/Green/${index}.png`}
                            height={36}
                            width={56}
                            style={{ backgroundColor: "#fff" }}
                            alt="rabatt"
                          />
                        }
                      />
                    </Tooltip>
                  </Grid>
                )
            )}
          </Grid>
        </ListItem>
      </List>
    </Card>
  );
}

export default PromotionInfoCard;
