import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { IGetVerifyCodeParams } from "../domain";
import { QueryKeys } from "../query-keys";

export const useVerifyCode = <TData = any, TError = any>(
  params: IGetVerifyCodeParams,
  queryOptions: UseQueryProps<TData, TError> = {}
) => {
  const { userApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      userApiService.createCancellableRequest((cancelToken) =>
        userApiService.getVerifyCode(params, cancelToken)
      ),
    [userApiService, params]
  );

  return useQuery(QueryKeys.verifyCode(), queryFn, queryOptions);
};
