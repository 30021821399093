import { createTheme } from "@material-ui/core/styles";
// import RobotoMedium from "../fonts/Roboto/Roboto-Medium.woff2";
// import RobotoBase from "../fonts/Roboto/Roboto.woff2";

export const themeUnbookable = createTheme({
  palette: {
    primary: {
      main: "#0069B4",
      light: "#00A6E1",
      dark: "#0069B4",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f9f9f9",
      light: "#ffffff",
      dark: "#c6c6c6",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
    success: {
      main: "#82e226",
    },
    warning: {
      main: "#ff9800",
    },
    error: {
      main: "#f44336",
    },
  },
  // typography: {
  //   fontFamily: "Roboto",
  // },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: `
  //     @font-face {
  //       font-family: 'Roboto';
  //       font-style: normal;
  //       font-display: swap;
  //       font-weight: 400;
  //       src: local('Roboto'), local('Roboto'), url(${RobotoMedium}) format('woff2'), url(${RobotoBase}) format('woff2');
  //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  //     }
  //   `,
  //   },
  // },
});

export const themeBookable = createTheme({
  palette: {
    primary: {
      light: "#C8E6C9",
      // main: "#7ED321",
      // main: "#BCCF00",
      main: "#00713C",
      dark: "#00913C",
      // dark: "#0069B4",
      // dark: "#BCCF00",
      // dark: "#82e226",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f9f9f9",
      light: "#ffffff",
      dark: "#c6c6c6",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
    },
    success: {
      main: "#82e226",
    },
    warning: {
      main: "#ff9800",
    },
    error: {
      main: "#f44336",
    },
  },
  // typography: {
  //   fontFamily: "Roboto",
  // },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: `
  //     @font-face {
  //       font-family: 'Roboto';
  //       font-style: normal;
  //       font-display: swap;
  //       font-weight: 400;
  //       src: local('Roboto'), local('Roboto'), url(${RobotoMedium}) format('woff2'), url(${RobotoBase}) format('woff2');
  //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  //     }
  //   `,
  //   },
  // },
});
