import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { IGetToken } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useUserArealEditToken = (options?: UseMutationOptions<any, any, IGetToken>) => {
  const { userApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: IGetToken) =>
      userApiService.createCancellableRequest((cancelToken) =>
        userApiService.getUserArealEditToken(payload, cancelToken)
      ),
    [userApiService]
  );

  const mutation = useMutation<any, any, IGetToken>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.USER_AREAL_EDIT_TOKEN);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
