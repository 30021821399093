import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1200,
      top: "64px",
    },
    rootx: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    paperAnchorTop: {
      top: "64px",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    appBarShift: {
      marginTop: 564,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    container: {
      marginTop: 24,
    },
    containerShift: {
      marginTop: 14,
      transition: theme.transitions.create(["height", "margin"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("height", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: theme.spacing(9) + 1,
      [theme.breakpoints.up("xs")]: {
        height: theme.spacing(15) + 1,
      },
    },
    drawerOpen: {
      height: 625,
      transition: theme.transitions.create("height", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      top: "52px",
      whiteSpace: "nowrap",
    },
    appBarLogo: {
      height: 36,
    },
    facilitiesList: {
      marginTop: 6,
    },
    facilities: {
      marginRight: 10,
      marginBottom: 2,
    },
    imageGallery: {
      marginBottom: 18,
      ".image-gallery-left-nav:hover::before": {
        color: "pink",
      },
    },
    dateCard: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dateRangePicker: {
      "& .DateInput": {
        width: "calc(50% - 24px)",
      },
      "& .DateRangePicker_picker": {
        zIndex: 4,
      },
    },
    closestBakery: {
      width: "100%",
      height: 200,
      marginBottom: 24,
      display: "flex",
    },
    closestBakeryDetails: {
      display: "flex",
      flexDirection: "column",
    },
    closestBakeryContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    closestBakeryCover: {
      minWidth: 300,
      objectFit: "cover",
    },
    closestBakeryButtons: {
      marginBottom: 24,
      display: "flex",
      justifyContent: "space-evenly",
    },
    cardActions: {
      padding: 16,
    },
    gallery: {
      ".image-gallery-left-nav:hover::before": {
        color: "pink",
      },
    },
    iconButton: {
      width: 24,
      height: 24,
      padding: 8,
    },
    icon: {
      width: 24,
      height: 24,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 500,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
    },
    thirdHeading: {
      fontSize: theme.typography.pxToRem(11),
      color: theme.palette.text.secondary,
    },
    date: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    helperDiv: {
      padding: theme.spacing(1, 2),
    },
    helperLeft: {
      padding: theme.spacing(1, 2),
    },
    helperRight: {
      padding: theme.spacing(1, 2),
    },
    upDown: {
      borderTop: `2px solid ${theme.palette.divider}`,
      borderBottom: `2px solid ${theme.palette.divider}`,
      padding: "4px",
      display: "flex",
      flexBasis: "100%",
      justifyContent: "space-around",
    },
    details: {
      alignItems: "center",
    },
    title: {
      flexDirection: "column",
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginLeft: 3,
    },
    mail: {
      width: theme.spacing(4),
      height: theme.spacing(3),
      marginLeft: 3,
    },
    loc: {
      width: "25px",
      height: "32px",
      marginLeft: 7,
    },
    inline: {
      display: "inline",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

export default useStyles;
