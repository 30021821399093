import React from "react";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField,
  CardActions,
  Button,
  Switch,
  createStyles,
  makeStyles,
  withStyles,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";
import { useVehicle } from "context";
import { useUpdateVehicle } from "rest";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const RedSwitch = withStyles({
  switchBase: {
    color: "#FF0000",
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles(() =>
  createStyles({
    cardActions: {
      padding: 16,
    },
  })
);

export const CarVehicleForm = ({ savedVehicle }) => {
  const classes = useStyles();

  const {
    vehicles,
    setVehicles,
    setCheckedC,
    setSavedVehicle,
    setCard1,
    setCard2,
    setCard3,
    setCarVehicleForm,
    setChecked,
    setCheckedE,
    setTrigger,
    trigger,
    prev,
  } = useVehicle();

  const { mutate: updateVehicle } = useUpdateVehicle({
    onSuccess: () => {
      setSavedVehicle(false);
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const saveCarVehicle = () => {
    // let first = Object.keys(vehicles).filter((key) => vehicles[key] === "");
    let second = Object.keys(vehicles.car).filter((key) => vehicles.car[key] === "");
    if (second.length > 0) {
      setCheckedC(true);
      setSavedVehicle(true);
      setTimeout(() => {
        setCheckedC(false);
      }, 5000);
    } else {
      let updateVehicleObject = {
        lengthInM: vehicles.lengthInM.replace(",", "."),
        numberplate: vehicles.numberplate.toString(),
        pendantLengthInM: vehicles.pendant.lengthInM.replace(",", "."),
        pendantNumberplate: vehicles.pendant.numberplate.toString(),
        isPendantActive: vehicles.pendant.isPendantActive,
        carLengthInM: vehicles.car.lengthInM.replace(",", "."),
        carNumberplate: vehicles.car.numberplate.toString(),
        isCarActive: vehicles.car.isCarActive,
        isVehicleActive: vehicles.isVehicleActive,
        hasMoto: vehicles.hasMoto,
        hasBike: vehicles.hasBike,
        hasTent: vehicles.hasTent,
      };
      setCard3(1);
      updateVehicle(updateVehicleObject);
    }
  };

  const handleAddCarLength = () => {
    if (parseInt(vehicles.car.lengthInM || 0) < 20) {
      setVehicles((vehicles) => ({
        ...vehicles,
        car: {
          ...vehicles.car,
          lengthInM: (parseInt(vehicles.car.lengthInM || 0) + 1).toString(),
        },
      }));
    }
  };

  const handleSubCarLength = () => {
    if (parseInt(vehicles.car.lengthInM) > 1) {
      setVehicles((vehicles) => ({
        ...vehicles,
        car: {
          ...vehicles.car,
          lengthInM: (parseInt(vehicles.car.lengthInM) - 1).toString(),
        },
      }));
    }
  };

  const handleChangeCarVehicle = (e: any) => {
    setVehicles((vehicles) => ({
      ...vehicles,
      car: {
        ...vehicles.car,
        [e.target.id]: e.target.value,
      },
    }));
  };

  const handleCarActive = (e) => {
    if (e.target.checked) {
      vehicles.car[e.target.id] = true;
    } else {
      vehicles.car[e.target.id] = false;
    }
    setTrigger(!trigger);
  };

  const closeCarVehicle = () => {
    setCarVehicleForm(false);
    setCard3(1);
    setVehicles(prev);
  };

  return (
    <Card style={{ marginTop: 18 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"numberplate"}
                defaultValue={vehicles.car.numberplate}
                onChange={handleChangeCarVehicle}
                variant="outlined"
                label="Kennzeichen"
                required
                helperText={vehicles.car.numberplate === "" && savedVehicle === true ? "Pflichtfeld" : ""}
                error={vehicles.car.numberplate === "" && savedVehicle === true ? true : false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={8} md={10}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id={"lengthInM"}
                    value={vehicles.car.lengthInM}
                    onChange={handleChangeCarVehicle}
                    variant="outlined"
                    label="Länge in Meter"
                    helperText={vehicles.car.lengthInM === "" && savedVehicle === true ? "Pflichtfeld" : ""}
                    error={vehicles.car.lengthInM === "" && savedVehicle === true ? true : false}
                    required
                    onInput={(e: any) => {
                      e.target.value =
                        e.target.value.includes(".") || e.target.value.includes("-")
                          ? ""
                          : e.target.value === "0"
                          ? ""
                          : e.target.value.includes(",")
                          ? e.target.value.indexOf(",") === 2
                            ? e.target.value.toString().slice(0, 5)
                            : e.target.value.toString().slice(0, 4)
                          : Number(parseFloat(e.target.value).toString().slice(0, 2)) < 20
                          ? e.target.value.toString().slice(0, 2)
                          : e.target.value.toString().slice(0, 1);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2} style={{ paddingTop: "7px" }}>
                <ButtonGroup
                  size="small"
                  variant="outlined"
                  disableElevation
                  orientation="vertical"
                  color="primary"
                >
                  <Button style={{ height: "28px" }} size="small" onClick={handleAddCarLength}>
                    <ArrowUpwardIcon />
                  </Button>
                  <Button style={{ height: "28px" }} size="small" onClick={handleSubCarLength}>
                    <ArrowDownwardIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Grid item>
          <Button onClick={saveCarVehicle} variant="contained" color="primary" disableElevation>
            Fahrzeug Speichern
          </Button>
        </Grid>

        {/* <Grid item>
          {!vehicles.car.isCarActive ? "Ohne " : "Mit"}
          <RedSwitch
            checked={vehicles.car.isCarActive}
            onChange={handleCarActive}
            id={"isCarActive"}
            name="checkedB"
            color="primary"
          />
        </Grid> */}
        <Grid item style={{ marginLeft: "auto" }}>
          <Button onClick={closeCarVehicle} variant="contained" disableElevation>
            Abbrechen
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};
