import React from "react";
import { Link } from "react-router-dom";
import { Card, CardActionArea, CardContent, List, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function MapCard({ item }) {
  const { t } = useTranslation();

  return (
    <Card style={{ marginBottom: 18 }} elevation={0}>
      <CardActionArea component={Link} to={{ pathname: `/parkingAreal/${item.parkingArealId}` }}>
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <List dense={true}>
            <ListItemText primary={t("pitches")} secondary={item.parkingArealName} />
            <ListItemText primary={t("selected_parking_space")} secondary={item.parkingSpotName} />
            <ListItemText
              primary={t("posting_date")}
              secondary={`${new Date(item.startDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })} - ${new Date(item.endDate).toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}`}
            />
            <ListItemText
              primary={t("label_address")}
              secondary={
                item.address &&
                `${item.address.streetAndHouseNumber} ${item.address.areaCode} / ${item.address.area}`
              }
            />
          </List>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
