import React from "react";
import { Grid } from "@material-ui/core";

export enum SpotType {
  S,
  M,
  L,
  XL,
  XXL,
}

export enum BookingState {
  BOOKED,
  FREE,
  SELECTED,
}

type Props = {
  type: SpotType;
  bookingState: BookingState;
  label: number;
};

export class ParkingSpotComponentWeb extends React.Component<Props> {
  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    let borderColor = this.props.bookingState === 0 ? "#b3b3b3" : "#79837d";
    let fontColor = this.props.bookingState === 0 ? "#808080" : "#252525";
    let backgroundColor;
    // let circleColor;

    switch (this.props.type) {
      case 2:
        backgroundColor = "#fff8ca";
        break;
      case 0:
        backgroundColor = "#dff2fd";
        break;
      case 3:
        backgroundColor = "#ffeaad";
        break;
      case 1:
        backgroundColor = "#bccdeb";
        break;
      case 4:
        backgroundColor = "#fdeef6";
        break;
    }

    switch (this.props.bookingState) {
      case 0:
        backgroundColor = "#ebebeb";
        // circleColor = "#ffffff";
        borderColor = "#ffffff";
        break;
      case 2:
        // circleColor = "#67b42e";
        backgroundColor = "#67b42e";
        break;
    }

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        style={{
          marginTop: "5px",
          marginBottom: "5px",
          marginLeft: "5px",
          marginRight: "5px",
          width: "100px",
          padding: "5px",
          borderWidth: "0.5px",
          borderStyle: "solid",
          borderColor: borderColor,
          background: backgroundColor,
        }}
      >
          <div
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "right",
            color: fontColor,
          }}
        >
          {this.props.label}
        </div>
      </Grid>
    );
  }
}
