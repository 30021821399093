export function importAll(r: any) {
  let images: any = {};
  r.keys().forEach((item: any, index: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

export const ActivitiesImages: any = importAll(
  require.context("../../public/assets/images/Activities", true, /\.(png|jpe?g|svg)$/)
);

export const FittingsImages: any = importAll(
  require.context("../../public/assets/images/Fittings", true, /\.(png|jpe?g|svg)$/)
);

export const RentImages: any = importAll(
  require.context("../../public/assets/images/Rent", true, /\.(png|jpe?g|svg)$/)
);

export const ServiceImages: any = importAll(
  require.context("../../public/assets/images/Service", true, /\.(png|jpe?g|svg)$/)
);

export const SightseeingImages: any = importAll(
  require.context("../../public/assets/images/Sightseeing", true, /\.(png|jpe?g|svg)$/)
);

export const TransportImages: any = importAll(
  require.context("../../public/assets/images/Transport", true, /\.(png|jpe?g|svg)$/)
);

export const RabattImages: any = importAll(
  require.context("../../public/assets/images/Rabatt", true, /\.(png|jpe?g|svg)$/)
);
