import type { CancelToken } from "axios";

import type { IUpdateUserFiltersParams } from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  userFilter: "/filter",
};

export class FilterApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private/user`) {
    super(baseURL);
  }

  async getUserFilters(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userFilter, { cancelToken });

    return data;
  }

  async updateUserFilters(params: IUpdateUserFiltersParams, cancelToken?: CancelToken) {
    const { data } = await this.http.put(URLS.userFilter, params, {
      cancelToken,
    });

    return data;
  }
}
