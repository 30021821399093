import type { CancelToken } from "axios";

import type {
  IUploadParkingArealImageParams,
  IUploadUserImageParams,
  IUploadTravellerImageParams,
  IUploadInfoImageParams,
  IUploadAdminImageParams,
} from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  upload: "/upload",
};

export class UploadApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private`) {
    super(baseURL);
  }

  async uploadParkingArealImage(params: IUploadParkingArealImageParams, cancelToken?: CancelToken) {
    const payload = new FormData();
    payload.set("file", params.file);
    payload.set("association", "parkingAreal");
    payload.set("id", params.parkingArealId);
    payload.set("desc", params.desc);

    const { data } = await this.http.post(URLS.upload, payload, {
      cancelToken,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  async uploadUserImage(params: IUploadUserImageParams, cancelToken?: CancelToken) {
    const payload = new FormData();
    console.log(params);
    payload.set("file", params.file);
    payload.set("association", "user");

    const { data } = await this.http.post(URLS.upload, payload, {
      cancelToken,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  async uploadTravellerImage(params: IUploadTravellerImageParams, cancelToken?: CancelToken) {
    const payload = new FormData();
    payload.set("file", params.file);
    payload.set("association", "travel");
    payload.set("id", params.travellerId);

    const { data } = await this.http.post(URLS.upload, payload, {
      cancelToken,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  async uploadInfoImage(params: IUploadInfoImageParams, cancelToken?: CancelToken) {
    const payload = new FormData();
    payload.set("file", params.file);
    payload.set("association", "info");

    const { data } = await this.http.post(URLS.upload, payload, {
      cancelToken,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  async uploadAdminImage(params: IUploadAdminImageParams, cancelToken?: CancelToken) {
    const payload = new FormData();
    payload.set("file", params.file);
    payload.set("association", "admin");

    const { data } = await this.http.post(URLS.upload, payload, {
      cancelToken,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }
}
