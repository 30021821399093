import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, FormControlLabel, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Room as RoomIcon } from "@material-ui/icons";
import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBookings } from "context";

const useStyles = makeStyles({
  booking: {},
  no: {
    backgroundColor: "#f9c5af",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
  orangeButton: {
    // color: "white",
    backgroundColor: "#FFA500", // Orange color
    "&:hover": {
      backgroundColor: "#FF8C00", // Darker orange on hover
    },
    color: "white", // Automatically set text color for contrast
  },
});

export const BasketItem = ({ item }) => {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const { t } = useTranslation();
  const { basket, setBasket } = useBookings();
  const deleteDialog = (e) => {
    e.stopPropagation();
    setOpenDelete(true);
  };

  const handleDelete = () => {
    setBasket((st) => st?.filter((i) => i.id !== item.id));
    setOpenDelete(false);
  };

  return (
    <>
      <TableRow>
        {/* <TableCell component="th" scope="row">
        {new Date(item.createdAt).toLocaleDateString("de-DE")}
      </TableCell> */}
        <TableCell>
          <img
            alt={item.name}
            style={{
              // height: 600,
              // maxWidth: 600,
              objectFit: "cover",
              background: "#c9c9c9",
              // width: "100%",
              height: 60,
              width: 60,
              borderRadius: 8,
            }}
            src={item.photo}
          />
        </TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>{`${item.brut.toFixed(2).replace(".", ",")} €`}</TableCell>
        <TableCell>{item.count}</TableCell>
        <TableCell>{`${item.gross.toFixed(2).replace(".", ",")} €`}</TableCell>
        <TableCell>
          <IconButton
            aria-label="delete dialog"
            size="small"
            onClick={deleteDialog}
            style={{ color: "#00713C" }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>

      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle id="form-dialog-title">Löschen</DialogTitle>

        <DialogContent style={{ minWidth: "40vw" }}>
          <Typography variant="subtitle1">Ihre Bestellung wird dauerhaft gelöscht.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)} color="primary" className={classes.orangeButton}>
            {t("label_cancel")}
          </Button>
          <Button
            variant="contained"
            disableElevation
            style={{ backgroundColor: "red", color: "white" }}
            onClick={handleDelete}
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
