import React from "react";
import "./App.css";
import "./translations/i18n";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { themeBookable } from "constants/theming";
import { ParkingAreal } from "./pages/public/ParkingAreaDetail";
// import { Areal } from "./pages/public/AreaDetail";
import { AuthProvider } from "./context/Auth";
import { CookieProvider } from "./context/Cookie";
import { AuthGuard } from "./components/PrivateRoute";
import { UserPages } from "./components/user";

// import { VerifyEmail } from "./pages/authenticated";
import {
  ParkingAreaDetailProvider,
  ProfileProvider,
  AreaDetailProvider,
  TravellersProvider,
  VehicleProvider,
  BookmarksProvider,
  BookingsProvider,
  UserDataProvider,
} from "context";
import { ApiServiceProvider } from "rest";
import { HomeProvider } from "context/Home";
import { GoogleMapsProvider } from "context/GoogleMaps";

import { ReactQueryDevtools } from "react-query/devtools";
import loadable from "@loadable/component";

const BookingDetail = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.BookingDetail,
});

const Bookings = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.Bookings,
});
const BookingsPast = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.BookingsPast,
});
const Bookmarks = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.Bookmarks,
});
const Profile = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.Profile,
});
const Reviews = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.Reviews,
});
const Vehicles = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.Vehicles,
});
const VerifyEmail = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.VerifyEmail,
});
const Travellers = loadable(() => import("pages/authenticated"), {
  resolveComponent: (components) => components.Travellers,
});
const Areal = loadable(() => import("pages/public/AreaDetail"), {
  resolveComponent: (components) => components.Areal,
});
const Home = loadable(() => import("pages/public/Home"));
const ApplyNewAreal = loadable(() => import("pages/public/ApplyNewAreal"), {
  resolveComponent: (components) => components.ApplyNewAreal,
});
const Impressum = loadable(() => import("pages/public/Impressum"), {
  resolveComponent: (components) => components.Impressum,
});
const Return = loadable(() => import("pages/public/Return"), {
  resolveComponent: (components) => components.Return,
});
const Help = loadable(() => import("pages/public/Help"), {
  resolveComponent: (components) => components.Help,
});
const ReservationSVG = loadable(() => import("pages/public/ReservationSVG"), {
  resolveComponent: (components) => components.ReservationSVG,
});
const Login = loadable(() => import("pages/public/Login"), {
  resolveComponent: (components) => components.Login,
});
const Forget = loadable(() => import("pages/public/Login/Forget"), {
  resolveComponent: (components) => components.Forget,
});
const SignUp = loadable(() => import("pages/public/Login/SignUp"), {
  resolveComponent: (components) => components.SignUp,
});
const NotFound = loadable(() => import("pages/public/404"));

const HomeWithProvider = () => (
  <CookieProvider>
    <GoogleMapsProvider>
      <HomeProvider>
        <Home />
      </HomeProvider>
    </GoogleMapsProvider>
  </CookieProvider>
);

const ParkingArealWithProvider = () => (
  <CookieProvider>
    <GoogleMapsProvider>
      <ParkingAreaDetailProvider>
        <ParkingAreal />
      </ParkingAreaDetailProvider>
    </GoogleMapsProvider>
  </CookieProvider>
);

const ArealWithProvider = () => (
  <CookieProvider>
    <GoogleMapsProvider>
      <AreaDetailProvider>
        {/* <MuiThemeProvider theme={themeUnbookable}> */}
        <UserDataProvider>
          <Areal />
        </UserDataProvider>
        {/* </MuiThemeProvider> */}
      </AreaDetailProvider>
    </GoogleMapsProvider>
  </CookieProvider>
);

const ProfileWithProvider = () => (
  <ProfileProvider>
    <GoogleMapsProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <UserPages />
      {/* </MuiThemeProvider> */}
    </GoogleMapsProvider>
  </ProfileProvider>
);

const ImpressumWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Impressum />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const ReturnWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Return />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const ApplyNewArealWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <ApplyNewAreal />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const LoginWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Login />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const ForgetWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <Forget />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const SignUpWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <SignUp />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const VerifyEmailWithProvider = () => (
  <CookieProvider>
    <ProfileProvider>
      {/* <MuiThemeProvider theme={themeBookable}> */}
      <VerifyEmail />
      {/* </MuiThemeProvider> */}
    </ProfileProvider>
  </CookieProvider>
);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApiServiceProvider>
        <BrowserRouter>
          <MuiThemeProvider theme={themeBookable}>
            <Routes>
              <Route path={"/help"} element={<Help />} />
              <Route path={"/"} element={<HomeWithProvider />} />
              <Route path={"/impressum"} element={<ImpressumWithProvider />} />
              {/* <Route path={"/fair"} element={Fair} /> */}
              <Route path={"/return"} element={<ReturnWithProvider />} />
              <Route path={"/booking/svg"} element={<ReservationSVG />} />
              <Route path={"/apply-new-areal"} element={<ApplyNewArealWithProvider />} />
              <Route path={"/login"} element={<LoginWithProvider />} />
              <Route path={"/login/forget"} element={<ForgetWithProvider />} />
              <Route path={"/login/signup"} element={<SignUpWithProvider />} />
              <Route path={"/parkingAreal/:id"} element={<ParkingArealWithProvider />} />
              <Route path={"/areal/:id"} element={<ArealWithProvider />} />

              <Route element={<AuthGuard />}>
                <Route path={"/user/verify"} element={<VerifyEmailWithProvider />} />
                <Route element={<ProfileWithProvider />}>
                  <Route path={"/user/profile"} element={<Profile />} />
                  <Route
                    path={"/user/travellers"}
                    element={
                      <TravellersProvider>
                        <Travellers />
                      </TravellersProvider>
                    }
                  />
                  <Route
                    path={"/user/vehicles"}
                    element={
                      <VehicleProvider>
                        <Vehicles vehicle={null} />
                      </VehicleProvider>
                    }
                  />
                  <Route
                    path={"/user/bookmarks"}
                    element={
                      <BookmarksProvider>
                        <Bookmarks />
                      </BookmarksProvider>
                    }
                  />
                  <Route
                    path={"/user/bookings"}
                    element={
                      <BookingsProvider>
                        <Bookings />
                      </BookingsProvider>
                    }
                  />
                  <Route
                    path={"/user/booking-detail/:id"}
                    element={
                      <BookingsProvider>
                        <BookingDetail />
                      </BookingsProvider>
                    }
                  />
                  <Route
                    path={"/user/bookings-past"}
                    element={
                      <BookingsProvider>
                        <BookingsPast />
                      </BookingsProvider>
                    }
                  />
                  <Route path={"/user/reviews"} element={<Reviews />} />

                  {/* <Route path={"/user/guthaben"} element={<ProfileWithProvider />} />
                  <Route path={"/user/stornieren"} element={<ProfileWithProvider />} />
                  <Route path={"/user/rechnungen"} element={<ProfileWithProvider />} /> */}
                </Route>
              </Route>

              <Route path={"*"} element={<NotFound />} />
            </Routes>
          </MuiThemeProvider>
        </BrowserRouter>
        <ReactQueryDevtools />
      </ApiServiceProvider>
    </AuthProvider>
  );
};

export default App;
