import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  CardActions,
  Chip,
  IconButton,
  useMediaQuery,
  Checkbox,
  Theme,
} from "@material-ui/core";
import { SyncAlt as SyncAltIcon, Delete as DeleteIcon, Room as RoomIcon } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation, withTranslation } from "react-i18next";

import {
  ActivitiesList,
  ActivitiesListEn,
  FittingsList,
  FittingsListEn,
  SightseeingList,
  SightseeingListEn,
  TransportList,
  TransportListEn,
} from "constants/campCardItems";
import { FittingsImages, ActivitiesImages, SightseeingImages, TransportImages } from "helpers";
import { campCardServices, campCardActivities } from "constants/campCardItems";

import { useBookmarks } from "context/Bookmarks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      minWidth: 410,
      height: 245,
      [theme.breakpoints.down("sm")]: {
        height: 120,
      },
    },
    facilitiesList: {
      marginTop: 8,
      height: 110,
    },
    facilities: {
      marginRight: 4,
      marginBottom: 2,
    },
    chip: {
      margin: "4px 5px",
    },
    card: {
      width: "100%",
      marginBottom: 18,
      display: "flex",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    details: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    campCardActions: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const CampCardImp = ({ item, index, groups }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:680px)");
  const { t } = useTranslation();

  const {
    setChangeFolder,
    setChangeFolderForm,
    setItemId,
    setDialogOpen,
    setSelectedPlace,
    setInfoOpen,
    setZoom,
    setCenterlat,
    setCenterlng,
  } = useBookmarks();

  const openChangeFolderForm = (bookmarkId: string) => {
    setChangeFolder((prev) => ({ ...prev, bookmarkId }));
    setChangeFolderForm(true);
  };

  const openMapAndCard = (event: any, item: any) => {
    setSelectedPlace(item);
    setInfoOpen((previousValue) => !previousValue);
    setZoom(10);
    setCenterlat(item.location.lat);
    setCenterlng(item.location.lon);

    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector("#bookingpast");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardActionArea {...{ component: Link, to: `/areal/${item.id}`, state: item }}>
          <CardMedia
            className={classes.media}
            image={
              item.image != ""
                ? item.image
                : `/assets/images/placeholders/${item.location.lat.toString().slice(-1)}.jpg`
            }
            title={item.name}
          />
        </CardActionArea>
        <div className={classes.details}>
          <CardActionArea {...{ component: Link, to: `/parkingAreal/${item.id}`, state: item }}>
            <CardContent style={{ paddingLeft: 16, paddingTop: 5, paddingBottom: 5 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={!item.bookable ? { color: "#0069B4", fontWeight: 500 } : { fontWeight: 500 }}
              >
                {item.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {t("city")}: {item.area}
                {item.amountOfSpots ? ` - ${t("number_of_parking_lots")}: ${item.amountOfSpots}` : ""}
              </Typography>
              <div className={classes.facilitiesList}>
                {Object.entries(item.fittingsList).map((option: any, v: any) =>
                  item.fittingsList[FittingsListEn[v]] === true ? (
                    <Tooltip
                      title={FittingsList[v]}
                      arrow
                      key={item.name + option}
                      style={{
                        paddingRight: "0px",
                        paddingLeft: "2px",
                        paddingTop: "2px",
                        paddingBottom: "0px",
                      }}
                    >
                      <Checkbox
                        checked={item.fittingsList[FittingsListEn[v]]}
                        // onClick={(e) => console.log(FittingsImages)}
                        id={option + v}
                        color="primary"
                        disableRipple={true}
                        size="small"
                        checkedIcon={
                          <img
                            src={
                              !item.bookable
                                ? FittingsImages["Blue/" + v + ".png"]
                                : FittingsImages["Green/" + v + ".png"]
                            }
                            height={24}
                            width={24}
                            alt="activities"
                          />
                        }
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )
                )}

                <br />

                {Object.entries(item.activitiesList).map((option: any, v: any) =>
                  item.activitiesList[ActivitiesListEn[v]] === true ? (
                    <Tooltip
                      title={ActivitiesList[v]}
                      arrow
                      key={item.name + option}
                      style={{
                        paddingRight: "0px",
                        paddingLeft: "2px",
                        paddingTop: "6px",
                        paddingBottom: "0px",
                      }}
                    >
                      <Checkbox
                        checked={item.activitiesList[ActivitiesListEn[v]]}
                        id={option + v}
                        color="primary"
                        disableRipple={true}
                        size="small"
                        checkedIcon={
                          <img
                            src={
                              !item.bookable
                                ? ActivitiesImages["Blue/" + v + ".png"]
                                : ActivitiesImages["Green/" + v + ".png"]
                            }
                            height={24}
                            width={24}
                            alt="activities"
                          />
                        }
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )
                )}

                <br />
                {Object.entries(item.sightseeingList).map((option: any, v: any) =>
                  item.sightseeingList[SightseeingListEn[v]] === true ? (
                    <Tooltip
                      title={SightseeingList[v]}
                      arrow
                      key={item.name + option}
                      style={{
                        paddingRight: "0px",
                        paddingLeft: "2px",
                        paddingTop: "6px",
                        paddingBottom: "0px",
                      }}
                    >
                      <Checkbox
                        checked={item.sightseeingList[SightseeingListEn[v]]}
                        id={option + v}
                        color="primary"
                        disableRipple={true}
                        size="small"
                        checkedIcon={
                          <img
                            src={
                              !item.bookable
                                ? SightseeingImages["Blue/" + v + ".png"]
                                : SightseeingImages["Green/" + v + ".png"]
                            }
                            height={24}
                            width={24}
                            alt="activities"
                          />
                        }
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )
                )}

                <br />
                {Object.entries(item.transportList).map((option: any, v: any) =>
                  item.transportList[TransportListEn[v]] === true ? (
                    <Tooltip
                      title={TransportList[v]}
                      arrow
                      key={item.name + option}
                      style={{
                        paddingRight: "0px",
                        paddingLeft: "2px",
                        paddingTop: "6px",
                        paddingBottom: "0px",
                      }}
                    >
                      <Checkbox
                        checked={item.transportList[TransportListEn[v]]}
                        id={option + v}
                        color="primary"
                        disableRipple={true}
                        size="small"
                        checkedIcon={
                          <img
                            src={
                              !item.bookable
                                ? TransportImages["Blue/" + v + ".png"]
                                : TransportImages["Green/" + v + ".png"]
                            }
                            height={24}
                            width={24}
                            alt="activities"
                          />
                        }
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )
                )}
              </div>
            </CardContent>
          </CardActionArea>
          <CardActions className={classes.campCardActions}>
            <Chip
              label={`${t("price").toUpperCase()}: ab ${item.mainPriceUI}`}
              variant="outlined"
              className={classes.chip}
            />
            <div>
              <Tooltip title={t("change_folder").toString()} placement="top" arrow>
                <IconButton
                  onClick={() => openChangeFolderForm(item.bookmarkId)}
                  size="small"
                  edge="end"
                  color="primary"
                >
                  <SyncAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("delete").toString()} placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setItemId(item.bookmarkId);
                    setDialogOpen(true);
                  }}
                  size="small"
                  edge="end"
                  color="primary"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {groups[index] === true && (
                <Tooltip title={t("on_the_map") + ""} placement="top" arrow>
                  <IconButton
                    size="small"
                    edge="end"
                    color="primary"
                    onClick={(event) => {
                      openMapAndCard(event, item);
                    }}
                  >
                    <RoomIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </CardActions>
        </div>
      </Card>
    </>
  );
};

export const CampCard = withTranslation()(CampCardImp);
