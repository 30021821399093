import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { IUpdateUserFiltersParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useUpdateUserFilters = (options?: UseMutationOptions<any, any, IUpdateUserFiltersParams>) => {
  const { filterApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: IUpdateUserFiltersParams) =>
      filterApiService.createCancellableRequest((cancelToken) =>
        filterApiService.updateUserFilters(payload, cancelToken)
      ),
    [filterApiService]
  );

  const mutation = useMutation<any, any, IUpdateUserFiltersParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.USER_FILTERS);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
