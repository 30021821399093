import { useCallback, useContext } from "react";
import { useQuery } from "react-query";
import type { IGetAllProductsBySupplierParams } from "../domain";
import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";

export const useGetProducts = <TData = any, TError = any>(
  params: IGetAllProductsBySupplierParams,
  queryOptions: UseQueryProps<TData, TError> = {}
) => {
  const { ordersApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      ordersApiService.createCancellableRequest((cancelToken) =>
        ordersApiService.getAllProductsBySupplierId(params, cancelToken)
      ),
    [ordersApiService, params]
  );

  return useQuery(QueryKeys.products(params), queryFn, queryOptions);
};
