import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { ICreateTravellerParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useCreateTravellers = (options?: UseMutationOptions<any, any, ICreateTravellerParams>) => {
  const { travellersApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: ICreateTravellerParams) =>
      travellersApiService.createCancellableRequest((cancelToken) =>
        travellersApiService.createTraveller(payload, cancelToken)
      ),
    [travellersApiService]
  );

  const mutation = useMutation<any, any, ICreateTravellerParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.USER_TRAVELLERS);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
