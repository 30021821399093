import React from "react";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField,
  CardActions,
  Button,
  createStyles,
  makeStyles,
  Theme,
  IconButton,
  ButtonGroup,
  withStyles,
  Switch,
} from "@material-ui/core";
import { useVehicle } from "context";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      padding: 16,
    },
  })
);
const RedSwitch = withStyles({
  switchBase: {
    color: "#FF0000",
  },
  checked: {},
  track: {},
})(Switch);

export const VehicleForm = ({ saveVehicles, firstName, lastName, fieldError, setFieldError }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { vehicles, setVehicles, savedVehicle, trigger, setTrigger, setVehicleForm, setCard1, prev } =
    useVehicle();

  const handleChangeVehicle = (e: any) => {
    setVehicles((vehicles) => ({
      ...vehicles,
      [e.target.id]: e.target.value,
    }));

    setFieldError(fieldError?.filter((item) => item.param !== e.target.id));
  };

  const handleAddVehicleLength = () => {
    if (parseInt(vehicles.lengthInM || 0) < 20) {
      setVehicles((vehicles) => ({
        ...vehicles,
        lengthInM: (parseInt(vehicles.lengthInM || 0) + 1).toString(),
      }));
    }
  };

  const handleSubVehicleLength = () => {
    if (parseInt(vehicles.lengthInM) > 1) {
      setVehicles((vehicles) => ({
        ...vehicles,
        lengthInM: (parseInt(vehicles.lengthInM) - 1).toString(),
      }));
    }
  };

  const closeVehicles = () => {
    setVehicleForm(false);
    setCard1(1);
    setVehicles(prev);
  };

  const handleVehicleActive = (e) => {
    if (e.target.checked) {
      vehicles[e.target.id] = true;
    } else {
      vehicles[e.target.id] = false;
    }
    setTrigger(!trigger);
  };

  return (
    <Card style={{ marginTop: 18 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"numberplate"}
                defaultValue={vehicles.numberplate}
                onChange={handleChangeVehicle}
                variant="outlined"
                label={t("label_numberPlate")}
                required
                helperText={vehicles.numberplate === "" && savedVehicle === true ? t("mandatory_field") : ""}
                error={
                  fieldError?.find((item) => item.param === "numberplate") ||
                  (vehicles.numberplate === "" && savedVehicle === true)
                    ? true
                    : false
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={8} md={10}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    id={"lengthInM"}
                    value={vehicles.lengthInM}
                    onChange={handleChangeVehicle}
                    variant="outlined"
                    label={t("length_in_meters")}
                    required
                    helperText={
                      vehicles.lengthInM === "" && savedVehicle === true ? t("mandatory_field") : ""
                    }
                    error={
                      fieldError?.find((item) => item.param === "lengthInM") ||
                      (vehicles.lengthInM === "" && savedVehicle === true)
                        ? true
                        : false
                    }
                    onInput={(e: any) => {
                      e.target.value =
                        e.target.value.includes(".") || e.target.value.includes("-")
                          ? ""
                          : e.target.value === "0"
                          ? ""
                          : e.target.value.includes(",")
                          ? e.target.value.indexOf(",") === 2
                            ? e.target.value.toString().slice(0, 5)
                            : e.target.value.toString().slice(0, 4)
                          : Number(parseFloat(e.target.value).toString().slice(0, 2)) < 20
                          ? e.target.value.toString().slice(0, 2)
                          : e.target.value.toString().slice(0, 1);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2} style={{ paddingTop: "7px" }}>
                <ButtonGroup
                  size="small"
                  variant="outlined"
                  disableElevation
                  orientation="vertical"
                  color="primary"
                >
                  <Button style={{ height: "28px" }} size="small" onClick={handleAddVehicleLength}>
                    <ArrowUpwardIcon />
                  </Button>
                  <Button style={{ height: "28px" }} size="small" onClick={handleSubVehicleLength}>
                    <ArrowDownwardIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id={"vehicleOwner"}
                defaultValue={
                  vehicles.vehicleOwner === "" ? firstName + " " + lastName : vehicles.vehicleOwner
                }
                helperText={vehicles.vehicleOwner === "" && savedVehicle === true ? t("mandatory_field") : ""}
                error={
                  fieldError?.find((item) => item.param === "vehicleOwner") ||
                  (vehicles.vehicleOwner === "" && savedVehicle === true)
                    ? true
                    : false
                }
                onChange={handleChangeVehicle}
                variant="outlined"
                label={t("vehicle_owner")}
                required
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Grid item>
          <Button onClick={saveVehicles} variant="contained" disableElevation color="primary">
            {t("save_vehicle")}
          </Button>
        </Grid>
        {/* <Grid item>
          {!vehicles.isVehicleActive ? "Ohne " : "Mit"}
          <RedSwitch
            checked={vehicles.isVehicleActive}
            onChange={handleVehicleActive}
            id={"isVehicleActive"}
            name="checkedB"
            color="primary"
          />
        </Grid> */}
        <Grid item style={{ marginLeft: "auto" }}>
          <Button onClick={closeVehicles} variant="contained" disableElevation>
            {t("label_cancel")}
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};
