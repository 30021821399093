import { Card, List, ListSubheader, ListItem, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function GeneralInfoCard({ item }) {
  const { t } = useTranslation();
  return (
    <Card style={{ marginBottom: 18 }}>
      <List dense={true} subheader={<ListSubheader color="primary">{t("general_info")}</ListSubheader>}>
        {item.generalInformation.amountOfSpots &&
          item.generalInformation.amountOfSpots !== "-" &&
          item.generalInformation.amountOfSpots !== "0" && (
            <ListItem alignItems="flex-start">
              <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                  <Typography color="primary" style={{ fontSize: "revert" }}>
                    {t("number_of_parking_lots") + ":"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    display={"inline"}
                    style={{
                      fontWeight: 400,
                      color: "#212125",
                      fontSize: "revert",
                    }}
                  >
                    {item.generalInformation.amountOfSpots}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          )}
        {item.generalInformation.max_length &&
          item.generalInformation.max_length !== "-" &&
          item.generalInformation.max_length !== "0" && (
            <ListItem alignItems="flex-start">
              <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                  <Typography color="primary" style={{ fontSize: "revert" }}>
                    {t("maximum_length") + ":"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    display={"inline"}
                    style={{
                      fontWeight: 400,
                      color: "#212125",
                      fontSize: "revert",
                    }}
                  >
                    {item.generalInformation.max_length + " METER"}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          )}
        {item.generalInformation.maximumStay && item.generalInformation.maximumStay !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography color="primary" style={{ fontSize: "revert" }}>
                  {t("maximum_stay")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.generalInformation.maximumStay === "0"
                    ? "Unbegrenzt"
                    : item.generalInformation.maximumStay +
                      (item.generalInformation.maximumStay === "1"
                        ? " Tag"
                        : item.generalInformation.maximumStay.length < 3
                        ? " Tage"
                        : "")}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.generalInformation.season && item.generalInformation.season !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography color="primary" style={{ fontSize: "revert" }}>
                  {t("label_season") + ":"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.generalInformation.season}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}

        {item.generalInformation.arrival && item.generalInformation.arrival !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography color="primary" style={{ fontSize: "revert" }}>
                  {t("label_startDate") + ":"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.generalInformation.arrival.charAt(0).toUpperCase() +
                    item.generalInformation.arrival.slice(1)}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.generalInformation.departure && item.generalInformation.departure !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography color="primary" style={{ fontSize: "revert" }}>
                  {t("label_endDate") + ":"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.generalInformation.departure.charAt(0).toUpperCase() +
                    item.generalInformation.departure.slice(1)}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.checkin && item.checkin !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography color="primary" style={{ fontSize: "revert" }}>
                  {t("checkin_ab") + ":"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.checkin.slice(11, 16)} Uhr
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {item.checkin && item.checkin !== "-" && (
          <ListItem alignItems="flex-start">
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Typography color="primary" style={{ fontSize: "revert" }}>
                  {t("checkout_bis") + ":"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  display={"inline"}
                  style={{
                    fontWeight: 400,
                    color: "#212125",
                    fontSize: "revert",
                  }}
                >
                  {item.checkout.slice(11, 16)} Uhr
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
      </List>
    </Card>
  );
}

export default GeneralInfoCard;
