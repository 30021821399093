import axios, { AxiosInstance, AxiosRequestConfig, CancelTokenSource } from "axios";
import { FirebaseAuth } from "services/firebase/Firebase";

export abstract class ApiBaseClient {
  protected readonly http: AxiosInstance;

  protected constructor(baseURL: string, defaultRequestConfig: AxiosRequestConfig = {}) {
    this.http = axios.create({
      ...defaultRequestConfig,
      baseURL,
    });

    this.http.interceptors.request.use(async (config) => {
      if (FirebaseAuth.currentUser) {
        const currentUser = await FirebaseAuth.currentUser.getIdTokenResult();
        config.headers["authorization"] = `Bearer ${currentUser.token}`;
      }

      return config;
    });
  }

  createCancellableRequest<TData>(
    requestCreator: (cancelToken: CancelTokenSource["token"]) => Promise<TData> | TData
  ): Promise<TData> | TData {
    const cancelTokenSource = axios.CancelToken.source();

    return requestCreator(cancelTokenSource.token);
  }
}
