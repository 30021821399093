import {
  Card,
  List,
  Link,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginLeft: 3,
    },
    mail: {
      width: theme.spacing(4),
      height: theme.spacing(3),
      marginLeft: 3,
    },
    loc: {
      width: "32px",
      height: "32px",
      marginLeft: 7,
      objectFit: "fill",
    },
  })
);

function ScoutInfoCard({ item }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card style={{ marginBottom: 18 }}>
      <List dense={true} subheader={<ListSubheader color="primary">{t("scout_info")}</ListSubheader>}>
        {"scout" in item && item.scout.id && (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                // variant="square"
                src={item.scout.image}
                className={classes.loc}
                imgProps={{ style: { objectFit: "fill" } }}
              ></Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.scout.name} />
          </ListItem>
        )}
        {"updatedAt" in item && (
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="subtitle2" color="primary">
                  {t("latest_update")} {` ${new Date(item.updatedAt).toLocaleDateString()}`}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </Card>
  );
}

export default ScoutInfoCard;
