import type { CancelToken } from "axios";

import type { ICreateVehicleParams, IUpdateVehicleParams } from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  userVehicle: "/vehicle",
};

export class VehicleApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private/user`) {
    super(baseURL);
  }

  async getVehicle(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userVehicle, { cancelToken });

    return data;
  }

  async createVehicle(params: ICreateVehicleParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.userVehicle, params, { cancelToken });

    return data;
  }

  async updateVehicle(params: IUpdateVehicleParams, cancelToken?: CancelToken) {
    const { data } = await this.http.put(URLS.userVehicle, params, {
      cancelToken,
    });

    return data;
  }

  async deleteVehicle(cancelToken?: CancelToken) {
    const { data } = await this.http.delete(URLS.userVehicle, {
      cancelToken,
    });

    return data;
  }
}
