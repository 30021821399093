import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { ICreateBookmarkFolderParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useCreateBookmarkFolder = (
  options?: UseMutationOptions<any, any, ICreateBookmarkFolderParams>
) => {
  const { bookmarksApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: ICreateBookmarkFolderParams) =>
      bookmarksApiService.createCancellableRequest((cancelToken) =>
        bookmarksApiService.createBookmarkFolder(payload, cancelToken)
      ),
    [bookmarksApiService]
  );

  const mutation = useMutation<any, any, ICreateBookmarkFolderParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.BOOKMARK_FOLDER_ALL);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
