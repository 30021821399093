import type { CancelToken } from "axios";

import type { ICreateCommentUserParams, IDeleteCommentParams } from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  comments: "/comments",
  userComments: "/user/comment",
};

export class CommentApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private`) {
    super(baseURL);
  }

  async getCommentOfUser(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userComments, { cancelToken });

    return data;
  }

  async createCommentUser(params: ICreateCommentUserParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.userComments, params, { cancelToken });

    return data;
  }

  async deleteComment(params: IDeleteCommentParams, cancelToken?: CancelToken) {
    const { data } = await this.http.delete(URLS.userComments + "/" + params.id, {
      cancelToken
    });

    return data;
  }
}
