import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { IDeleteUserBookingParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useDeleteBooking = (options?: UseMutationOptions<any, any, IDeleteUserBookingParams>) => {
  const { bookingApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: IDeleteUserBookingParams) =>
      bookingApiService.createCancellableRequest((cancelToken) =>
        bookingApiService.deleteUserBooking(payload, cancelToken)
      ),
    [bookingApiService]
  );

  const mutation = useMutation<any, any, IDeleteUserBookingParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.BOOKING_OF_USER);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
