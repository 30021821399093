import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

const EndPage = (params: any) => {
  const classes = useStyles();
  const handleClose = () => {
    params.situation();
  };
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <CheckCircleOutlinedIcon style={{ fontSize: 60, color: green[500] }} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" component="p">
            {t("label_booking_saved")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t("label_ok")}
          </Button>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </div>
  );
};

export default EndPage;
