import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  FormControlLabel,
  AccordionDetails,
  AccordionActions,
  Tooltip,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Switch,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { deleteBookmarkFolder } from "rest/AdminRestClient";
import { CampCard } from "components/user/bookmarks";
import { useBookmarks } from "context/Bookmarks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActions: {
      padding: 16,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

export const BookmarkCard = ({ item, index, getBookmarksInt }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { bookmarksAll, setBookmarksMap } = useBookmarks();

  const [groups, setGroups] = useState<any>([]);

  const active = (index, event: React.ChangeEvent<HTMLInputElement>) => {
    let neo = groups;
    neo[index] = event.target.checked;
    setGroups(neo);

    let arrayMap = [].concat(
      // eslint-disable-next-line array-callback-return
      ...bookmarksAll.map((item: any, key: any) => {
        if (neo[key] === true) {
          return item.bookmarks.map((item: any) => item.parkingAreal);
        }
      })
    );
    setBookmarksMap(arrayMap.filter((item) => item !== undefined));
  };

  const deleteFolder = (name: string) => {
    console.log(name);
    deleteBookmarkFolder(name)
      .then(() => {
        getBookmarksInt();
      })
      .catch((error) => console.error(error));
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Grid container spacing={0}>
          <Grid item xs={8} md={6}>
            <Typography className={classes.heading}>{item.name}</Typography>
          </Grid>
          <Grid item xs={2} md={5}></Grid>
          <Grid item xs={1} md={1}>
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Switch
                  onChange={(event) => active(index, event)}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  color="primary"
                />
              }
              label=""
            />
          </Grid>
        </Grid>
      </AccordionSummary>

      {item.bookmarks.length > 0 && (
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            {item.bookmarks.map((itemf: any, indexf: number) => (
              <CampCard key={itemf.parkingAreal.id} item={itemf.parkingAreal} index={index} groups={groups} />
            ))}
          </div>
        </AccordionDetails>
      )}
      <AccordionActions className={classes.cardActions}>
        {item.id !== "default" && (
          <Tooltip title={t("delete_folder") + ""} placement="top" arrow>
            <IconButton onClick={() => deleteFolder(item.id)} edge="end" color="primary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </AccordionActions>
    </Accordion>
  );
};
