import React from "react";
import { useEffect, useState } from "react";
import { FirebaseAuth } from "../services/firebase/Firebase";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

// @ts-ignore
export const AuthContext = React.createContext();

// @ts-ignore
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    FirebaseAuth.onAuthStateChanged((user) => {
      // @ts-ignore
      setCurrentUser(user);
      setPending(false);
    });
    // setPending(false);
  }, []);

  if (pending) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
