import React, { useState, createContext } from "react";

import { IProfileContext } from "interfaces/context";
import { IUserProfile } from "interfaces/user";

const initialUserProfileData = {
  address: {
    area: "",
    areaCode: "",
    countryCode: "",
    streetAndHouseNumber: "",
  },
  person: {
    birthDate: "",
    birthPlace: "",
    firstName: "",
    lastName: "",
    identityCardNo: "",
    nationality: "",
  },
  email: "",
  profileImage: {
    path: "",
    url: "",
  },
  id: "",
  phone: "",
  phoneCode: "",
  pet: "",
  status: "",
};

const ProfileContext = createContext<IProfileContext>({} as IProfileContext);

export const useProfile = () => {
  const context = React.useContext(ProfileContext);
  if (!context) {
    throw new Error(`useProfile must be used within a ProfileProvider`);
  }
  return context;
};

export const ProfileProvider = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const [getProfile, setProfile] = useState<IUserProfile>(initialUserProfileData);
  const [verifyCodeStatus, setVerifyCodeStatus] = useState(false);
  const [openImageBackdrop, setOpenImageBackdrop] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyData, setVerifyData] = useState({
    adultTravelerCount: "",
    bookingId: "",
    childTravelerCount: "",
    vehicle: false,
    vehicleMatch: false,
    vehicleNumber: "",
    error: true,
    message: "",
    petCount: "",
  });
  const [selectedIndex, setSelectedIndex] = useState(1);

  return (
    <ProfileContext.Provider
      value={{
        checked,
        setChecked,
        getProfile,
        setProfile,
        verifyCodeStatus,
        setVerifyCodeStatus,
        openImageBackdrop,
        setOpenImageBackdrop,
        fullScreen,
        setFullScreen,
        activeStep,
        setActiveStep,
        verifyCode,
        setVerifyCode,
        verifyData,
        setVerifyData,
        selectedIndex,
        setSelectedIndex,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
