import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions } from "react-query";

import { IUploadParkingArealImageParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";

export const useUploadParkingArealImage = (
  options?: UseMutationOptions<any, any, IUploadParkingArealImageParams>
) => {
  const { uploadApiService } = useContext(apiServiceContext);

  const mutationFn = useCallback(
    (payload: IUploadParkingArealImageParams) =>
      uploadApiService.createCancellableRequest((cancelToken) =>
        uploadApiService.uploadParkingArealImage(payload, cancelToken)
      ),
    [uploadApiService]
  );

  const mutation = useMutation<any, any, IUploadParkingArealImageParams>(mutationFn, options);

  return mutation;
};
