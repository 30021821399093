import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Container, CssBaseline, Grid, Backdrop, CircularProgress, Fade, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { getUserRole, getUserTravellers, getVehicle } from "rest/AdminRestClient";

import { AppBarMain } from "components/AppBar";
import { Sidebar } from "components";

import { useProfile } from "context";
import { useUserTravellers } from "rest";
import { useUserProfile } from "rest";
import {
  Travellers,
  Profile,
  Vehicles,
  Bookmarks,
  Bookings,
  Reviews,
  Stornieren,
  Rechnungen,
  BookingDetail,
} from "../../pages/authenticated";

import {
  ProfileProvider,
  BookingsProvider,
  BookmarksProvider,
  VehicleProvider,
  TravellersProvider,
} from "context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 80,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const UserPagesLayout: React.FC = () => {
  const classes = useStyles();

  const { selectedIndex, setSelectedIndex, checked, setProfile, openImageBackdrop, setOpenImageBackdrop } =
    useProfile();

  const [user, setUser] = useState<any>(null);
  const [fade, setFade] = useState<any>(false);
  const [vehicle, setVehicle] = useState<any>(null);
  const [key, setKey] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);
  const pathList = [
    "/user/profile",
    "/user/travellers",
    "/user/vehicles",
    "/user/bookmarks",
    "/user/bookings",
    "/user/bookings-past",
    "/user/reviews",
    "/user/stornieren",
    "/user/rechnungen",
  ];

  useUserProfile({
    onSuccess: (results) => {
      setProfile(results);
      setUser(results);
      setFade(true);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    getVehicle()
      .then((results) => {
        let vehicle = {
          lengthInM: parseFloat(results.data.lengthInM),
          numberplate: results.data.numberplate,
          vehicleOwner: results.data.owner,
          pendant: {
            lengthInM: results.data.pendantLengthInM,
            numberplate: results.data.pendantNumberplate,
            isPendantActive: results.data.isPendantActive,
          },
        };
        setVehicle(vehicle);
      })
      .catch((error) => console.error(error));
    let path = window.location.pathname;
    setSelectedIndex(pathList.indexOf(path) + 1);
  }, []);

  if (vehicle != null && user != null && fade) {
    return (
      <Fade in={fade}>
        <>
          <CssBaseline />
          <AppBarMain key={key} location={"profile"} />

          <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={2}>
              <Hidden lgDown>
                <Grid md={1} />
              </Hidden>
              <Grid item xs={12} md={2} xl={2}>
                <Sidebar user={user} fade={fade} />
              </Grid>
              <Grid item xs={12} md={10} xl={8}>
                <Outlet context={[vehicle]} />

                {/* <Route exact path={"/user/stornieren"} render={() => <Stornieren />} />
                <Route exact path={"/user/rechnungen"} render={() => <Rechnungen />} /> */}
              </Grid>

              <Hidden lgDown>
                <Grid md={1} />
              </Hidden>
            </Grid>
          </Container>
        </>
      </Fade>
    );
  } else {
    return (
      <>
        <CssBaseline />
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export const UserPages = withTranslation()(UserPagesLayout);
