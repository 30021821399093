import React, { useState, useContext, useEffect } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Grid,
  Avatar,
  useMediaQuery,
  Box,
  Link as Links,
  Tooltip,
} from "@material-ui/core";
import { ExitToApp as ExitToAppIcon, Person as PersonIcon, Menu as MenuIcon } from "@material-ui/icons";
import Image from "material-ui-image";

import { FirebaseAuth } from "services/firebase/Firebase";
import { getUserRole } from "rest/AdminRestClient";
import { AuthContext } from "context/Auth";
import { useUserArealEditToken } from "rest/user-service/queries/user-areal-edit-token";
import { AnyNaptrRecord } from "dns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "#fff",
      zIndex: theme.zIndex.drawer + 100,
    },
    appBarLogo: {
      height: 36,
      paddingRight: 8,
    },
    googleBadge: {
      height: 44,
    },
    accButton: {
      marginRight: theme.spacing(2),
    },
    menuButton: {
      textDecoration: "none",
    },
    datenschutz: {
      "&:hover": {
        backgroundColor: "#fff",
        color: "#4CAF50",
      },
    },
  })
);

const ButtonLogin = () => {
  return (
    <Button
      component={Link}
      to={"/login"}
      variant="contained"
      color="primary"
      startIcon={<ExitToAppIcon />}
      disableElevation
    >
      Login
    </Button>
  );
};

const ButtonProfile = (myAccount) => {
  return (
    <Button
      // onClick={myAccount()}

      component={Link}
      to={"/user/profile"}
      variant="contained"
      color="primary"
      disableElevation
    >
      Mein Konto
    </Button>
  );
};

const ButtonHomePage = ({ label }) => {
  return (
    <Button component={Link} to={"/"} variant="contained" color="primary" disableElevation>
      {label}
    </Button>
  );
};

const AppBarMainImp2 = (props) => {
  const { currentUser } = useContext<any>(AuthContext);
  const matchesBig = useMediaQuery("(min-width:1100px)");
  const matches = useMediaQuery("(min-width:835px)");
  const navigate = useNavigate();
  const classes = useStyles();

  const { t } = useTranslation();
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [role, setRole] = useState<String>("");
  const [secondRole, setSecondRole] = useState<String>("");
  const [user, setUser] = useState<any>({});
  const mopen = Boolean(anchorEl);

  const { mutate: getUserArealEditToken } = useUserArealEditToken({
    onSuccess: (data) => {
      // window.location.href = data;
      window.location.href = `https://meinplatz.camp24checkin.de/orx/token=${data}`;
      // window.location.href = `http://localhost:3000/orx/token=${data}`;
      // setToken(data);
    },
  });
  // useEffect(() => {
  //   if (currentUser) {
  //     if (currentUser.emailVerified === false) {
  //       history.push("/user/verify");
  //     } else {
  //       getUserRole("")
  //         .then((results) => {
  //           setSecondRole(results.data.secondRole);
  //           setRole(results.data.role);
  //           setUser(results.data.user);
  //         })
  //         .catch((error) => {
  //           FirebaseAuth.signOut()
  //             .then((_) => console.log("ok"))
  //             .catch((reason) => console.log(reason));
  //           console.info(error);
  //         });
  //     }
  //   }
  // }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    return FirebaseAuth.signOut()
      .then((_) => navigate("/"))
      .catch((reason) => console.log(reason));
  };

  const admin = () => {
    // return history.push({
    //   pathname: "/admin/parking_areal_list",
    //   state: { role: role },
    // });
  };

  const myAccount = () => {
    // return history.push({
    //   pathname: "/user/profile",
    //   state: { user: user },
    // });
  };

  const datenschutz = () => {
    return navigate("/help");
  };

  const impressum = () => {
    return navigate("/impressum");
  };

  const platzHinzufugen = () => {
    return navigate("/apply-new-areal");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <>
      {!matchesBig ? (
        <AppBar position="fixed" className={classes.root} elevation={2}>
          <Toolbar disableGutters={true}>
            <Grid item sm={2}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
              >
                <MenuItem>
                  <Links href={"https://b2b.c24c.eu"} target="_blank" style={{ display: "flex" }}>
                    <img src="/assets/images/b2b.png" alt="Camp 24" className={classes.appBarLogo} />
                  </Links>
                </MenuItem>
                <MenuItem>
                  <a href="https://apps.apple.com/us/app/camp-24-check-in/id1477801769?uo=4">
                    <img src="/assets/images/appleBadge.svg" alt="Camp 24" className={classes.appBarLogo} />
                  </a>
                </MenuItem>
                <MenuItem>
                  <a href="https://play.google.com/store/apps/details?id=de.camp24checkin.pr">
                    <img
                      src="/assets/images/google-play-badge.png"
                      alt="Camp 24"
                      className={classes.appBarLogo}
                    />
                  </a>
                </MenuItem>
                <MenuItem onClick={impressum}>Impressum</MenuItem>
                <MenuItem onClick={datenschutz}>Datenschutz</MenuItem>
                <MenuItem onClick={platzHinzufugen}>Platz Hinzufugen</MenuItem>
              </Menu>
            </Grid>

            <Grid item xs={4}>
              <Link to={"/"} style={{ display: "flex", justifyContent: "center" }}>
                <img src="/assets/images/logo.png" alt="Camp 24" className={classes.appBarLogo} />
              </Link>
            </Grid>
            <Grid item xs={currentUser ? 4 : 6} style={{ textAlign: "center", alignSelf: "center" }}>
              {!!currentUser ? (
                props.location === "homePage" ? (
                  <ButtonProfile />
                ) : (
                  <ButtonHomePage label={t("label_homepage")} />
                )
              ) : (
                <ButtonLogin />
              )}
            </Grid>
            {currentUser && (
              <Grid item xs={2}>
                <IconButton aria-label="Log Out" onClick={signOut}>
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            )}
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar position="fixed" className={classes.root} elevation={2}>
          <Toolbar disableGutters={false}>
            <Grid container>
              <Grid item sm={6} xs={8} lg={6} style={{ display: "flex", alignItems: "center" }}>
                <Links href={"https://b2b.c24c.eu"} target="_blank">
                  <img src="/assets/images/b2b.png" alt="Camp 24" className={classes.appBarLogo} />
                </Links>
                <a href="https://play.google.com/store/apps/details?id=de.camp24checkin.pr">
                  <img
                    src="/assets/images/google-play-badge.png"
                    alt="Camp 24"
                    className={classes.appBarLogo}
                  />
                </a>
                <a href="https://apps.apple.com/us/app/camp-24-check-in/id1477801769?uo=4">
                  <img src="/assets/images/appleBadge.svg" alt="Camp 24" className={classes.appBarLogo} />
                </a>
                <Link to={"/impressum"} className={classes.menuButton}>
                  <Button className={classes.datenschutz}>{t("imprint")}</Button>
                </Link>
                <Link to={"/help"} className={classes.menuButton}>
                  <Button className={classes.datenschutz}>{t("privacy")}</Button>
                </Link>
              </Grid>
              <Grid item xs={2} sm={1} lg={2} style={{ alignSelf: "end" }}>
                <Link to={"/"}>
                  <img
                    src="/assets/images/logo.png"
                    alt="Camp 24"
                    style={{ paddingLeft: "14px", height: "36px" }}
                  />
                </Link>
              </Grid>

              <Grid item sm={4} xs={4} lg={3} style={{ textAlign: "end", alignSelf: "center" }}>
                {!!currentUser && (
                  // <Link to={"/apply-new-areal"} className={classes.menuButton}>

                  <Button
                    style={{ marginRight: "5px" }}
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => getUserArealEditToken("suggest" as any)}
                    // onClick={() => {
                    //   console.log();
                    // }}
                  >
                    {t("add_space")}
                  </Button>
                  // </Link>
                )}
                {!!currentUser ? (
                  props.location === "homePage" ? (
                    <ButtonProfile />
                  ) : (
                    <ButtonHomePage label={t("label_homepage")} />
                  )
                ) : (
                  <ButtonLogin />
                )}
              </Grid>
              {currentUser && (
                <Grid item xs={1} style={{ textAlign: "center" }}>
                  <Tooltip title={"" + t("hover_logout")}>
                    <IconButton aria-label="Log Out" onClick={signOut}>
                      <ExitToAppIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export const AppBarMain = withTranslation()(AppBarMainImp2);
