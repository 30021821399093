import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { ICreateBookmarkParams } from "../domain";
import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useCreateBookmark = (options?: UseMutationOptions<any, any, ICreateBookmarkParams>) => {
  const { bookmarksApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    (payload: ICreateBookmarkParams) =>
      bookmarksApiService.createCancellableRequest((cancelToken) =>
        bookmarksApiService.createBookmark(payload, cancelToken)
      ),
    [bookmarksApiService]
  );

  const mutation = useMutation<any, any, ICreateBookmarkParams>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.BOOKMARK_ALL);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
