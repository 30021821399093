import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";

export const useUserOwn = <TData = any, TError = any>(queryOptions: UseQueryProps<TData, TError> = {}) => {
  const { userApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () => userApiService.createCancellableRequest((cancelToken) => userApiService.getUserOwn(cancelToken)),
    [userApiService]
  );

  return useQuery(QueryKeys.userOwn(), queryFn, queryOptions);
};
