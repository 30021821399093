import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  List,
  ListItem,
  Tooltip,
  Switch,
} from "@material-ui/core";

import { useVehicle } from "context";
import { useUpdateVehicle } from "rest";
import { InformationIcon } from "components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 12,
      fontSize: 20,
    },
    description: {
      marginBottom: 12,
      fontSize: 14,
    },
    media: {
      width: 200,
      height: 140,
    },
    mediaX: {
      width: 200,
      height: 140,
    },
    details: {
      display: "flex",
    },
    content: {
      padding: "16px !important",
      flex: "1 0 auto",
    },
    disabledDiv: {
      pointerEvents: "none",
      opacity: "0.7",
      transition: "0.5s",
      maxWidth: 345,
    },
    root: {
      maxWidth: 345,
    },
  })
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

// const RedSwitch = withStyles({
//   switchBase: {
//     color: "#FF0000 !important",
//   },
//   checked: { color: "#54AE36 !important" },
//   track: {},
// })(Switch);

const RedSwitch = withStyles({
  switchBase: {
    color: "#FF0000",
  },
  checked: {},
  track: {},
})(Switch);

export const TentCard = () => {
  const classes = useStyles();

  const { vehicles, card1, card2, card3, prev, setChecked, setSavedVehicle, setCheckedE } = useVehicle();

  // const { mutate: deleteVehicle } = useDeleteVehicle({
  //   onSuccess: () => {
  //     setChecked(true);
  //     setSavedVehicle(false);
  //     setTimeout(() => {
  //       setChecked(false);
  //     }, 5000);
  //   },
  //   onError: () => {
  //     setCheckedE(true);
  //     setTimeout(() => {
  //       setCheckedE(false);
  //     }, 5000);
  //   },
  // });

  const { mutate: updateVehicle } = useUpdateVehicle({
    onSuccess: () => {
      setSavedVehicle(false);
      setChecked(true);
      setTimeout(() => {
        setChecked(false);
      }, 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const handleTentActive = (e: any) => {
    let updateVehicleObject = {
      lengthInM: vehicles.lengthInM.replace(",", "."),
      numberplate: vehicles.numberplate.toString(),
      pendantLengthInM: vehicles.pendant.lengthInM.replace(",", "."),
      pendantNumberplate: vehicles.pendant.numberplate.toString(),
      isPendantActive: vehicles.pendant.isPendantActive,
      carLengthInM: vehicles.car.lengthInM.replace(",", "."),
      carNumberplate: vehicles.car.numberplate.toString(),
      isCarActive: vehicles.car.isCarActive,
      isVehicleActive: vehicles.isVehicleActive,
      hasMoto: vehicles.hasMoto,
      hasBike: vehicles.hasBike,
      hasTent: vehicles.hasTent,
    };

    switch (e.target.id) {
      case "moto":
        if (e.target.checked) {
          updateVehicleObject.hasMoto = true;
        } else {
          updateVehicleObject.hasMoto = false;
        }
        break;
      case "bike":
        if (e.target.checked) {
          updateVehicleObject.hasBike = true;
        } else {
          updateVehicleObject.hasBike = false;
        }
        break;
      case "tent":
        if (e.target.checked) {
          updateVehicleObject.hasTent = true;
        } else {
          updateVehicleObject.hasTent = false;
        }
        break;
    }

    updateVehicle(updateVehicleObject);
  };

  return (
    <Card className={card1 === 5 || card2 === 5 || card3 === 5 ? classes.disabledDiv : classes.root}>
      <CardContent className={card1 === 5 ? classes.disabledDiv : ""}>
        <Typography style={{ fontWeight: "bold" }} variant="h5" component="h2" color="primary">
          Zelt
        </Typography>
      </CardContent>

      <CardMedia
        // className={classes.mediaX}
        component="img"
        alt="Vehicle"
        height="200"
        image="/assets/images/tentHead.png"
        title="Vehicle"
      />
      <CardContent className={card1 === 5 ? classes.disabledDiv : ""}>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: "21px" }}
          // style={prev?.hasVehicle ? { paddingBottom: "12px" } : { paddingBottom: "0px" }}
        >
          <Grid item md={6}>
            <img src="/assets/images/tent.png" width="60" style={{ marginLeft: "5px" }} alt="" />
          </Grid>
          <Grid item md={6}>
            <RedSwitch
              color="primary"
              checked={prev?.hasTent}
              onChange={(e: any) => handleTentActive(e)}
              id={"tent"}
            />
            <HtmlTooltip
              title={
                <>
                  <span>
                    <p>Bitte wählen Sie ihre Fahrzeuge für die Reservierung</p>
                  </span>
                </>
              }
            >
              <InformationIcon style={{ fill: "green", marginLeft: "10px", verticalAlign: "middle" }} />
            </HtmlTooltip>
          </Grid>
          <Grid item md={6}>
            <img src="/assets/images/bike.png" width="60" alt="" />
          </Grid>
          <Grid item md={6}>
            <RedSwitch
              color="primary"
              checked={prev?.hasBike}
              onChange={(e: any) => handleTentActive(e)}
              id={"bike"}
            />
            <HtmlTooltip
              title={
                <>
                  <span>
                    <p>Bitte wählen Sie ihre Fahrzeuge für die Reservierung</p>
                  </span>
                </>
              }
            >
              <InformationIcon style={{ fill: "green", marginLeft: "10px", verticalAlign: "middle" }} />
            </HtmlTooltip>
          </Grid>
          <Grid item md={6}>
            <img src="/assets/images/moto.png" width="60" alt="" />
          </Grid>
          <Grid item md={6}>
            <RedSwitch
              color="primary"
              checked={prev?.hasMoto}
              onChange={(e: any) => handleTentActive(e)}
              id={"moto"}
            />
            <HtmlTooltip
              title={
                <>
                  <span>
                    <p>Bitte wählen Sie ihre Fahrzeuge für die Reservierung</p>
                  </span>
                </>
              }
            >
              <InformationIcon style={{ fill: "green", marginLeft: "10px", verticalAlign: "middle" }} />
            </HtmlTooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
