import axios, { AxiosInstance, CancelTokenSource } from "axios";
import type { CancelToken } from "axios";

import type {
  IFetchParkingArealByIdParams,
  ICreateFairMailParams,
  ICreatePayBackParams,
  IFetchCommentsOfParkingArealParams,
  IFetchPublicSearchParams,
  IFetchAddressFromGeocode,
} from "./domain";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  parkingAreal: "/parkingAreal",
  parkingArealSearch: "/parkingArealSearch",
  mail: "/fair/mail",
  payBack: "/payback",
};

export class PublicApiService {
  readonly http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: `${BaseURL}/api/public`,
    });
  }

  createCancellableRequest<TData>(
    requestCreator: (cancelToken: CancelTokenSource["token"]) => Promise<TData> | TData
  ): Promise<TData> | TData {
    const cancelTokenSource = axios.CancelToken.source();

    return requestCreator(cancelTokenSource.token);
  }

  async getPublicSearch(params: IFetchPublicSearchParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.parkingArealSearch, { params, cancelToken });

    return data;
  }

  async getParkingArealById(params: IFetchParkingArealByIdParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.parkingAreal + `/${params.id}`, { cancelToken });

    return data;
  }

  async fairMail(params: ICreateFairMailParams, cancelToken?: CancelToken) {
    const filter = params.name && params.email ? "?name=" + params.name + "&email=" + params.email : "";
    const { data } = await this.http.post(URLS.mail + filter, { cancelToken });

    return data;
  }

  async payBack(params: ICreatePayBackParams, cancelToken?: CancelToken) {
    const filter =
      params.orderid && params.payerid ? "?orderid=" + params.orderid + "&payerid=" + params.payerid : "";
    const { data } = await this.http.post(URLS.payBack + filter, { cancelToken });

    return data;
  }

  async getCommentsOfParkingAreal(params: IFetchCommentsOfParkingArealParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get(`/parkingAreal/${params.id}/comments`, { cancelToken });

    return data;
  }

  async getAddressFromGeocode(params: IFetchAddressFromGeocode, cancelToken?: CancelToken) {
    const { data } = await this.http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?" +
        `latlng=${params.latlng}&key=AIzaSyDpKBax9NNbkxs6n6Zj7rMcQKYWbyQaDJw`,
      { cancelToken }
    );

    return data;
  }
}
