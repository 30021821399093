export const rateCategoriesArray = [
    { 
        name: 'lage',
        text: 'Lage',
        data: 'location'
    },
    { 
        name: 'Ruhe',
        text: 'Ruhe',
        data: 'noiseLevel'
    },
    { 
        name: 'Ausstattung',
        text: 'Ausstattung',
        data: 'facilities'
    },
    { 
        name: 'Sauberkeit',
        text: 'Sauberkeit',
        data: 'cleanliness'
    },
    { 
        name: 'Preis',
        text: 'Preis-Leisung Verhältnis',
        data: 'price'
    },
    { 
        name: 'Aktivität',
        text: 'Aktivität möglichkeiten',
        data: 'activity'
    }
];