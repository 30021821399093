import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { InfoWindow, GoogleMap, Marker } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import {
  CssBaseline,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  FormControl,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  useDeleteBookmark,
  useChangeBookmarkFolder,
  useCreateBookmarkFolder,
  useBookmarksAll,
} from "rest/bookmark-service";
import { MarkerCampCard, BookmarkCard } from "components/user/bookmarks";
import { useBookmarks } from "context/Bookmarks";
const MarkerIcon = "/assets/images/marker/favorit.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 16,
      fontSize: 14,
      display: "inline-box",
    },
    cardActions: {
      padding: 16,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    homeButton: {
      marginBottom: "10px",
    },
  })
);

const UserBookmarks: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [newFolderForm, setNewFolderForm] = useState(false);
  const [folderName, setFolderName] = useState<string>("");
  const [mapRef, setMapRef] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [close, setClose] = useState(false);
  const [fade, setFade] = useState(false);
  const { t } = useTranslation();

  const {
    bookmarksAll,
    setBookmarksAll,
    changeFolder,
    setChangeFolder,
    changeFolderForm,
    setChangeFolderForm,
    zoom,
    setZoom,
    selectedPlace,
    setSelectedPlace,
    infoOpen,
    setInfoOpen,
    centerlng,
    setCenterlng,
    centerlat,
    setCenterlat,
    bookmarksMap,
    itemId,
    dialogOpen,
    setDialogOpen,
  } = useBookmarks();

  const isAllBookmarksEmpty = !bookmarksAll?.find((item) => item?.["bookmarks"]?.length > 0);

  const { refetch: getBookmarksInt } = useBookmarksAll({
    onSuccess: (results: Array<any>) => {
      setBookmarksAll(results);
      setFade(true);
    },
    onError: (error: any) => console.log(error),
  });

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const { mutate: createBookmarkFolder } = useCreateBookmarkFolder({
    onSuccess: () => {
      setNewFolderForm(false);
      setFolderName("");
      getBookmarksInt();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const createNewFolder = () => {
    createBookmarkFolder({ name: folderName });
  };

  const { mutate: deleteBookmark } = useDeleteBookmark({
    onSuccess: () => {
      getBookmarksInt();
      setDialogOpen(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const deleteInFolder = () => {
    deleteBookmark({ bookmarkId: itemId });
  };

  const openNewFolderForm = () => {
    setNewFolderForm(true);
  };

  const closeNewFolderForm = () => {
    setNewFolderForm(false);
    setFolderName("");
  };

  const closeChangeFolderForm = () => {
    setChangeFolderForm(false);
  };

  const { mutate: changeBookmarkFolder } = useChangeBookmarkFolder({
    onSuccess: () => {
      setChangeFolder({});
      setChangeFolderForm(false);
      getBookmarksInt();
    },
    onError: (error) => console.log(error),
  });

  const saveChangeFolderForm = () => {
    changeBookmarkFolder({
      bookmarkId: changeFolder.bookmarkId || "",
      data: { bookmarkFolderId: changeFolder.bookmarkFolderId || "" },
    });
  };

  const loadHandler = (map: any) => {
    // console.log(map);
    setMapRef(map);
    setZoom(5);
  };

  const markerLoadHandler = (marker: any, item: any) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [item.id]: marker };
    });
  };

  const centerChanged = (mapRef: any) => {
    // setCenterlat(mapRef.getCenter().lat());
    // setCenterlng(mapRef.getCenter().lng());
  };

  const markerClickHandler = (event: any, item: any) => {
    setSelectedPlace(item);

    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    if (zoom < 13) {
      setZoom(10);
    }

    setCenterlat(item.location.lat);
    setCenterlng(item.location.lon);
  };

  useEffect(() => {
    close
      ? setInfoOpen((previousValue) => (previousValue === false ? !previousValue : previousValue))
      : setClose(!close);
  }, [infoOpen]);

  return (
    <>
      <CssBaseline />
      <Card style={{ marginBottom: 18 }} id={"bookingpast"}>
        <GoogleMap
          onLoad={loadHandler}
          onCenterChanged={() => {
            centerChanged(mapRef);
          }}
          center={{ lat: centerlat, lng: centerlng }}
          zoom={zoom}
          mapContainerStyle={{
            height: "70vh",
            width: "100%",
          }}
        >
          {bookmarksMap &&
            bookmarksMap.map((item: any) => (
              <Marker
                position={{ lat: parseFloat(item.location.lat), lng: parseFloat(item.location.lon) }}
                onLoad={(marker) => markerLoadHandler(marker, item)}
                onClick={(event) => markerClickHandler(event, item)}
                icon={MarkerIcon}
                key={item.id + "marker"}
              />
            ))}
          {infoOpen && selectedPlace && (
            <InfoWindow anchor={markerMap[selectedPlace.id]} onCloseClick={() => setClose(true)}>
              <MarkerCampCard item={selectedPlace} />
            </InfoWindow>
          )}
        </GoogleMap>
      </Card>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Möchten Sie Ihren Favoriten löschen?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteInFolder} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Fade in={fade}>
        <Card>
          <CardContent>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography className={classes.title} color="primary" gutterBottom>
                Favoriten
              </Typography>
              {isAllBookmarksEmpty && (
                <Button
                  className={classes.homeButton}
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/")}
                >
                  {t("find_pitches")}
                </Button>
              )}
            </Grid>
            <Grid container>
              {bookmarksAll.map((item: any, index: number) => (
                <Grid item xs={12} key={item.id + "a"} style={{ paddingBottom: "7px" }}>
                  <BookmarkCard item={item} index={index} getBookmarksInt={getBookmarksInt} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button onClick={openNewFolderForm} variant="contained" color="primary" disableElevation>
              {t("add_new_folder")} +
            </Button>
          </CardActions>
        </Card>
      </Fade>
      <Dialog open={newFolderForm} onClose={closeNewFolderForm}>
        <DialogContent style={{ width: 400 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              margin="dense"
              label="Favoriten"
              onChange={(e) => setFolderName(e.target.value)}
              autoFocus
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNewFolderForm}>{t("label_cancel")}</Button>
          <Button onClick={createNewFolder} color="primary">
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={changeFolderForm} onClose={closeChangeFolderForm}>
        <DialogContent style={{ width: 400 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="select">{t("select_folder")}</InputLabel>
            <Select
              labelId="select"
              defaultValue={changeFolder.bookmarkFolderId}
              onChange={(e) =>
                setChangeFolder((prev) => ({ ...prev, bookmarkFolderId: e.target.value as string }))
              }
            >
              {bookmarksAll.map((bookmark: any, index: number) => (
                <MenuItem key={bookmark.id} value={bookmark.id}>
                  {bookmark.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeChangeFolderForm}>{t("label_cancel")}</Button>
          <Button onClick={saveChangeFolderForm} color="primary">
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const Bookmarks = withTranslation()(UserBookmarks);
