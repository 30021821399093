import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import "react-dates/lib/css/_datepicker.css";
import { getParkingSpotsSVG, getVehicle } from "rest/AdminRestClient";
import Paper from "@material-ui/core/Paper";
import SVG from "react-inlinesvg";
import { ParkingSpotComponentWeb } from "./ParkingSpotComponent";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import WheelPicker from "./WheelPicker";
import { Button, Dialog, DialogContent, DialogTitle, Hidden, Typography } from "@material-ui/core";
import showRotateDeviceText from "components/showRotateDevicetext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: {
      "&:hover $focusHighlight": {
        opacity: 0,
      },
    },
    focusHighlight: {},
  })
);

export const DateRange = (params: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let tempEv;
  const svgElement = React.createRef();
  const [spot, setSpot] = React.useState<any>("");
  const [svg, setSvg] = React.useState<any>("");
  const [svgList, setSvgList] = React.useState<any>([]);
  const [baseSpot, setBaseSpot] = React.useState<any>("");
  const [conflict, setConflict] = React.useState<any>(false);
  const [orientation, setOrientation] = React.useState<any>(false);
  const [show, setShow] = React.useState<any>(false);
  const [tentDialog, setTentDialog] = React.useState(false);

  useEffect(() => {
    setConflict(false);
    getVehicle();
    getParkingSpotsSVG(params.startDate.toISOString(), params.endDate.toISOString(), params.parkingAreal._id)
      .then((result: any) => {
        if (result.data === "Conflict") {
          setConflict(true);
        } else {
          setSvgList(result.data.circles);
          setSpot(
            result.data.circlesDet.sort((a: any, b: any) =>
              a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: "base",
              })
            )
          );
          // setSpot(result.data.circlesDet);
          setBaseSpot(JSON.parse(JSON.stringify(result.data.circlesDet)));
          setSvg(result.data.circles.join(""));
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (typeof window.screen.orientation !== "undefined") {
      setOrientation(true);
      window.screen.orientation.onchange = function () {
        if (!window.screen.orientation.type.includes("landscape")) {
          setShow(true);
        } else {
          setShow(false);
        }
      };
      if (!window.screen.orientation.type.includes("landscape")) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, []);

  const loaded = () => {
    // @ts-ignore
    svgElement.current?.addEventListener("click", (ev) => {
      if (ev.target.id) {
        changes(ev);
        params.setIsTent(false);
      }
      if (ev.target.getAttribute("data-type") === "P") {
        openTentDialog();
        params.setIsTent(true);
      }
    });

    // @ts-ignore
    // svgElement.current?.addEventListener("touchend", (ev) => {
    //   if (ev.target.id) {
    //     changes(ev);
    //   }
    // });
  };

  const closeTentDialog = () => {
    setTentDialog(false);
  };
  const openTentDialog = () => {
    setTentDialog(true);
  };

  const applyTentSize = (size: string) => {
    if (size === "small") {
      params.setTentSize("P");
    } else if (size === "medium") {
      return "Mittel";
    } else if (size === "large") {
      params.setTentSize("Q");
    }
    closeTentDialog();
  };

  const changes = (ev: any) => {
    if (tempEv !== undefined) {
      tempEv.target.setAttribute("style", "fill: white; fill-opacity: 0.01; cursor: pointer;");
    }
    ev.target.setAttribute("style", "fill: #67B449; fill-opacity: 1; cursor: pointer;");
    tempEv = ev;
    params.ready(true);
    params.spotId(ev.target.id);
    // params.setSpotName(ev.target.getAttribute("data-name"));
  };

  const handleCircle = (event: any, item: any, index: any) => {
    if (item.bookingState === 0) return null;
    let svglist = [...svgList];
    const regex = /Ebene_([^-]*)/m;
    // eslint-disable-next-line array-callback-return
    const indId = svgList.findIndex((o) => {
      let temp = o.match(regex);
      if (temp !== null && o.match(regex)[1] === item.name) {
        return o;
      }
    });
    // svglist[indId] = svglist[indId].replace(regex, "#ff8106");

    svglist[indId] = svglist[indId].replace(/none/, "#67b42e");
    setSvg(svglist.join(""));
    item.bookingState = 2;
    let spots = JSON.parse(JSON.stringify(baseSpot));
    spots[index] = item;
    setSpot(spots);
    params.spotName(item.name);
    let tyype =
      item.type === 0
        ? "S"
        : item.type === 1
        ? "M"
        : item.type === 2
        ? "L"
        : item.type === 3
        ? "XL"
        : item.type === 4
        ? "XXL"
        : null;
    params.spotType(tyype);
    params.spotId(item._id);
    params.ready(true);
  };

  // const spotButton = (item: any, index: number) => {
  //   return (
  //     <React.Fragment>
  //       <Card elevation={0}>
  //         <CardActionArea onClick={(event: any) => handleCircle(event, item, index)}>
  //           <CardMedia>
  //             <ParkingSpotComponentWeb type={item.type} bookingState={item.bookingState} label={item.name} />
  //           </CardMedia>
  //         </CardActionArea>
  //       </Card>
  //     </React.Fragment>
  //   );
  // };

  const spotButton = (item: any, index: number) => {
    return (
      <React.Fragment>
        <Card elevation={0}>
          <CardActionArea
            classes={{
              root: classes.actionArea,
              focusHighlight: classes.focusHighlight,
            }}
            onClick={(event: any) => handleCircle(event, item, index)}
          >
            <CardMedia>
              {
                <ParkingSpotComponentWeb
                  type={item.type}
                  bookingState={item.bookingState}
                  label={item.name}
                />
              }
            </CardMedia>
          </CardActionArea>
        </Card>
      </React.Fragment>
    );
  };

  if (conflict) {
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={0}>
          <Grid item xs={1} md={3} lg={4} />
          <Grid item xs={9} md={6} lg={4}>
            <Alert severity="warning">
              <AlertTitle>{t("warning")}</AlertTitle>
              {t("already_have_rez_warn")}
              <strong> {t("check_dates_before_rez")} </strong>
            </Alert>
          </Grid>
          <Grid item xs={1} md={3} lg={4} />
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      // <React.Fragment>
      //   <CssBaseline />
      //   <Grid container spacing={0} justifyContent="center">
      //     {svg && (
      //       <Grid item xs={10} md={7} lg={6}>
      //         <Paper elevation={0}>
      //           <SVG src={svg} />
      //         </Paper>
      //       </Grid>
      //     )}
      //   </Grid>

      //   <Grid container justifyContent="center" spacing={0}>
      //     <Grid item xs={11} md={8} lg={7}>
      //       <Grid container justifyContent="center" spacing={0}>
      //         {spot && spot.map((item: any, index: number) => spotButton(item, index))}
      //       </Grid>
      //     </Grid>
      //   </Grid>
      // </React.Fragment>
      <React.Fragment>
        <CssBaseline />
        <Dialog maxWidth="lg" open={tentDialog}>
          <DialogTitle style={{ textAlign: "center" }}>
            <Typography variant="h5">{t("tent_size_select")}</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => applyTentSize("small")}
                >
                  {t("small_tent")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => applyTentSize("large")}
                >
                  {t("large_tent")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {svg && (
          <>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12} md={12} lg={8} xl={5}>
                <Paper elevation={0}>
                  <SVG
                    src={svg}
                    style={{
                      maxWidth: "1500px",
                      // WebkitTouchCallout: "none",
                      // WebkitUserSelect: "none",
                      // KhtmlUserSelect: "none",
                      // MozUserSelect: "none",
                      // MsUserSelect: "none",
                      // userSelect: "none",
                    }}
                    // @ts-ignore
                    innerRef={svgElement}
                    onLoad={loaded}
                  />
                </Paper>
              </Grid>
              <Hidden mdUp>
                {" "}
                {orientation === true ? (show ? showRotateDeviceText(t) : "") : showRotateDeviceText(t)}
              </Hidden>
            </Grid>
            {/* <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12} md={12} lg={4} xl={5} justifyContent="center">
                <Grid container justifyContent="center" spacing={0} style={{ paddingTop: "15px" }}>
                  <div>{spot?.length > 0 && <WheelPicker handleCircle={handleCircle} spot={spot} />}</div>
                </Grid> */}
            {/* <Grid container justifyContent="center" spacing={0}>
                {spot && spot.map((item: any, index: number) => spotButton(item, index))}
              </Grid> */}
            {/* </Grid>
            </Grid> */}
          </>
        )}
        {/* <Grid container justifyContent="center" spacing={0}>
          <Grid item xs={11} md={8} lg={7}>
            <Grid container justifyContent="center" spacing={0}>
              {spot && spot.map((item: any, index: number) => spotButton(item, index))}
            </Grid>
          </Grid>
        </Grid> */}
      </React.Fragment>
    );
  }
};

export default DateRange;
