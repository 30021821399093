export const getBoundingBox = (centerPoint: any, distance: any) => {
  let MIN_LAT,
    MAX_LAT,
    MIN_LON,
    MAX_LON,
    R,
    radDist,
    degLat,
    degLon,
    radLat,
    radLon,
    minLat,
    maxLat,
    minLon,
    maxLon,
    deltaLon;
  if (distance < 0) {
    return "Illegal arguments";
  }

  MIN_LAT = -90 * (Math.PI / 180);
  MAX_LAT = 90 * (Math.PI / 180);
  MIN_LON = -180 * (Math.PI / 180);
  MAX_LON = 180 * (Math.PI / 180);
  R = 6378.1;
  radDist = distance / R;
  degLat = centerPoint[0];
  degLon = centerPoint[1];
  radLat = degLat * (Math.PI / 180);
  radLon = degLon * (Math.PI / 180);
  minLat = radLat - radDist;
  maxLat = radLat + radDist;
  minLon = void 0;
  maxLon = void 0;
  deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
  if (minLat > MIN_LAT && maxLat < MAX_LAT) {
    minLon = radLon - deltaLon;
    maxLon = radLon + deltaLon;
    if (minLon < MIN_LON) {
      minLon = minLon + 2 * Math.PI;
    }
    if (maxLon > MAX_LON) {
      maxLon = maxLon - 2 * Math.PI;
    }
  } else {
    minLat = Math.max(minLat, MIN_LAT);
    maxLat = Math.min(maxLat, MAX_LAT);
    minLon = MIN_LON;
    maxLon = MAX_LON;
  }
  return [
    (180 * minLon) / Math.PI,
    (180 * minLat) / Math.PI,
    (180 * maxLon) / Math.PI,
    (180 * maxLat) / Math.PI,
  ];
};

export const distance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515 * 1.609344;
  return dist.toFixed(2);
};
