import type { CancelToken } from "axios";

import type {
  IChangeBookmarkFolderParams,
  ICreateBookmarkParams,
  IDeleteBookmarkFolderParams,
  IDeleteBookmarkParams,
} from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";
import { ICreateBookmarkFolderParams } from "./domain/create-bookmark-folder";

const URLS = {
  userBookmark: "/bookmark",
  userBookmarkAll: "/bookmark/all",
  userBookmarkFolder: "/bookmark/folder",
};

export class BookmarkApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private/user`) {
    super(baseURL);
  }

  async getBookmarksAll(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userBookmarkAll + "?web=true", { cancelToken });

    return data;
  }

  async createBookmark(params: ICreateBookmarkParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.userBookmark, params, { cancelToken });

    return data;
  }

  async deleteBookmark(params: IDeleteBookmarkParams, cancelToken?: CancelToken) {
    const { data } = await this.http.delete(URLS.userBookmark + "/" + params.bookmarkId, { cancelToken });

    return data;
  }

  async getBookmarkFolderAll(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userBookmarkFolder, { cancelToken });

    return data;
  }

  async changeBookmarkFolder(params: IChangeBookmarkFolderParams, cancelToken?: CancelToken) {
    const { data } = await this.http.put(URLS.userBookmark + "/" + params.bookmarkId, params.data, {
      cancelToken,
    });

    return data;
  }

  async createBookmarkFolder(params: ICreateBookmarkFolderParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.userBookmarkFolder, params, {
      cancelToken,
    });

    return data;
  }

  async deleteBookmarkFolder(params: IDeleteBookmarkFolderParams, cancelToken?: CancelToken) {
    const { data } = await this.http.delete(URLS.userBookmarkFolder + "/" + params.bookmarkFolderId, {
      cancelToken,
    });

    return data;
  }
}
