import type { CancelToken } from "axios";

import type { IFetchPrivateSearchParams } from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  parkingArealSearch: "/parkingArealSearch",
};

export class SearchApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private`) {
    super(baseURL);
  }

  async getPrivateSearch(params: IFetchPrivateSearchParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.parkingArealSearch, { params, cancelToken });

    return data;
  }
}
