import type { CancelToken } from "axios";

import type {
  ICreateTravellerParams,
  IDeleteTravellerParams,
  IUpdateTravellerParams,
  IPetStatusParams,
} from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  userTraveller: "/travellers",
  userTravellerStatus: "/traveller",
  petStatus: "/user/pet",
};

export class TravellerApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private/user`) {
    super(baseURL);
  }

  async getUserTravellers(cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.userTraveller, { cancelToken });

    return data;
  }

  async createTraveller(params: ICreateTravellerParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.userTraveller, params.data, { cancelToken });

    return data;
  }

  async updateTraveller(params: IUpdateTravellerParams, cancelToken?: CancelToken) {
    const { data } = await this.http.put(URLS.userTraveller + "/" + params.data.id, params.data, {
      cancelToken,
    });

    return data;
  }

  async deleteTraveller(params: IDeleteTravellerParams, cancelToken?: CancelToken) {
    const { data } = await this.http.delete(URLS.userTraveller + "/" + params.id, {
      cancelToken,
    });

    return data;
  }

  async petStatus(params: IPetStatusParams, cancelToken?: CancelToken) {
    const { userId, pet } = params;
    let filter = userId && pet ? "?id=" + userId + "&pet=" + pet.toString() : "";
    const { data } = await this.http.post(URLS.petStatus + filter, undefined, {
      cancelToken,
      baseURL: `${BaseURL}/api/private`,
    });

    return data;
  }
}
