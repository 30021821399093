import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./style";

export const Cookies: React.FC = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      buttonText={t("agree_usage_cookie")}
      declineButtonText={t("delete_and_reject_cookies")}
      cookieName="camp24checkin"
      style={styles.banner}
      contentStyle={styles.message}
      buttonStyle={styles.button}
      declineButtonStyle={styles.declineButton}
      buttonWrapperClasses="buttonDiv"
      flipButtons
      enableDeclineButton
      onDecline={() => {
        window.location.replace("http://www.google.com");
      }}
      expires={365}
      acceptOnScroll={true}
      acceptOnScrollPercentage={75}
      setDeclineCookie={false}
    >
      {t("cookie_consent")} <Link to={"/help"}>{t("further_info")}</Link>
    </CookieConsent>
  );
};
