import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardContent, DialogActions, makeStyles, styled, Typography } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Tabs,
  Tab,
  OutlinedInput,
  Stack,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useBookings } from "context";
import { BasketItem } from "./BasketItem";
import { useCreateOrder, useGetProducts } from "rest/orders-service";
import { on } from "process";
import { set } from "date-fns";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    height: "200px",
    "&:hover": {
      cursor: "pointer",
      // boxShadow: "0px 10px 10px rgba(0,0,0,0.3)",
    },
  },
  media: {
    width: "100%",
    height: "auto",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  content: {
    flex: "1 0 auto",
    padding: theme.spacing(2),
    width: "600px",
  },
  orangeButton: {
    // color: "white",
    backgroundColor: "#FFA500", // Orange color
    "&:hover": {
      backgroundColor: "#FF8C00", // Darker orange on hover
    },
    color: "white", // Automatically set text color for contrast
  },
}));

const OrderDialog = ({ orderDialog, onClose, suppliers, bookingId }) => {
  const [tab, setTab] = useState<null | string>(null);
  const [supplierId, setSupplierId] = useState<string>("");
  const [showBasket, setShowBasket] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<any>>([]);
  const { t } = useTranslation();
  const { basket, setBasket } = useBookings();

  const classes = useStyles();
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
        {...other}
      >
        {value === index && (
          <>
            <Box sx={{ paddingLeft: 3, overflow: "scroll", height: "90%" }}>{children}</Box>
            <Box sx={{ height: "10%" }} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button onClick={() => setShowBasket(true)} variant="outlined">
                Einkaufswagen
              </Button>
            </Box>
          </>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // console.log(newValue);
    setSupplierId(newValue);
    setTab(newValue);
  };

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      // Handle backdrop click event
    } else {
      onClose?.();
      setTimeout(() => {
        setBasket([]);
        setProducts([]);
      }, 250);

      setTimeout(() => {
        setTab(null);
      }, 500);
    }
  };

  const { refetch: getProducts } = useGetProducts(
    { supplierId: supplierId },
    {
      enabled: false,
      onSuccess: (results: Array<any>) => {
        setProducts(results);
      },
      onError: (error: any) => console.error(error),
    }
  );

  useEffect(() => {
    if (tab !== null) {
      getProducts();
    }
  }, [tab]);

  return (
    <Dialog
      open={orderDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
    >
      <DialogTitle marginBottom={4} borderBottom={1} id="alert-dialog-title">
        Bestellungen
      </DialogTitle>

      <DialogContent>
        {showBasket ? (
          <BasketDialogContent
            setShowBasket={setShowBasket}
            handleClose={handleClose}
            bookingId={bookingId}
          />
        ) : tab !== null && products.length > 0 ? (
          // <Box
          //   sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", height: "70vh", width: "100%" }}
          // >
          <Grid container spacing={2}>
            {products.map((i, ind) => (
              <Grid item sm={12} key={ind + 99}>
                <OrderItem item={i} index={ind} />
              </Grid>
            ))}
            <Grid container sm={12} justifyContent="flex-end">
              <Grid>
                <Button
                  onClick={() => handleClose({}, "escapeKeyDown")}
                  variant="contained"
                  disableElevation
                  className={classes.orangeButton}
                >
                  Abbrechen
                </Button>
              </Grid>
              <Grid style={{ paddingLeft: "10px" }}>
                <Button
                  onClick={() => setShowBasket(true)}
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  Zum Einkaufswagen
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <LandingDialogContent handleChange={handleChange} handleClose={handleClose} suppliers={suppliers} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderDialog;

const OrderItem = ({ index, item }) => {
  const { basket, setBasket } = useBookings();
  const [count, setCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleBasket = () => {
    console.log(item);
    if (count > 0) {
      const basketItem = {
        id: item.id,
        name: item.name,
        brut: item.brut,
        photo: item.photo,
        description: item.description,
        count: count,
        gross: item.brut * count,
      };

      setBasket((st) => [...st, basketItem]);
      setDialogOpen(true);
      setCount(0);
      setTimeout(() => {
        setDialogOpen(false);
      }, 4000);
    }
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle id="form-dialog-title">Erfolgreich</DialogTitle>

        <DialogContent style={{ minWidth: "40vw" }}>
          <Typography variant="subtitle2" color="textPrimary">
            Ihr Artikel wurde Ihrem Warenkorb hinzugefügt.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary" variant="contained" disableElevation>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        alignItems={"center"}
        // marginTop={index === 0 ? 0 : 4}
        // columnSpacing={{ sm: 2, md: 0 }}
        // paddingBottom={2}
        paddingTop={1}
        borderBottom={1}
        borderColor="divider"
        container
      >
        <Grid item md={3}>
          <img
            alt="Supplier "
            style={{
              objectFit: "cover",
              background: "#c9c9c9",
              // height: 100,
              width: 150,
              borderRadius: "8px",
            }}
            src={item.photo}
          />
        </Grid>

        <Grid item md={5} justifyContent="flex-start">
          <Stack spacing={1}>
            <Typography variant="h5" color="textPrimary">
              {item.name}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {item.description}
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {`${item.brut.toFixed(2).replace(".", ",")} €`}
            </Typography>
          </Stack>
        </Grid>

        <Grid display="grid" justifyContent="flex-end" item md={2}>
          <OutlinedInput
            defaultValue={0}
            value={count}
            sx={{ height: "40px", width: "100px" }}
            type="number"
            placeholder="0"
            inputProps={{ maxLength: 2, min: 0 }}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length > 2) {
                e.target.value = value.slice(0, 2);
              }
              if (parseInt(value, 10) < 0) {
                e.target.value = "0";
              }
              setCount(parseInt(e.target.value, 10));
            }}
          />
        </Grid>
        <Grid display="grid" justifyContent="center" item md={2}>
          <IconButton onClick={handleBasket}>
            <AddShoppingCartIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

const LandingDialogContent = ({ handleChange, suppliers, handleClose }) => {
  const classes = useStyles();
  return (
    <>
      {/* <Typography variant={"h5"} color="textPrimary" style={{ textAlign: "center" }}>
        WÄHLEN SIE DEN ORT, AN DEM SIE BESTELLEN MÖCHTEN
      </Typography> */}

      {suppliers?.map((i, index) => {
        const isFirst = index === 0;

        return (
          <Grid
            columnSpacing={{ sm: 2, md: 0 }}
            paddingTop={0}
            borderTop={isFirst ? 0 : 2}
            marginTop={isFirst ? 0 : 4}
            // paddingBottom={1}
            container
            key={i?.id}
          >
            <Card className={classes.root} variant="outlined" onClick={(event) => handleChange(event, i?.id)}>
              <img src={i?.photo} alt={i?.name} className={classes.media} />
              <CardContent className={classes.content}>
                <Typography variant="h6" component="h2">
                  {i?.name}
                </Typography>
                <Typography variant="body1" color="textSecondary" noWrap={false}>
                  {i?.description}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" style={{ paddingTop: "10px" }}>
                  {i?.address}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {i?.phone}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
      <Grid container sm={12} justifyContent="flex-end" sx={{ paddingTop: "10px" }}>
        <Grid>
          <Button
            onClick={() => handleClose({}, "escapeKeyDown")}
            variant="contained"
            disableElevation
            className={classes.orangeButton}
          >
            Abbrechen
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const BasketDialogContent = ({ setShowBasket, handleClose, bookingId }) => {
  const { basket, setBasket } = useBookings();
  const [openEmpty, setOpenEmpty] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const classes = useStyles();

  const { mutate: createOrder } = useCreateOrder({
    onSuccess: (data) => {
      setOpenSuccess(true);
      setTimeout(() => {
        setBasket([]);
        handleClose({}, "escapeKeyDown");
        setShowBasket(false);
      }, 4000);
      // console.log(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const emptyBasket = () => {
    setBasket([]);
    handleClose({}, "escapeKeyDown");
    setTimeout(() => {
      setShowBasket(false);
    }, 500);
  };

  const closeSuccess = () => {
    handleClose({}, "escapeKeyDown");
    setShowBasket(false);
    setBasket([]);
    setTimeout(() => {
      setOpenSuccess(false);
    }, 500);
  };

  const confirm = () => {
    createOrder({
      bookingId: bookingId,
      products: basket.map((i) => {
        return {
          productId: i.id,
          count: i.count,
        };
      }),
    });
    // setBasket([]);
    // handleClose({}, "escapeKeyDown");
    // setTimeout(() => {
    //   setShowBasket(false
  };

  return (
    <>
      <Dialog open={openEmpty} onClose={() => setOpenEmpty(false)}>
        <DialogTitle id="form-dialog-title">Löschen</DialogTitle>

        <DialogContent style={{ minWidth: "40vw" }}>
          <Typography variant="subtitle1">Ihre Bestellung wird dauerhaft gelöscht.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEmpty(false)} color="primary" className={classes.orangeButton}>
            Abbrechnen
          </Button>
          <Button
            variant="contained"
            disableElevation
            style={{ backgroundColor: "red", color: "white" }}
            onClick={emptyBasket}
          >
            Bestellung stornieren
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSuccess} onClose={() => closeSuccess()}>
        <DialogTitle id="form-dialog-title">Erfolg</DialogTitle>
        <DialogContent style={{ minWidth: "40vw" }}>
          <Typography variant="subtitle1">Ihre Bestellung war erfolgreich.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeSuccess()}
            color="primary"
            className={classes.orangeButton}
            variant="contained"
            disableElevation
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <DialogTitle id="confirm-dialog-title">Bestätigung</DialogTitle>
        <DialogContent style={{ minWidth: "40vw" }}>
          <Typography variant="subtitle1">
            Sind Sie sicher, dass Sie diese Aktion durchführen möchten?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirm(false)}
            color="primary"
            variant="contained"
            className={classes.orangeButton}
            disableElevation
          >
            Abbrechen
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            // style={{ backgroundColor: "red", color: "white" }}
            onClick={() => {
              confirm();
              // Your confirm action logic here
              // setOpenConfirm(false);
            }}
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Bild</TableCell>
              <TableCell>Produktname</TableCell>
              <TableCell>Preis pro Stück</TableCell>
              <TableCell>Anzahl</TableCell>
              <TableCell>Preis</TableCell>
              <TableCell>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {basket?.map((i) => (
              <BasketItem item={i} />
            ))}
            {/* <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Net</TableCell>
              <TableCell></TableCell>
              <TableCell>13,00€</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Tax</TableCell>
              <TableCell></TableCell>
              <TableCell>1,00€</TableCell>
              <TableCell></TableCell>
            </TableRow> */}

            {basket?.length ? (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color="textPrimary">
                    Gesammt
                  </Typography>
                </TableCell>
                <TableCell></TableCell>{" "}
                <TableCell>
                  <Typography variant="subtitle2" color="textPrimary">
                    {`${basket
                      .reduce((total, item) => total + item.gross, 0)
                      .toFixed(2)
                      .replace(".", ",")} €`}
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : null}
            {!basket?.length && (
              <Typography style={{ padding: "16px 16px" }} variant="subtitle2" color="textPrimary">
                Warenkorb ist leer
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box marginTop={2} width="100%" display="flex" justifyContent="space-between">
        <Box display="flex" columnGap={2}>
          <Button
            onClick={() => setShowBasket(false)}
            variant="contained"
            disableElevation
            className={classes.orangeButton}
          >
            Zurück
          </Button>

          <Button
            onClick={() => setOpenEmpty(true)}
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            disableElevation
          >
            Bestellung stornieren
          </Button>
        </Box>
        <Button onClick={() => setOpenConfirm(true)} variant="contained" color="primary" disableElevation>
          Bestätigen
        </Button>
      </Box>
    </>
  );
};
