import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
  banner: {
    height: "100%",
    position: "fixed",
    top: 0,
    backgroundColor: "rgba(255,255,255,0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  message: {
    maxWidth: 1000,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 40,
    color: "#000",
    lineHeight: "inherit",
    backgroundColor: "#fff",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    flex: "inherit",
    margin: "inherit",
  },
  button: {
    marginBottom: 0,
    padding: "6px 16px",
    top: "none",
    right: "none",
    color: "#fff",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.75,
    backgroundColor: "#7ed321",
    borderRadius: 4,
    display: "initial",
  },
  declineButton: {
    marginBottom: 0,
    padding: "6px 16px",
    top: "none",
    right: "none",
    borderRadius: 4,
    display: "initial",
  },
};

export default styles;
