import React, { useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";

import { useQueryClient } from "react-query";
import {
  CssBaseline,
  Card,
  makeStyles,
  Button,
  Typography,
  Avatar,
  Collapse,
  DialogActions,
} from "@material-ui/core";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Divider,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Stack,
  Alert,
  Box,
} from "@mui/material";
import { ArrowBack, SettingsSuggest, ElectricBolt, Close as CloseIcon } from "@mui/icons-material";
import WaterIcon from "@mui/icons-material/Water";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteBooking, useGetUserBookingDetails } from "rest";
import { OrdersTable } from "components";
import OrderDialog from "components/user/orders/OrderDialog";
import { useGetAllOrdersByBookingId, useGetSuppliers } from "rest/orders-service";

const orderImage = "/assets/images/bread.png";
const rowsX: any = [createData("Frozen yoghurt", 4, 3, 3), createData("Ice cream sandwich", 5, 2, 3)];

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  booking: {
    backgroundColor: "#DCE6A0",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
  no: {
    backgroundColor: "#f9c5af",
    borderBottom: "5px solid white",
    cursor: "pointer",
  },
});

const statusOptions = {
  FUTURE: { label: "Reserviert", color: "purple" },
  WAITING: { label: "Reserviert", color: "yellow" },
  CANCELED: { label: "Storniert", color: "red" },
  ONGOING: { label: "Belegt", color: "blue" },
  COMPLETED: { label: "Belegt", color: "green" },
  LATE: { label: "Reserviert", color: "red" },
};

const columns = [
  {
    id: "picture",
    // label: <SettingsSuggest fontSize="large" />,
    minWidth: 170,
    align: "left" as "left",
  },
  { id: "code", label: "Bezeichnung", minWidth: 100, align: "left" as "left" },
  {
    id: "population",
    label: "Anzahl",
    minWidth: 170,
    align: "center" as "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(picture, code, population, size) {
  return { picture, code, population };
}

const rows = [
  createData(<WaterIcon fontSize="medium" color="primary" />, "Wasser", "84", 3287263),
  createData(<ElectricBolt fontSize="medium" color="primary" />, "Strom", "7,32", 9596961),
];

const UserBookings = (props: any) => {
  const classes = useStyles();
  const [close, setClose] = useState(false);
  const [closex, setClosex] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const [checked, setChecked] = useState(false);
  const [orderDialog, setOrderDialog] = useState(false);
  const [suppliers, setSuppliers] = useState<any>([]);
  const [orders, setOrders] = useState<any>([]);
  const location = useLocation();

  const { data = null } = useGetUserBookingDetails<any>({
    bookingId: location.pathname.split("booking-detail/")[1],
  });

  useGetSuppliers(
    { parkingArealId: data?.parkingArealId },
    {
      onSuccess: (results: Array<any>) => {
        setSuppliers(results);
      },
      onError: (error: any) => console.error(error),
    }
  );

  useGetAllOrdersByBookingId(
    { bookingId: location.pathname.split("booking-detail/")[1] },
    {
      onSuccess: (results: Array<any>) => {
        setOrders(results);
      },
      onError: (error: any) => console.error(error),
    }
  );

  const { mutate: deleteBooking } = useDeleteBooking({
    onSuccess: () => {
      setChecked(true);

      queryClient.invalidateQueries("booking_history_of_user");
      setTimeout(() => {
        setChecked(false);
        navigate(`/user/bookings`);
      }, 3000);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleCloseDialog = () => {
    setClosex(false);
  };

  const openCloseDialog = () => {
    setClosex(true);
  };

  const userData = data?.userTravelers?.find((user) => user?.id === data?.userId);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const del = () => {
    handleCloseDialog();
    // alert("This is your booking");
    deleteBooking({ id: location.pathname.split("booking-detail/")[1] });
  };

  const orderDialogOpen = () => {
    setOrderDialog(true);
  };

  const orderDialogClose = () => {
    setOrderDialog(false);
  };

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return (
    <>
      <OrderDialog
        orderDialog={orderDialog}
        onClose={orderDialogClose}
        suppliers={suppliers}
        bookingId={data?._id}
      />

      <Dialog
        open={closex}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("cancel_booking")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("cancel_booking_confirm")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t("no")}
          </Button>
          <Button onClick={del} color="primary" autoFocus>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Collapse in={checked}>
        <Alert severity="success">{t("succesfully_cancelled_booking")}</Alert>
      </Collapse>
      <Dialog
        open={close}
        onClose={console.log}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="delete"
            size="medium"
            onClick={() => setClose(false)}
            sx={{
              float: "right",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              <Grid
                container
                spacing={{ sm: 3, md: 0 }}
                style={{ marginBottom: 20, width: 600, maxWidth: "100%" }}
                alignItems="center"
                direction={{ xs: "column", md: "row" }}
              >
                <Grid item xs={12} sm={4}>
                  <img
                    style={{ height: 100, width: 150, objectFit: "contain" }}
                    src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Free-Download.png"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div>
                    <b>Vorname:</b> Valentin
                  </div>
                  <div>
                    <b>Familiename: </b> Blau
                  </div>
                  <div>
                    <b> Geburtsdatum: </b> ************
                  </div>
                  <div>
                    <b> Geburtsort:</b> ************
                  </div>
                  <div>
                    <b>Staatsangehorigkeit: </b> ************
                  </div>
                  <div>
                    <b>Ausweisnummer: </b> ************
                  </div>
                  <div>
                    <b>Adresse: </b> ************
                  </div>
                  <div>
                    <b>Wohnland: </b> ************
                  </div>
                </Grid>
              </Grid>
            }
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isPersonModalOpen}
        onClose={console.log}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="delete"
            size="medium"
            onClick={() => setIsPersonModalOpen(false)}
            sx={{
              float: "right",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              <Grid
                container
                spacing={{ sm: 3, md: 0 }}
                style={{ marginBottom: 20, width: 600, maxWidth: "100%" }}
                alignItems="center"
                direction={{ xs: "column", md: "row" }}
              >
                <Grid item xs={12} sm={4} alignSelf="start" direction="column">
                  <img
                    style={{
                      height: 100,
                      maxWidth: "200",
                      objectFit: "contain",
                      marginLeft: 15,
                      borderRadius: "50%",
                    }}
                    src={modalData?.avatar}
                  />

                  {/* <Avatar src={modalData?.avatar} sx={{ width: 100, height: 100 }} /> */}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div>
                    <b>Vorname:</b> {modalData?.firstName}
                  </div>
                  <div>
                    <b>Familiename: </b> {modalData?.lastName}
                  </div>
                  <div>
                    <b> Geburtsdatum: </b> {new Date(modalData?.birthDate).toLocaleDateString("de")}
                  </div>
                  <div>
                    <b> Geburtsort:</b> {modalData?.birthPlace}
                  </div>
                  <div>
                    <b>Staatsangehorigkeit: </b> {modalData?.nationality}
                  </div>
                  <div>
                    <b>Ausweisnummer: </b> {modalData?.identityCardNo}
                  </div>
                  <div>
                    <b>Adresse: </b> {modalData?.streetAndHouseNumber} {modalData?.area}
                    {modalData?.areaCode}
                  </div>
                  <div>
                    <b>Wohnland: </b>
                    {modalData?.countryCode}
                  </div>
                </Grid>
              </Grid>
            }
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <CssBaseline />
      <Card style={{ marginBottom: 18, padding: 15 }}>
        {/* <Grid container spacing={0} style={{ marginTop: "auto" }}>
          <Grid item xs={10}>
            <div>
              <b>Platze: </b>
              <span>CAMP24 RIELASINGEN</span>
            </div>
            <div>
              <b>Datum: </b>
              <span>28.11.2022 - 01.12.2022 / verlängert</span>
            </div>
            <div>
              <b>PIN: </b>
              <span>1234</span>
            </div>
            <div>
              <b>Ausgewählter Parkplatz: </b>
              <span>08 / geändert von 03</span>
            </div>
            <div>
              <b>Adresse: </b>
              <span>Singener Strasse 25, 78239 Rielasingen-Worblingen</span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <b style={{ color: "red" }}>Eingecheckt</b>
          </Grid>
        </Grid> */}
        <Grid
          container
          spacing={1}
          direction={{ xs: "column", md: "row" }}
          ml={{ xs: "3px !important", sm: "10% !important", md: "10px !important" }}
          pb={2}
        >
          <Grid item xs={12} sm={7} pl={{ sm: "20px", lg: 0 }}>
            <Typography
              // sx={{ paddingTop: "5px" }}
              className={"MuiTypography--heading"}
              variant={"h6"}
              color="textPrimary"
              gutterBottom
            >
              Datum: {new Date(data?.startDate).toLocaleDateString("de")} -{" "}
              {new Date(data?.endDate).toLocaleDateString("de")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} textAlign="center">
            <Typography
              // sx={{ paddingTop: "5px" }}
              className={"MuiTypography--heading"}
              variant={"h6"}
              color="textPrimary"
              gutterBottom
            >
              {data?.parkingAreal?.name}
            </Typography>
            {/* <b>Platznummer: {data?.parkingSpot?.name}</b> */}
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={2}
            pl={{ sm: "10%" }}
            alignSelf="center"
            mr={{ xs: "auto", md: 0 }}
            style={{ textAlign: "center" }}
          > */}
          {/* <Image src={userData?.avatar} sx={{ width: 84, height: 84 }} /> */}
          {/* <img
              style={{ height: 100, maxWidth: "200", objectFit: "contain", margin: "10px 0" }}
              src={userData?.avatar}
            />
          </Grid> */}
          <Grid item xs={12} sm={4} pl={{ sm: "10%" }}>
            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              {/* <div> */}
              <b>Vorname:</b> {userData?.firstName}
              {/* </div> */}
            </Typography>

            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b>Familiename: </b> {userData?.lastName}
            </Typography>

            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b> Geburtsdatum: </b> {new Date(userData?.birthDate).toLocaleDateString("de")}
            </Typography>

            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b> Geburtsort:</b> {userData?.birthPlace}
            </Typography>

            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b>Staatsangehorigkeit: </b> {userData?.nationality}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} pl={{ sm: "10%" }}>
            {/* <div>
              <b style={{ color: statusOptions?.[data?.status || "WAITING"]?.color }}>
                {statusOptions?.[data?.status || "WAITING"]?.label}
              </b>
            </div> */}

            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b>Status: </b> {statusOptions?.[data?.status || "WAITING"]?.label}
            </Typography>
            {data?.userVehicle?.caravanNumberplate !== "" && (
              <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
                <b>Wohnmobil: </b> {data?.userVehicle?.caravanNumberplate}
              </Typography>
            )}
            {data?.userVehicle?.carNumberplate !== "" && (
              <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
                <b> Fahrzeug: </b> {data?.userVehicle?.carNumberplate}
              </Typography>
            )}
            {data?.userVehicle?.pendantNumberplate !== "" && (
              <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
                <b> wohnwagen:</b> {data?.userVehicle?.pendantNumberplate}
              </Typography>
            )}
            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b>PIN: </b> {data?.PIN}
            </Typography>
          </Grid>
          {/* <Grid item sm={1} /> */}
          <Grid item xs={12} sm={5} pl={{ sm: "10%" }} textAlign="center">
            <Typography
              variant="h3"
              style={{
                // minWidth: "100px",
                // minHeight: "100px",
                fontSize: "4.5rem",
                fontWeight: 500,
                color: "#00713C",
              }}
            >
              {data?.parkingSpot?.name}
            </Typography>

            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b>Telefon: </b> {data?.parkingAreal?.phone}
            </Typography>
            <Typography className={"MuiTypography--subheading"} variant={"subtitle1"} color="textSecondary">
              <b>Adresse: </b>{" "}
              {`${data?.parkingAreal?.address?.streetAndHouseNumber}, ${data?.parkingAreal?.address?.areaCode}`}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {data?.checkIn && (
        <>
          <Grid container spacing={0} style={{ marginBottom: 25 }}>
            <Grid item xs={8}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth }}
                            align={column?.align}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column?.align}>
                                  {column.format && typeof value === "number" ? column.format(value) : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Paper>
            </Grid>

            <Grid item xs={4} style={{ paddingLeft: 25 }}>
              <Card style={{ padding: 10 }}>
                <b style={{ color: "gray", display: "flex", justifyContent: "center", padding: 5 }}>
                  Reisende
                </b>
                <Divider></Divider>
                {data?.userTravelers?.map((item) => {
                  return (
                    <Button
                      key={item.id}
                      fullWidth
                      color="primary"
                      onClick={() => {
                        setModalData(item);
                        setIsPersonModalOpen(true);
                      }}
                      style={{ justifyContent: "flex-start" }}
                    >
                      {item?.firstName + " " + item?.lastName}
                    </Button>
                  );
                })}
              </Card>
              {data?.pet?.count !== "none" && (
                <Card style={{ padding: 10, marginTop: "8px" }}>
                  <Typography
                    className={"MuiTypography--subheading"}
                    variant={"subtitle1"}
                    color="textSecondary"
                  >
                    <b>Hund: </b> {data?.pet?.count}
                  </Typography>
                </Card>
              )}
            </Grid>
          </Grid>
          {(data?.dynamicItems.length > 0 || orders.length > 0) && (
            <Typography
              className={"MuiTypography--heading"}
              variant="subtitle2"
              color="textPrimary"
              gutterBottom
              style={{ marginLeft: 3 }}
            >
              Bestellungen
            </Typography>
          )}
          {data.dynamicItems.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell align="right">Leistung</TableCell>
                    <TableCell align="right">Anzahl&nbsp;(g)</TableCell>
                    <TableCell align="right">Einzelpreis&nbsp;(g)</TableCell>
                    <TableCell align="right">Steuersatz&nbsp;(g)</TableCell>
                    <TableCell align="right">Summe&nbsp;(g)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.dynamicItems?.map((row: any) => (
                    <TableRow key={row.date} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {new Date(row.date).toLocaleDateString("de-DE")}
                      </TableCell>
                      <TableCell align="right">{row.description}</TableCell>
                      <TableCell align="right">{row.count}</TableCell>
                      <TableCell align="right">{row.price}</TableCell>
                      <TableCell align="right">{row.tax}</TableCell>
                      <TableCell align="right">{row.sum}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          {orders.length > 0 ? <OrdersTable parkingArealId={data?.parkingArealId} orders={orders} /> : null}
          {data && <p>Reserviert am {new Date(data.createdAt).toLocaleDateString("de-DE")}</p>}
        </>
      )}
      <Grid sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}>
        <Stack direction="row" spacing={2}>
          {!data?.checkIn ? (
            <Button
              variant="contained"
              style={{ backgroundColor: "red", color: "white" }}
              disableElevation
              size="large"
              onClick={() => openCloseDialog()}
            >
              Löschen
            </Button>
          ) : data?.checkOut ? (
            <Button
              variant="contained"
              color="default"
              disableElevation
              size="large"
              // onClick={() => params.handleClose()}
            >
              Rechnung
            </Button>
          ) : (
            suppliers.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                size="large"
                onClick={() => orderDialogOpen()}
              >
                Bestellungen
              </Button>
            )
          )}
        </Stack>
      </Grid>
    </>
  );
};

export const BookingDetail = withTranslation()(UserBookings);
