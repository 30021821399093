import React, { useState, createContext } from "react";

import { IUserDataContext } from "interfaces/context";
import { IUserData } from "interfaces/user";

const initialTravellerData = {
  firstName: "",
  lastName: "",
  birthDate: "",
  birthPlace: "",
  identityCardNo: "",
  validUntil: "",
  nationality: "",
};

const initialUserData = {
  baseInfo: {
    arrivalDate: "",
    departureDate: "",
    vehicleSize: "",
    spotName: "",
    numberplate: "",
    phone: "",
    phoneCode: "",
    mail: "",
    firstName: "",
    lastName: "",
    adultCount: "",
    childCount: "",
    petCount: "",
  },
  address: {
    area: "",
    areaCode: "",
    countryCode: "",
    streetAndHouseNumber: "",
    birthDate: "",
    birthPlace: "",
    identityCardNo: "",
    validUntil: "",
    nationality: "",
  },
  travellers: [],
  status: "new",
};

const UserDataContext = createContext<IUserDataContext>({} as IUserDataContext);

export const useUserData = () => {
  const context = React.useContext(UserDataContext);
  if (!context) {
    throw new Error(`useProfile must be used within a ProfileProvider`);
  }
  return context;
};

export const UserDataProvider: React.FC<any> = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const [getUserData, setUserData] = useState<IUserData>(initialUserData);
  const [verifyCodeStatus, setVerifyCodeStatus] = useState(false);
  const [openImageBackdrop, setOpenImageBackdrop] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);

  return (
    <UserDataContext.Provider
      value={{
        checked,
        setChecked,
        getUserData,
        setUserData,
        verifyCodeStatus,
        setVerifyCodeStatus,
        openImageBackdrop,
        setOpenImageBackdrop,
        selectedIndex,
        setSelectedIndex,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
