import React, { useState, createContext } from "react";

import { IBookmarksContext } from "interfaces/context";

const BookmarksContext = createContext<IBookmarksContext>({} as IBookmarksContext);

export const useBookmarks = () => {
  const context = React.useContext(BookmarksContext);
  if (!context) {
    throw new Error(`useBookmarks must be used within a BookmarksProvider`);
  }
  return context;
};

export const BookmarksProvider = ({ children }) => {
  const [bookmarksAll, setBookmarksAll] = useState<any>([]);
  const [changeFolder, setChangeFolder] = useState({});
  const [changeFolderForm, setChangeFolderForm] = useState(false);
  const [zoom, setZoom] = useState(4);
  const [selectedPlace, setSelectedPlace] = useState<any>({});
  const [infoOpen, setInfoOpen] = useState(false);
  const [centerlng, setCenterlng] = useState(15.3547264);
  const [centerlat, setCenterlat] = useState(47.1681664);
  const [bookmarksMap, setBookmarksMap] = useState<any>([]);
  const [itemId, setItemId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <BookmarksContext.Provider
      value={{
        bookmarksAll,
        setBookmarksAll,
        changeFolder,
        setChangeFolder,
        changeFolderForm,
        setChangeFolderForm,
        zoom,
        setZoom,
        selectedPlace,
        setSelectedPlace,
        infoOpen,
        setInfoOpen,
        centerlng,
        setCenterlng,
        centerlat,
        setCenterlat,
        bookmarksMap,
        setBookmarksMap,
        itemId,
        setItemId,
        dialogOpen,
        setDialogOpen,
      }}
    >
      {children}
    </BookmarksContext.Provider>
  );
};
