import React, { useState, createContext } from "react";

import { ITravellersContext } from "interfaces/context";

const TravellersContext = createContext<ITravellersContext>({} as ITravellersContext);

export const useTravellers = () => {
  const context = React.useContext(TravellersContext);
  if (!context) {
    throw new Error(`useTravellers must be used within a TravellersProvider`);
  }
  return context;
};

export const TravellersProvider = ({ children }) => {
  const [travellers, setTravellers] = useState<any>(null);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [savedTraveller, setSavedTraveller] = useState<boolean>(true);
  const [newTraveller, setNewTraveller] = useState<boolean>(false);

  return (
    <TravellersContext.Provider
      value={{
        travellers,
        setTravellers,
        expanded,
        setExpanded,
        savedTraveller,
        setSavedTraveller,
        newTraveller,
        setNewTraveller,
      }}
    >
      {children}
    </TravellersContext.Provider>
  );
};
