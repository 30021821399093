import type { CancelToken } from "axios";

import type {
  IGetAllSuppliersByParkingArealIdParams,
  ICreateOrderParams,
  IGetOrderParams,
  IUpdateOrderParams,
  IDeleteOrderParams,
  IGetAllOrdersByBookingIdParams,
  IGetAllProductsBySupplierParams,
} from "./domain";
import { ApiBaseClient } from "../api-base-client";
import { URL as BaseURL } from "../RequestOption";

const URLS = {
  // Supplier paths
  createSupplier: "/supplier/create",
  getSupplier: "/supplier/get",
  updateSupplier: "/supplier/update",
  deleteSupplier: "/supplier/delete",
  getAllSuppliersByParkingArealId: "/supplier/getAllByParkingAreal",

  // Order paths
  createOrder: "/order/create",
  getOrder: "/order/get",
  updateOrder: "/order/update",
  deleteOrder: "/order/delete",
  getAllOrdersByBookingId: "/order/getAllByBookingId",
  getAllOrdersBySupplierId: "/order/getAllBySupplierId",
  getAllOrdersByParkingArealId: "/order/getAllByParkingArealId",

  // Product paths
  createProduct: "/product/create",
  getProduct: "/product/get",
  updateProduct: "/product/update",
  deleteProduct: "/product/delete",
  getAllProductsByParkingArealId: "/product/getAllByParkingAreal",
  getAllProductsBySupplier: "/product/getAllProductsBySupplier",
};

export class OrdersApiService extends ApiBaseClient {
  constructor(baseURL = `${BaseURL}/api/private/user`) {
    super(baseURL);
  }

  async getAllSuppliersByParkingArealId(
    params: IGetAllSuppliersByParkingArealIdParams,
    cancelToken?: CancelToken
  ) {
    const { data } = await this.http.get(URLS.getAllSuppliersByParkingArealId, { params, cancelToken });
    return data;
  }

  // Order methods
  async createOrder(params: ICreateOrderParams, cancelToken?: CancelToken) {
    const { data } = await this.http.post(URLS.createOrder, params, { cancelToken });
    return data;
  }

  async getOrder(params: IGetOrderParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.getOrder, { params, cancelToken });
    return data;
  }

  async updateOrder(params: IUpdateOrderParams, cancelToken?: CancelToken) {
    const { data } = await this.http.put(URLS.updateOrder, undefined, { params, cancelToken });
    return data;
  }

  async deleteOrder(params: IDeleteOrderParams, cancelToken?: CancelToken) {
    const { data } = await this.http.delete(URLS.deleteOrder, { params, cancelToken });
    return data;
  }

  async getAllOrdersByBookingId(params: IGetAllOrdersByBookingIdParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.getAllOrdersByBookingId, { params, cancelToken });
    return data;
  }

  async getAllProductsBySupplierId(params: IGetAllProductsBySupplierParams, cancelToken?: CancelToken) {
    const { data } = await this.http.get(URLS.getAllProductsBySupplier, { params, cancelToken });
    return data;
  }
}
