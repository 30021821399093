import React, { useState, createContext } from "react";

import { IAreaDetailContext } from "interfaces/context";
const AreaDetailContext = createContext<IAreaDetailContext>({} as IAreaDetailContext);

export const useAreaDetailState = () => {
  const context = React.useContext(AreaDetailContext);
  if (!context) {
    throw new Error(`useAreaDetailState must be used within a AreaDetailProvider`);
  }
  return context;
};

export const AreaDetailProvider = ({ children }) => {
  const [totalRating, setTotalRating] = useState<number>(0);
  const [comment, setComment] = useState<any>(null);
  const [galerie, setGalerie] = React.useState<boolean>(true);
  const [uploadSuccess, setUploadSuccess] = React.useState(false);
  const [uploadError, setUploadError] = React.useState(false);
  const [bookmarkForm, setBookmarkForm] = React.useState<boolean>(false);
  const [expControl, setExpControl] = React.useState<boolean[]>([]);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [closestBakery, setClosestBakery] = React.useState<boolean>(false);
  const [infos, setInfos] = React.useState([]);
  const [touristInfoTab, setTouristInfoTab] = React.useState<number>(1);

  return (
    <AreaDetailContext.Provider
      value={{
        totalRating,
        comment,
        galerie,
        bookmarkForm,
        uploadSuccess,
        uploadError,
        expControl,
        totalComments,
        closestBakery,
        infos,
        touristInfoTab,
        setTotalRating,
        setComment,
        setGalerie,
        setBookmarkForm,
        setUploadSuccess,
        setUploadError,
        setExpControl,
        setTotalComments,
        setClosestBakery,
        setInfos,
        setTouristInfoTab,
      }}
    >
      {children}
    </AreaDetailContext.Provider>
  );
};
