import React, { useContext, useState, lazy, Suspense } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  CardActions,
  Button,
  IconButton,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { getDMS } from "helpers";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { StarBorder, FavoriteBorder, Share, Explore, Favorite } from "@material-ui/icons";
import { AuthContext } from "context/Auth";
import { useAreaDetailState } from "context/AreaDetail";
import { facilitiesCampingAreal, facilitiesParkingAreal } from "./tooltipLists";
import FacilityFactory from "./facilities";
import ImageGalleryBookable from "./imageGalleryBookable";
import ImageGalleryUnbookable from "./imageGalleryUnbookable";
import useStyles from "./style";
import RoomIcon from "@material-ui/icons/Room";

function MainCard({
  images,
  item,
  commentHandle,
  dist,
  openBookmarkForm,
  openCopyDialog,
  lat,
  lng,
  user,
  getUserArealEditToken,
  hearth,
}) {
  const { currentUser }: any = useContext(AuthContext);
  const { totalRating, totalComments } = useAreaDetailState();
  const [imageDescription, setImageDescription] = useState<any>(0);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card style={{ marginBottom: 18 }} key="mainCard">
      <CardContent>
        <div className={classes.imageGallery} id={"galerie"}>
          {item.bookable ? (
            <ImageGalleryBookable images={images} setImageDescription={setImageDescription} />
          ) : (
            <ImageGalleryUnbookable images={images} setImageDescription={setImageDescription} />
          )}
        </div>
        <Typography
          align="center"
          variant="subtitle2"
          style={{ marginTop: "-10px", paddingBottom: "10px", minHeight: "4em" }}
        >
          {images[imageDescription]["desc"] ? images[imageDescription]["desc"] : <span>&nbsp;&nbsp;</span>}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography align="left" variant="h5" component="h1" color="primary" style={{ fontWeight: 600 }}>
            {item.name}
          </Typography>
          <Rating
            size="large"
            name="mainItem"
            value={totalRating}
            readOnly
            precision={0.5}
            emptyIcon={<StarBorder fontSize="inherit" />}
          />
        </div>
        <div>
          <Typography
            align="right"
            color="primary"
            style={{ marginBottom: "-12px", cursor: "pointer" }}
            onClick={(event: any) => commentHandle(event)}
          >
            {`${totalComments} ${t("label_reviews")}`}
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <br />
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        style={{ paddingBottom: "5px", marginLeft: "10px" }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Typography align="left" style={{ paddingTop: "3px", paddingLeft: "5px" }} color="primary">
            {t("distances") + ":"}
          </Typography>
        </Grid>
        {item.distance !== undefined ? (
          <Grid item xs={8} sm={4} md={4} lg={3} style={{ display: "flex", marginLeft: "-10px" }}>
            <ListItemAvatar style={{ textAlignLast: "center" }}>
              <Explore
                color="primary"
                style={{
                  paddingTop: "3px",
                  textAlignLast: "center",
                }}
              />
            </ListItemAvatar>
            <ListItemText primary={t("destination") + ": " + +item.distance + " km"} />
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={8} sm={5} md={5} lg={6} style={{ display: "flex", marginLeft: "-20px" }}>
          <ListItemAvatar style={{ textAlignLast: "center" }}>
            <RoomIcon
              color="primary"
              style={{
                paddingTop: "3px",
                textAlignLast: "center",
              }}
            />
          </ListItemAvatar>
          <ListItemText primary={t("location") + ": " + dist(lat, lng, item.lat, item.lon) + " km"} />
        </Grid>
      </Grid>
      <Divider />

      {facilitiesCampingAreal.map((facility, index) => {
        return (
          <React.Fragment key={index + "_f"}>
            <FacilityFactory state={facility} item={item} />
            <Divider />
          </React.Fragment>
        );
      })}
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        style={{ paddingTop: "9px", marginLeft: "10px" }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Typography
            align="left"
            style={{
              paddingTop: "3px",
              paddingRight: "16px",
              paddingLeft: "5px",
              maxWidth: "%15",
            }}
            color="primary"
          >
            {t("gps")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9} style={{ display: "flex", marginLeft: "-20px" }}>
          <ListItemAvatar style={{ textAlignLast: "center" }}>
            <RoomIcon
              color="primary"
              style={{
                paddingTop: "3px",
                textAlignLast: "center",
              }}
            />
          </ListItemAvatar>
          {item.GPSA !== "" &&
          item.GPSB !== "" &&
          item.sekunden1 !== "" &&
          item.minute1 !== "" &&
          item.grad1 !== "" &&
          item.sekunden2 !== "" &&
          item.minute2 !== "" &&
          item.grad2 !== "" &&
          item.GPSA !== undefined &&
          item.GPSB !== undefined &&
          item.sekunden1 !== undefined &&
          item.minute1 !== undefined &&
          item.grad1 !== undefined &&
          item.sekunden2 !== undefined &&
          item.minute2 !== undefined &&
          item.grad2 !== undefined ? (
            <ListItemText
              primary={
                item.grad1 +
                "° " +
                item.minute1 +
                "' " +
                item.sekunden1.slice(0, 7) +
                '" ' +
                item.GPSA +
                "  ,  " +
                item.grad2 +
                "° " +
                item.minute2 +
                "' " +
                item.sekunden2.slice(0, 7) +
                '" ' +
                item.GPSB
              }
            />
          ) : (
            <ListItemText primary={getDMS(item.lat, "lat") + " , " + getDMS(item.lon, "long")} />
          )}
        </Grid>
      </Grid>
      <Divider id={"comment"} />
      <CardActions className={classes.cardActions}>
        <Grid container spacing={1}>
          <Grid container xs={10} md={6} spacing={1}>
            {currentUser && (
              <Grid item>
                <Button
                  // onClick={(e) =>
                  //   (window.location.href = `http://maps.google.com/maps?saddr=${lat},${lng}&daddr=${item.lat},${item.lon}`)
                  // }
                  onClick={() => getUserArealEditToken(item.id)}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  {t("update_data")}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                onClick={(e) =>
                  (window.location.href = `http://maps.google.com/maps?saddr=${lat},${lng}&daddr=${item.lat},${item.lon}`)
                }
                variant="contained"
                color="primary"
                disableElevation
                style={{ marginLeft: "auto" }}
              >
                {t("start_navigation")}
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={2} md={6} justifyContent="flex-end">
            {currentUser &&
              user &&
              (!hearth ? (
                <Grid>
                  <IconButton onClick={openBookmarkForm} size="small" color="primary">
                    <FavoriteBorder />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item>
                  <IconButton onClick={openBookmarkForm} size="small" color="primary">
                    <Favorite />
                  </IconButton>
                </Grid>
              ))}
            <Grid item>
              <IconButton size="small" color="primary" onClick={openCopyDialog}>
                <Share />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default MainCard;
