import { Grid, Typography } from "@material-ui/core";

export default function showRotateDeviceText(t) {
  return (
    <Grid item xs={12} style={{ paddingTop: "20px" }}>
      <Typography variant="body1" style={{ color: "#00713C", fontWeight: "bold", textAlign: "center" }}>
        {t("rotate_device")}
      </Typography>
    </Grid>
  );
}
