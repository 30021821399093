import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import type { IFetchPrivateSearchParams } from "../domain";
import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";

export const usePrivateSearch = <TData = any, TError = any>(
  params: IFetchPrivateSearchParams,
  queryOptions: UseQueryProps<TData, TError> = {}
) => {
  const { searchApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      searchApiService.createCancellableRequest((cancelToken) =>
        searchApiService.getPrivateSearch(params, cancelToken)
      ),
    [searchApiService, params]
  );

  return useQuery(QueryKeys.privateSearch(), queryFn, queryOptions);
};
