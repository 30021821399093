import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";

export const useUserTravellers = <TData = any, TError = any>(
  queryOptions: UseQueryProps<TData, TError> = {}
) => {
  const { travellersApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      travellersApiService.createCancellableRequest((cancelToken) =>
        travellersApiService.getUserTravellers(cancelToken)
      ),
    [travellersApiService]
  );

  return useQuery(QueryKeys.userTravellers(), queryFn, queryOptions);
};
