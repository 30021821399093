/***
 * Endpoint paths of the public rest api
 */
export abstract class PublicRestApiPath {
  public static parkingAreals = "/parkingAreals";
  public static parkingAreal = "/parkingAreal";
  public static parkingArealWithId = "/parkingAreal/:parkingArealId";
  public static parkingArealComments = "/parkingAreal/:parkingArealId/comments";
  public static parkingArealInfo = "/parkingAreal/:parkingArealId/info";
  public static parkingArealInfoTypes = "/parkingAreal/:parkingArealId/infoTypes";
  public static parkingArealCommentsCouch = "/parkingAreal/:parkingArealId/commentsCouch";
  public static parkingArealCommentsUserCouch = "/parkingAreal/commentUserCouch";
  public static parkingArealSearch = "/parkingArealSearch";
  public static parkingArealAsDb = "/parkingAreals/db";
  public static user = "/user";
  public static mail = "/fair/mail";
  public static payBack = "/payback";
  public static receipt = "/payback/receipt";
  public static receiptPublic = "/booking/:bookingId/receipt";
  public static preBookingSvg = "prebooking/svg";
  public static preBookingPhoneVerify = "prebooking/verify";
  public static preBookingCreate = "prebooking/create";
  public static preBookingPrice = "prebooking/price";
  public static preBookingPrepaymentConfirm = "prebooking/prepayment/confirm";
  public static placeOccupancy = "placeOccupancy";
}
