import { useCallback, useContext } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

import { apiServiceContext } from "../../api-service-context";
import { EntityType } from "../query-keys";

export const useDeleteVehicle = (options?: UseMutationOptions) => {
  const { vehicleApiService } = useContext(apiServiceContext);
  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    () =>
      vehicleApiService.createCancellableRequest((cancelToken) =>
        vehicleApiService.deleteVehicle(cancelToken)
      ),
    [vehicleApiService]
  );

  const mutation = useMutation<any, any, any>(mutationFn, {
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(EntityType.USER_VEHICLE);
      options?.onSuccess?.(...args);
    },
  });

  return mutation;
};
