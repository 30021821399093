import React, { Suspense, useEffect, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import firebase from "firebase";

import {
  CssBaseline,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Fade,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { VerifyCodeDialog, UserForm, VehicleAndTravellersDialog } from "components/user/profile";
import { GoogleIcon } from "components/icons/GoogleIcon";
import { FirebaseAuth } from "services/firebase/Firebase";
// import { useUserProfile } from "rest";
import { useProfile } from "context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#7ed321",
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  })
);

const UserProfile: React.FC<any> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [alreadyLinked, setAlreadyLinked] = useState(false);
  const [linked, setLinked] = useState(false);
  // const [loading, setLoading] = useState(true);

  const { checked, setProfile, openImageBackdrop, setOpenImageBackdrop } = useProfile();

  // useEffect(() => {
  // setOpenImageBackdrop(true);
  // console.log(profile);
  // setProfile(profile);
  //     setLoading(false);
  // }, []);

  // useUserProfile({
  //   onSuccess: (results) => {
  //     // setProfile(results);
  //     setOpenImageBackdrop(false);
  //     setLoading(false);
  //   },
  //   onError: (error) => {
  //     console.error(error);
  //   },
  // });

  const linkAccount = (type: string) => {
    if (type === "google") {
      let googleProvider = new firebase.auth.GoogleAuthProvider();
      if (FirebaseAuth.currentUser != null) {
        FirebaseAuth.currentUser
          .linkWithPopup(googleProvider)
          .then(() => {
            setLinked(true);
            setTimeout(() => {
              setLinked(false);
            }, 5000);
          })
          .catch((error) => {
            setAlreadyLinked(true);
            setTimeout(() => {
              setAlreadyLinked(false);
            }, 5000);

            console.log(error);
          });
      }
    } else if (type === "apple") {
      let appleProvider = new firebase.auth.OAuthProvider("apple.com");
      if (FirebaseAuth.currentUser != null) {
        FirebaseAuth.currentUser
          .linkWithPopup(appleProvider)
          .then((result) => {
            setLinked(true);
            setTimeout(() => {
              setLinked(false);
            }, 5000);
          })
          .catch((error) => {
            setAlreadyLinked(true);
            setTimeout(() => {
              setAlreadyLinked(false);
            }, 5000);
          });
      }
    }
  };

  // if (!loading) {
  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={openImageBackdrop}
        onClick={() => setOpenImageBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />

      <Snackbar open={checked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="success"> {t("profile_submit_success")}</Alert>
      </Snackbar>

      <Snackbar open={linked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="success">{t("your_account_linked")}</Alert>
      </Snackbar>

      <Snackbar open={alreadyLinked} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="warning">{t("your_account_already_linked")}</Alert>
      </Snackbar>

      {/* <VehicleAndTravellersDialog profilePageProps={props} /> */}

      <VerifyCodeDialog />

      <Suspense fallback={<h1>{t("label_loading_profile")} </h1>}>
        <Fade in={true}>
          <UserForm />
        </Fade>
        <br />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle2">{t("profile_info_text_1")}</Typography>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
  // } else {
  //   return (
  //     <>
  //       <CssBaseline />
  //       <Backdrop className={classes.backdrop} open={openImageBackdrop}>
  //         <CircularProgress color="inherit" />
  //       </Backdrop>
  //     </>
  //   );
  // }
};

export const Profile = withTranslation()(UserProfile);
