import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  useMediaQuery,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import SpotCard from "../SpotCard";
import { useTranslation } from "react-i18next";
import { useAreaDetailState } from "context/AreaDetail";

const useStyles = makeStyles(() =>
  createStyles({
    closestBakeryButtons: {
      marginBottom: 24,
      display: "flex",
      justifyContent: "space-evenly",
    },
  })
);

function TouristInfoModal({ item }) {
  const { t } = useTranslation();
  const { closestBakery, setClosestBakery, infos, touristInfoTab, setInfos, setTouristInfoTab } =
    useAreaDetailState();

  const classes = useStyles();
  const lighter = useMediaQuery("(min-width:630px)");

  const closeClosestBakery = () => {
    setClosestBakery(false);
  };

  const selected = (e) => {
    setInfos(
      item.info.filter(function (el) {
        return el.mode === e;
      })
    );

    setTouristInfoTab(e);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={closeClosestBakery}
      aria-labelledby="customized-dialog-title"
      open={closestBakery}
    >
      <DialogContent style={{ minHeight: "80vh", height: "80vh" }}>
        <Typography
          variant="h4"
          style={
            item?.bookable
              ? { paddingBottom: "20px", color: "#BCCF00" }
              : { paddingBottom: "20px", color: "#0b429c" }
          }
        >
          {t("turist_info")}
        </Typography>

        {lighter ? (
          <div className={classes.closestBakeryButtons}>
            <Button
              variant="contained"
              color={touristInfoTab !== 1 ? "secondary" : "primary"}
              onClick={(e) => selected(1)}
            >
              {t("shopping")}
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 4 ? "secondary" : "primary"}
              onClick={(e) => selected(4)}
            >
              {t("restaurant")}
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 3 ? "secondary" : "primary"}
              onClick={(e) => selected(3)}
            >
              {t("current")}
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 5 ? "secondary" : "primary"}
              onClick={(e) => selected(5)}
            >
              {t("health")}
            </Button>
            <Button
              variant="contained"
              color={touristInfoTab !== 2 ? "secondary" : "primary"}
              onClick={(e) => selected(2)}
            >
              {t("other")}
            </Button>
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.closestBakeryButtons}>
              <Button
                variant="contained"
                color={touristInfoTab !== 1 ? "secondary" : "primary"}
                onClick={(e) => selected(1)}
              >
                {t("shopping")}
              </Button>
              <Button
                variant="contained"
                color={touristInfoTab !== 2 ? "secondary" : "primary"}
                onClick={(e) => selected(2)}
              >
                {t("other")}
              </Button>
              <Button
                variant="contained"
                color={touristInfoTab !== 3 ? "secondary" : "primary"}
                onClick={(e) => selected(3)}
              >
                {t("current")}
              </Button>
            </div>
            <div className={classes.closestBakeryButtons}>
              <Button
                variant="contained"
                color={touristInfoTab !== 4 ? "secondary" : "primary"}
                onClick={(e) => selected(4)}
              >
                {t("restaurant")}
              </Button>
              <Button
                variant="contained"
                color={touristInfoTab !== 5 ? "secondary" : "primary"}
                onClick={(e) => selected(5)}
              >
                {t("health")}
              </Button>
            </div>
          </React.Fragment>
        )}

        {item && infos?.length > 0 ? (
          infos.map((item: any, index: number) => (
            <React.Fragment key={index + "b"}>
              <SpotCard item={item} />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <Typography component="h6" variant="h6">
              {t("no_info_this_category")}
            </Typography>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeClosestBakery} color="primary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TouristInfoModal;
