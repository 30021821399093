import { useCallback, useContext } from "react";
import { useQuery } from "react-query";

import { UseQueryProps } from "../../interfaces";
import { apiServiceContext } from "../../api-service-context";
import { QueryKeys } from "../query-keys";

export const useBookingOfUserHistory = <TData = any, TError = any>(
  queryOptions: UseQueryProps<TData, TError> = {}
) => {
  const { bookingApiService } = useContext(apiServiceContext);
  const queryFn = useCallback(
    () =>
      bookingApiService.createCancellableRequest((cancelToken) =>
        bookingApiService.getBookingHistoryOfUser(cancelToken)
      ),
    [bookingApiService]
  );
  return useQuery(QueryKeys.bookingHistoryOfUser(), queryFn, queryOptions);
  // return useQuery(QueryKeys.bookingHistoryOfUser(), queryFn, queryOptions);
};
