import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Hidden, Typography } from "@material-ui/core";
import showRotateDeviceText from "components/showRotateDevicetext";

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
    lineHeight: 1,
  },
  search: {
    height: 350,
    margin: "64px 0px 24px",
    position: "relative",
    textAlign: "center",
  },
  borderBottom: {
    borderBottom: "none",
  },
  borderTop: {
    borderTop: "solid",
  },
});

function ccyFormat(num: number) {
  return `${num?.toFixed(2)?.replace(/\./g, ",")}`;
}

export default function BillTable(params: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orientation, setOrientation] = React.useState<any>(false);

  useEffect(() => {
    if (typeof window.screen.orientation !== "undefined") {
      setOrientation(true);
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        {params.blocked ? (
          <Grid item>
            <Alert severity="error">
              <AlertTitle>{t("mistake")}</AlertTitle>
              {t("parking_spot_already_rez")} <strong>{t("go_back_and_choose_another_park")}</strong>
            </Alert>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Hidden mdUp>
                {orientation === true
                  ? window.screen.orientation.type.includes("landscape")
                    ? ""
                    : showRotateDeviceText(t)
                  : showRotateDeviceText(t)}
              </Hidden>
            </Grid>

            <Grid item>
              <Paper elevation={0}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        size={"small"}
                        align="center"
                        colSpan={7}
                        style={{ fontSize: "x-large", fontWeight: "bold", borderBottom: "none" }}
                      >
                        {t("label_booking_info") + ":"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        size={"small"}
                        align="center"
                        colSpan={7}
                        style={{ fontSize: "large", borderBottom: "none" }}
                      >
                        {params.priceList.parkAreal.name +
                          " - " +
                          params.priceList.parkAreal.address.streetAndHouseNumber +
                          " - " +
                          params.priceList.parkAreal.address.areaCode +
                          " " +
                          params.priceList.parkAreal.address.area}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        size={"small"}
                        align="center"
                        colSpan={7}
                        style={{ fontSize: "x-large", fontWeight: "bold", borderBottom: "none" }}
                      >
                        {`Stellplatznummer: ${params.priceList.spot} - Kennzeichen: ${params.priceList.vehicleNumberPlate}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell size={"small"} align="center" colSpan={7} style={{ fontSize: "large" }}>
                        {`Buchungsdatum: ` +
                          ("0" + new Date().getDate()).slice(-2) +
                          "/" +
                          ("0" + (new Date().getMonth() + 1)).slice(-2) +
                          "/" +
                          new Date().getFullYear()}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"start"} style={{ borderTop: "solid" }}>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {t("date")}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"Leistung"}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"Anz."}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"Einzelpreis"}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {" "}
                        {"summe"}
                      </TableCell>
                      {/* <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("date")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("label_price_extra_person")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("child")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("dog")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("tourist_tax")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("parking_space")}
                    </TableCell>
                    <TableCell align="left" className={classes.borderBottom}>
                      {" "}
                      {t("total")}
                    </TableCell> */}
                    </TableRow>
                    {params.priceList.tablePrice.map((row: any, ind: any) => (
                      <TableRow key={ind}>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.date}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.count}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.perItem}
                        </TableCell>
                        <TableCell align="left" className={classes.borderBottom}>
                          {row.gross}
                        </TableCell>
                        {/* <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(row.price)}
                      </TableCell>
                      <TableCell align="left" className={classes.borderBottom}>
                        {ccyFormat(row.sum)}
                      </TableCell> */}
                      </TableRow>
                    ))}
                    <TableRow style={{ borderBottom: "solid" }} />
                    {params.priceList.tax.map((row: any, ind: any) => (
                      <>
                        <TableRow style={{ borderBottom: "ridge", lineHeight: "8px" }}>
                          <TableCell style={{ fontWeight: "bold" }} />
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {`${row.tax} ,0%`} {t("vat")}
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {row.diff}
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {row.net}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                    <TableRow style={{ borderBottom: "solid", borderTop: "ridge" }}>
                      <TableCell style={{ fontSize: "large", fontWeight: "bold" }}>
                        {t("total_amount")}:
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}></TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}></TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}></TableCell>
                      <TableCell align="right" style={{ fontSize: "large", fontWeight: "bold" }}>
                        {params.priceList.gross}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {params.parkAreal.isPrePayment && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ paddingTop: "20px", textAlign: "center", color: "crimson" }}
                  >
                    Wir bitten Sie, uns eine Anzahlung in Höhe von 8,00 Euro zu leisten.
                  </Typography>
                )}
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
