import React, { useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useQueryClient } from "react-query";
import { Room as RoomIcon } from "@material-ui/icons";
import {
  CardHeader,
  CssBaseline,
  Card,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Collapse,
  Grid,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

import { useBookingOfUser, useBookingOfUserHistory, useDeleteBooking } from "rest";
import { IBooking } from "interfaces/booking";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  ok: {
    backgroundColor: "#c4dc9d",
    borderBottom: "5px solid white",
  },
  no: {
    backgroundColor: "#f9c5af",
    borderBottom: "5px solid white",
  },
});

const StornierenImpl: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [getBookings, setBookings] = useState<IBooking[]>([]);
  const [close, setClose] = useState(false);
  const [closeX, setCloseX] = useState(false);
  const [ind, setInd] = useState("");
  const [parkingArealId, setParkingArealId] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedE, setCheckedE] = useState(false);

  useBookingOfUserHistory({
    onSuccess: (bookings) => {
      const books = bookings
        .filter((item: any) => new Date(item.endDate).getTime() - new Date().getTime() > 0)
        .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());
      const filteredBooks = books.filter((item) => item.paymentStatus !== "paid");
      setBookings(filteredBooks);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { mutate: deleteBooking } = useDeleteBooking({
    onSuccess: () => {
      setClose(false);

      queryClient.invalidateQueries("booking_history_of_user");
      setChecked(true);
      setTimeout(() => setChecked(false), 5000);
    },
    onError: () => {
      setCheckedE(true);
      setTimeout(() => {
        setCheckedE(false);
      }, 5000);
    },
  });

  const active = (id, status, parkingArealIdX) => {
    if (status === "active") {
      setInd(id);
      setClose(true);
    } else {
      setCloseX(true);
      setParkingArealId(parkingArealIdX);
    }
  };

  const del = () => {
    deleteBooking({ id: ind });
  };

  const handleCloseDialog = () => {
    setClose(false);
  };
  const handleCloseDialogX = () => {
    setCloseX(false);
  };

  const Row = ({ item }: { item: IBooking }) => {
    return (
      <TableRow className={item.status === "active" ? classes.ok : classes.no}>
        <TableCell component="th" scope="row">
          {new Date(item.createdAt).toLocaleDateString("de-DE")}
        </TableCell>
        <TableCell align="left">{item.parkingArealName}</TableCell>
        <TableCell align="right">{new Date(item.startDate).toLocaleDateString("de-DE")}</TableCell>
        <TableCell align="right">{new Date(item.endDate).toLocaleDateString("de-DE")}</TableCell>
        <TableCell align="right">{item.parkingSpotName}</TableCell>
        <TableCell align="right">{item.PIN}</TableCell>
        <TableCell align="center">
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Switch
                checked={item.status === "active" ? true : false}
                onChange={() => active(item.id, item.status, item.parkingArealId)}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
                color="primary"
              />
            }
            label=""
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Dialog
        open={close}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("cancel_booking")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("cancel_booking_confirm")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t("no")}
          </Button>
          <Button onClick={del} color="primary" autoFocus>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={closeX}
        onClose={handleCloseDialogX}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle1" gutterBottom>
                  {t("cannot_revert_cancel_booking")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("must_make_new_reservation")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} container alignItems={"flex-end"} justifyContent={"flex-end"}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <IconButton
                      edge="end"
                      color="primary"
                      onClick={() => navigate(`/areal/${parkingArealId}`)}
                    >
                      <RoomIcon />
                    </IconButton>
                  }
                  label=""
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Collapse in={checked}>
        <Alert severity="success">{t("succesfully_cancelled_booking")}</Alert>
      </Collapse>
      <Collapse in={checkedE}>
        <Alert severity="error">{t("problem_message_try_again")}</Alert>
      </Collapse>
      <CssBaseline />
      <Card style={{ marginBottom: 18 }} id={"bookingpast"}>
        <CardHeader
          style={{ textAlign: "center" }}
          title={
            <Typography variant="h6" component="h4">
              <span>{t("promotion_message_camp")}</span>
              <br />
              <span style={{ color: "red" }}> {t("cancel_free_of_charge")} </span>
            </Typography>
          }
        />

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("date")}</TableCell>
                <TableCell align="left">{t("parking_space")}</TableCell>
                <TableCell align="right">{t("arrival")}</TableCell>
                <TableCell align="right">{t("departure")}</TableCell>
                <TableCell align="right">{t("place_number")}</TableCell>
                <TableCell align="right">{t("pin")}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getBookings.map(
                (item) => new Date(item.endDate).getTime() - new Date().getTime() > 0 && <Row item={item} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export const Stornieren = withTranslation()(StornierenImpl);
