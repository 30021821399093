export const campCardActivities = [
  { title: "Radfahren", name: "cycle" },
  { title: "Schwimmen", name: "swim" },
  { title: "Schwimmbad", source: "schwimmbad", name: "poolswim", alt: "Schwimmbad" },
  { title: "Wandern", name: "hiking", alt: "Wandern" },
  { title: "Angein", name: "fishing", alt: "Angeln" },
  { title: "Golf", name: "golf", alt: "Golf" },
  { title: "Tennis", name: "tennis", alt: "Tennis" },
  { title: "Strand", source: "strand", name: "beach", alt: "Strand" },
  { title: "Ski", name: "snowski", alt: "Ski" },
  { title: "Schneewanderung", name: "snowhike", alt: "Langlauf" },
  { title: "Klettern", name: "climbing", alt: "Klettern" },
  { title: "Zahnradbahn", source: "zahn", name: "railway", alt: "Zahnradbahn" },
  { title: "Schiffahrt", source: "schiff", name: "ship", alt: "Schiffahrt" },
  { name: "br" },
  { title: "Aussichtspunkt", source: "aussicht", name: "view", alt: "Aussichtspunkt" },
  { title: "Burg / Festung", source: "burg", name: "castle", alt: "Burg / Festung" },
  { title: "Historische Gebäude", source: "hist", name: "history", alt: "Historische Gebäude" },
  { title: "Museum", name: "museum", alt: "Museum" },
  { title: "Kirche", source: "kirche", name: "church", alt: "Kirche" },
  { title: "Moschee", source: "moschee", name: "mosque", alt: "Moschee" },
  { title: "Synagoge", name: "synagoge", alt: "Synagoge" },
  { title: "Fußgängerzone", name: "zone", alt: "Fußgängerzone" },
  { title: "Einkaufszentrum", source: "einkauf", name: "shop", alt: "Einkaufszentrum" },
  { title: "Kino / Thater", source: "kino", name: "movietheater", alt: "Kino / Thater" },
  { title: "Zoo", name: "zoo", alt: "Zoo" },
  { title: "Stadion / Arena", name: "stadion", alt: "Stadion / Arena" },
];

export const campCardServices = [
  {
    name: "electricity",
    title: "Strom",
  },
  {
    name: "water",
    title: "Wasser",
  },
  {
    name: "shower",
    title: "Dusche",
  },
  {
    name: "toilet",
    title: "WC",
  },
  {
    name: "handicappedAccessible",
    title: "Behindertengerecht",
    source: "wheelchair",
  },
  {
    name: "playground",
    title: "Kinderspielplatz",
  },
  {
    name: "chemistryWC",
    title: "Chemie-WC",
    source: "chemistrywc",
  },
  {
    name: "automat",
    title: "Chemie-WC Automat",
  },
  {
    name: "disposal",
    title: "Bodenentsorgung",
  },
  {
    name: "rubbish",
    title: "Müll",
  },
  {
    name: "wifi",
    title: "WLAN",
  },
  {
    name: "laundry",
    title: "Wasch-Trockenmöglichkeit",
    source: "machine",
  },
  {
    name: "pets",
    title: "Hund",
  },
];

export const defaultFilters = {
  electricity: false,
  water: false,
  toilet: false,
  shower: false,
  rentalBathrooms: false,
  wifi: false,
  handicappedAccessible: false,
  pets: false,
  dogshower: false,
  rubbish: false,
  chemistryWC: false,
  disposal: false,
  automat: false,
  waterOnSite: false,
  playground: false,
  babyChangingRoom: false,
  washingMachine: false,
  laundry: false,
  wine: false,
  overSize: false,
  farm: false,
  caravanAllowed: false,
  cycle: false,
  poolswim: false,
  swim: false,
  hiking: false,
  fishing: false,
  rafting: false,
  diving: false,
  golf: false,
  tennis: false,
  horsebackRiding: false,
  beach: false,
  skiing: false,
  snowhike: false,
  climbing: false,
  paragliding: false,
  balloonRide: false,
  naturist: false,
  thermalBath: false,
  waterPark: false,
  lakeSwimming: false,
  mobilehome: false,
  flats: false,
  campingBarrel: false,
  cabin: false,
  caravan: false,
  tent: false,
  treehouse: false,
  trailer: false,
  adac: false,
  dcc: false,
  cheque: false,
  campingkey: false,
  ccicss: false,
  occ: false,
  tcs: false,
  acsi: false,
  camp: true,
  park: true,
};

export const FittingsList = [
  "Strom",
  "Wasser",
  "WC",
  "Dusche",
  "Mietbäder",
  "WLAN",
  "Barrierefrei",
  "Hunde erlaubt",
  "Hundedusche",
  "Müllentsorgung",
  "Kassettenentsorgung",
  "Bodenentsorgung",
  "Entsorgungsautomat",
  "Wasser am Platz",
  "Abwasser am Platz",
  "TV/Anschluss",
  "Kinderspielplatz",
  "Kinderspielraum",
  "Kinder/Animation",
  "Wickelraum",
  "Aufenthaltsraum",
  "Spülmaschine",
  "Waschmaschine",
  "Wäschetrockner",
  "Trockner/Raum",
  "Wellness",
  "Sauna",
  "Massage",
  "Restaurant",
  "Kiosk",
  "Supermarkt",
  "Bäckerei",
  "Kaffee",
  "Grillplatz",
  "Wintercamping",
  "Mehrsprachig",
  "Weingut",
  "Biergarten",
  "Überlänge",
  "Bauernhof",
  "Wohnwagen Erlaubt",
];

export const FittingsListFilter = [
  { name: "Strom", id: 0 },
  { name: "Wasser", id: 1 },
  { name: "WC", id: 2 },
  { name: "Dusche", id: 3 },
  { name: "Mietbäder", id: 4 },
  { name: "WLAN", id: 5 },
  { name: "Barrierefrei", id: 6 },
  { name: "Hunde erlaubt", id: 7 },
  { name: "Hundedusche", id: 8 },
  { name: "Müllentsorgung", id: 9 },
  { name: "Kassettenentsorgung", id: 10 },
  { name: "Bodenentsorgung", id: 11 },
  { name: "Entsorgungsautomat", id: 12 },
  // {name: "Wasser am Platz", id:13},
  // {name: "Abwasser am Platz", id:14},
  // {name: "TV/Anschluss", id:15},
  { name: "Kinderspielplatz", id: 16 },
  // { name: "Kinderspielraum", id: 17 },
  // {name: "Kinder/Animation", id:18},
  { name: "Wickelraum", id: 19 },
  // { name: "Aufenthaltsraum", id: 20 },
  // {name: "Spülmaschine", id:21},
  { name: "Waschmaschine", id: 22 },
  { name: "Wäschetrockner", id: 23 },
  // {name: "Trockner/Raum", id:24},
  // { name: "Wellness", id: 25 },
  // {name: "Sauna", id:26},
  // {name: "Massage", id:27},
  // {name: "Restaurant", id:28},
  // {name: "Kiosk", id:29},
  // {name: "Supermarkt", id:30},
  // {name: "Bäckerei", id:31},
  // {name: "Kaffee", id:32},
  // {name: "Grillplatz", id:33},
  // { name: "Wintercamping", id: 34 },
  // {name: "Mehrsprachig", id:35},
  { name: "Weingut", id: 36 },
  // {name: "Biergarten", id:37},
  { name: "Überlänge", id: 38 },
  { name: "Bauernhof", id: 39 },
  { name: "Wohnwagen Erlaubt", id: 40 },
];

export const ActivitiesList = [
  "Radfahren",
  "Mountainbiken",
  "Schwimmen",
  "Schwimmbad",
  "Wandern",
  "Angeln",
  "Paddeln",
  "Rafting",
  "Segeln",
  "Tauchen",
  "Golf",
  "Tennis",
  "Skateboard",
  "Volleyball",
  "Reiten",
  "Strand",
  "Federball",
  "Boccia",
  "Tischtennis",
  "Skifahren",
  "Langlauf",
  "Klettern",
  "Seilbahn",
  "Zahnradbahn",
  "Schifffahrt",
  "Paragleiten",
  "Ballonfahrt",
  "Safaritour",
  "FKK",
  "Therme",
  "Kanufahren",
  "Mini Golf",
  "Erlebnisbad",
  "Badesee",
];

export const ActivitiesListFilter = [
  { name: "Radfahren", id: 0 },
  //{name: "Mountainbiken", id: 1},
  { name: "Schwimmen", id: 2 },
  { name: "Schwimmbad", id: 3 },
  { name: "Wandern", id: 4 },
  { name: "Angeln", id: 5 },
  //{name: "Paddeln", id: 6},
  { name: "Rafting", id: 7 },
  //{name: "Segeln", id: 8},
  { name: "Tauchen", id: 9 },
  { name: "Golf", id: 10 },
  { name: "Tennis", id: 11 },
  // {name: "Skateboard", id: 12},
  // {name: "Volleyball", id: 13},
  { name: "Reiten", id: 14 },
  { name: "Strand", id: 15 },
  // {name: "Federball", id: 16},
  // {name: "Boccia", id: 17},
  // {name: "Tischtennis", id: 18},
  { name: "Skifahren", id: 19 },
  { name: "Langlauf", id: 20 },
  { name: "Klettern", id: 21 },
  // {name: "Seilbahn", id: 22},
  // {name: "Zahnradbahn", id: 23},
  // {name: "Schifffahrt", id: 24},
  { name: "Paragleiten", id: 25 },
  { name: "Ballonfahrt", id: 26 },
  // {name: "Safaritour", id: 27},
  { name: "FKK", id: 28 },
  { name: "Therme", id: 29 },
  // { name: "Kanufahren", id: 30 },
  // { name: "Mini Golf", id: 31 },
  { name: "Erlebnisbad", id: 32 },
  { name: "Badesee", id: 33 },
];

export const RabattListFilter = [
  { name: "ACSI", id: 0 },
  { name: "ADAC", id: 1 },
  { name: "Camping Key", id: 2 },
  { name: "CCI/CCS", id: 3 },
  { name: "DCC", id: 4 },
  { name: "ÖCC", id: 5 },
  { name: "TCS", id: 6 },
  { name: "Campingcheque", id: 7 },
];

export const RabattNames = {
  acsi: "ACSI",
  adac: "ADAC",
  campingkey: "Camping Key",
  ccicss: "CCI/CCS",
  dcc: "DCC",
  occ: "ÖCC",
  tcs: "TCS",
  cheque: "Campingcheque",
};

export const RabattList = ["ACSI", "ADAC", "Camping Key", "CCI/CCS", "DCC", "ÖCC", "TCS", "Campingcheque"];

export const RabattListEn = ["acsi", "adac", "campingkey", "ccicss", "dcc", "occ", "tcs", "cheque"];

export const SightseeingList = [
  "Aussichtspunkt",
  "Burg/Festung",
  "Historische Gebäude",
  "Museum",
  "Kirche",
  "Moschee",
  "Synagoge",
  "Fußgängerzone",
  "Einkaufszentrum",
  "Kino/Theater",
  "Zoo",
  "Stadion",
];

export const TransportList = ["Bus", "Strassenbahn", "Taxi", "Zug", "Schiff"];

export const ServiceList = [
  "Brötchenservice",
  "Tourist/Info",
  "Gas",
  "Friseur",
  "Apotheke",
  "Post",
  "Geldautomat",
  "Wäscheservice",
  "Fahrradverleih",
  "Fahrradreparatur",
  "Autoverleih",
  "Werkstatt",
  "Bootsverleih",
  "Slipanlage",
  "Tankstelle",
  "Autofrei",
];

export const RentList = [
  "Mobilheim",
  "Appartments",
  "Campingfass",
  "Hütte",
  "Wohnwagen",
  "Zelt",
  "Baumhaus",
  "Bauwagen",
  "Mietbad",
];

export const RentListFilter = [
  { name: "Mobilheim", id: 0 },
  { name: "Appartments", id: 1 },
  { name: "Campingfass", id: 2 },
  { name: "Hütte", id: 3 },
  { name: "Wohnwagen", id: 4 },
  { name: "Zelt", id: 5 },
  { name: "Baumhaus", id: 6 },
  { name: "Bauwagen", id: 7 },
  { name: "Mietbad", id: 8 },
];

export const ActivitiesListEn = [
  "cycle",
  "mountainBiking",
  "poolswim",
  "swim",
  "hiking",
  "fishing",
  "paddling",
  "rafting",
  "sailing",
  "diving",
  "golf",
  "tennis",
  "snowski",
  "volleyball",
  "horsebackRiding",
  "beach",
  "badminton",
  "boccia",
  "tableTennis",
  "skiing",
  "snowhike",
  "climbing",
  "railway",
  "cogRailway",
  "ship",
  "paragliding",
  "balloonRide",
  "safariTour",
  "naturist",
  "thermalBath",
  "canoe",
  "miniGolf",
  "waterPark",
  "lakeSwimming",
];

export const FittingsListEn = [
  "electricity",
  "water",
  "toilet",
  "shower",
  "rentalBathrooms",
  "wifi",
  "handicappedAccessible",
  "pets",
  "dogShower",
  "rubbish",
  "chemistryWC",
  "disposal",
  "automat",
  "waterOnSite",
  "sewageOnSite",
  "TVconnection",
  "playground",
  "childrenPlayroom",
  "childrenAnimation",
  "babyChangingRoom",
  "recreationRoom",
  "dishwasher",
  "washingMachine",
  "laundry",
  "dryerRoom",
  "wellness",
  "sauna",
  "massage",
  "restaurant",
  "kiosk",
  "telmarkt",
  "bakery",
  "coffee",
  "barbecue",
  "winterCamping",
  "multilingual",
  "wine",
  "beerGarden",
  "overSize",
  "farm",
  "caravanAllowed",
];

export const RentListEn = [
  "mobilehome",
  "flats",
  "campingBarrel",
  "cabin",
  "caravan",
  "tent",
  "treehouse",
  "trailer",
  "rentalBathroom",
];

export const SightseeingListEn = [
  "view",
  "castle",
  "history",
  "museum",
  "church",
  "mosque",
  "synagogue",
  "zone",
  "shop",
  "movietheater",
  "zoo",
  "stadion",
];

export const TransportListEn = ["bus", "tram", "taxi", "train", "ship"];

export const ServiceListEn = [
  "breadService",
  "touristInfo",
  "gas",
  "hairdresser",
  "pharmacy",
  "postOffice",
  "cashDispenser",
  "laundryService",
  "bicycleRental",
  "bicycleRepair",
  "carRental",
  "workshop",
  "boatHire",
  "slipway",
  "petrolStation",
  "carFree",
];
